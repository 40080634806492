import * as React from 'react';
import {useEffect, useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as actions from '../../store/actions';
import {
    addDeviceProfileValidationSchema,
    DeviceProfileFormProps,
    getRoute,
    NewDeviceProfileDialogProps,
    useStyles,
} from './NewDeviceProfileDialog.utils';
import {ReduxState} from '../../store/types';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../store/types/Permission';
import DialogContainer, {DialogButton} from '../../components/AlertDialog/DialogContainer';
import Loader from '../../components/Loader/Loader';
import SelectField from '../../components/SelectField/SelectField';
import {AddOptionsTemplateRequest, OptionsTemplateInfo, UATypeInfo} from '../../store/types/Devices';
import CustomizedTextField from '../../components/TextField/TextField';
import {usePermissions} from '../../hooks/usePermissions';
import {Routes} from '../../routes/routes';
import history from '../../history';
import {showToast} from "../../utils/showToast";
import { devicesWithButtonsConfiguration } from '../../store/actions';

const NewDeviceProfileDialog: React.FC<NewDeviceProfileDialogProps> = ({
        isOpen,
        clickOnClose,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const {isCreatingProfile, isTypeListLoading, createProfileApiError} = useSelector(
        (state: ReduxState) => state.devices,
    );

    const i_customer = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.i_customer,
    );

    const isLoading: boolean = useMemo(
        () => isTypeListLoading || isCreatingProfile || false,
        [isTypeListLoading, isCreatingProfile],
    );

    const viewDetails = usePermissions(...Permission.Inventory.DeviceProfiles.value);

    const ua_type_list = useMemo(() => {
        const retList: UATypeInfo[] = [];
        for(const bc of devicesWithButtonsConfiguration) {
            retList.push({
                hidden: 0,
                i_ua_type: bc.value,
                manufacturer: '',
                name: bc.name,
                ports: 12,
                product: '',
                version: '',
            });
        }
        return retList;
    }, [devicesWithButtonsConfiguration]);

    const initialValues: DeviceProfileFormProps = useMemo(
        () => ({
            model: null,
            name: '',
        } as DeviceProfileFormProps),
        [],
    );

    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        setFieldError,
        handleChange,
        dirty
    } = useFormik<DeviceProfileFormProps>({
        initialValues,
        onSubmit: (form) => {
            const model: AddOptionsTemplateRequest = {
                template_info: {
                    i_customer: i_customer,
                    i_ua_list: [],
                    i_ua_type: form.model?.i_ua_type,
                    name: form.name,
                } as OptionsTemplateInfo,
                callback: (createdProfileId) => {
                    clickOnClose?.();
                    if (viewDetails !== PermissionType.Hidden) {
                        history.push(
                            getRoute(Routes.DeviceProfilesDetails, {
                                id: createdProfileId,
                            }),
                        );
                    }
                    showToast(t<string>('screens:deviceProfiles.deviceProfileAdded'));
                }
            };
            dispatch(actions.addDeviceProfile.request(model));
        },
        validationSchema: addDeviceProfileValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });

    useEffect(() => {
        if (isOpen) {
            resetForm();
            if(i_customer)
            {
                const _pageSize = 100;
                const _page = 1;
                dispatch(
                    actions.getOptionsTemplateList.request({
                        get_total: 1,
                        i_customer: i_customer,
                        limit: _pageSize,
                        offset: (_page - 1) * _pageSize,
                    })
                );
            }
        }
    }, [isOpen, i_customer]);

    useEffect(() => {
        if (isOpen && ua_type_list?.length === 1 && !values.model) {
            const option = ua_type_list[0];
            setFieldValue('model', option);
        }
    }, [isOpen, ua_type_list, values.model]);

    useEffect(() => {
        if (!isCreatingProfile && createProfileApiError) {
            switch (createProfileApiError.faultcode) {
                case 'internal_server_error':
                    setFieldError('name', t('errors:deviceProfiles.deviceProfileNameIsNotUnique'));
                    break;
            }
        }
    }, [createProfileApiError, isCreatingProfile]);

    const isDisabled = (): boolean => {
        return (
            !dirty ||
            !values.model ||
            !(values.name?.length) ||
            isLoading)
    }
    return (
        <PermissionProvider permission={Permission.Inventory.DeviceProfiles.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'deviceprofiless-add-new-modal'}
                header={t('screens:deviceProfiles.addNewDeviceProfile')}
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            isDisabled()
                        }
                        onClick={handleSubmit}
                        dataQa={'add-new-device-profile-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-device-profile-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <SelectField
                                id="model"
                                label={t(
                                    'screens:devices.model',
                                )}
                                getOptionLabel={(value: UATypeInfo) => value?.name || ''}
                                onChange={(_, option: UATypeInfo) => {
                                    if (option) {
                                        setFieldValue('model', option);
                                    }
                                }}
                                items={ua_type_list || []}
                                value={values.model}
                                helperText={
                                    errors.model && errors.model.length > 0
                                        ? errors.model
                                        : ''
                                }
                                classes={{helperText: classes.error}}
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataQa={'model-select-field'}
                                dataTestId={'model-select-field'}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="name"
                                label={t('screens:deviceProfiles.profileName')}
                                value={values.name}
                                helperText={
                                    errors.name && errors.name.length > 0
                                        ? errors.name
                                        : ''
                                }
                                setFieldError={setFieldError}
                                onChange={handleChange}
                                required
                                maxLength={64}
                                dataQa="deviceprofile-name-field"
                            />
                        </Box>
                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition/>
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewDeviceProfileDialog;
