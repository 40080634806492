import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import {AddOptionsTemplateRequest, OptionsTemplateInfo, TemplateKeysOptionsInfo, UAProfileKeyAction, UAProfileKeyInfo, UpdateOptionsTemplateRequest} from '../../store/types/Devices';
import {useTranslation} from 'react-i18next';
import TextField from '../../components/TextField/TextField';
import {v4 as id} from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import DeviceProfileDetailsActionKeyButton from './DeviceProfileDetailsActionKeyButton';
import { DeviceProfileDetailsActionKeyRowData } from './DeviceProfileDetailsActionKeyRow.utils';
import DeviceProfileDetailsActionKeyRow from './DeviceProfileDetailsActionKeyRow';
import FormActions from '../../components/Forms/FormActions';
import { Permission } from '../../store/types/Permission';
import { actions } from '../../store';
import { DeviceProfileDetailsTabFormType, DeviceProfileDetailsTabProps, deviceProfileValidationSchema, 
    useStyle } from './DeviceProfileDetailsTab.utils';
import {showToast} from "../../utils/showToast";
import { YesNo } from '../../store/types/CallScreening';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { DialogButton } from '../../components/AlertDialog/DialogContainer';
import history from '../../history';
import { getRoute, Routes } from '../../routes/routes';
import SelectField from '../../components/SelectField/SelectField';
import { ReduxState } from '../../store/types';
import { DropDownDictionaryItem } from '../CallHistory/ActiveCalls/ActiveCalls.utils';
import { useLocation } from 'react-router-dom';
import {ReactComponent as OpenExternalLink} from '../../assets/OpenExternalLink.svg';

const DeviceProfileDetailsTab: React.FC<DeviceProfileDetailsTabProps> = (
    {
        profile,
        className,
        dataQa,
        uaProfileMetaInfo,
        onSaveComplete,
        readonly = false,
        onChangeTemplate,
    }) => {
    const classes = useStyle();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {
        templates,
        ua_type_list
    } = useSelector((state: ReduxState) => state.devices);

    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        i_ua_config_template?: number;
        name?: string;
    }>({
        isOpen: false
    });

    const scale = 0.5;

    const location = useLocation();
    const isCopy = useMemo(() => {
        const url = location.pathname ?? '';
        const pattern = Routes.DeviceProfilesCopy.replace(":id", "(\\d){1,9}");
        const isMatch = url.match(pattern);
        return !!isMatch;
    }, [location]);

    const mapKeysToArray = (keyMetadata: UAProfileKeyInfo[], values: TemplateKeysOptionsInfo[], actions: UAProfileKeyAction[], type: 'line_key' | 'soft_key' | 'expansion_key') => {
        const result: DeviceProfileDetailsActionKeyRowData[] = [];
        for(let metaObjectIndex = 0; metaObjectIndex < keyMetadata.length; metaObjectIndex++)
        {
            const actionsWithDefault: string[] = ['', ...(actions?.map(e => e.type) ?? [])];
            const metaObject = keyMetadata[metaObjectIndex];
            const value = values?.find(e => e.id === (metaObjectIndex + 1));
            const keyObject: DeviceProfileDetailsActionKeyRowData = {
                keyId: value ? ((value.id ?? 1) - 1) : (metaObjectIndex),
                actions: actionsWithDefault,
                value: value?.value ?? '',
                label: value?.label ?? '',
                itemIdentifierInternal: id(),
                selectedAction: value?.action,
                position: metaObject.position,
                type: type,
                isDisabled: (value?.allow_override ?? YesNo.No) === YesNo.Yes,
                actionsConfiguration: actions ?? [],
                selectedActionConfiguration: (actions ?? [])?.find(e => e.type === value?.action)
                    ?.action_attributes ?? [],
            };
            result.push(keyObject);
        }
        return result;
    };

    const initialValues = useMemo(() => {
        const softKeysMetadata = uaProfileMetaInfo?.keys_layout?.soft_keys?.keys ?? [];
        const softKeysValues = profile?.keys_options?.filter(e => e.type === 'soft_key') ?? [];
        const softKeysActions = uaProfileMetaInfo?.keys_layout?.soft_keys?.actions ?? [];
        
        const lineKeysMetadata = uaProfileMetaInfo?.keys_layout?.line_keys?.keys ?? [];
        const lineKeysValues = profile?.keys_options?.filter(e => e.type === 'line_key') ?? [];
        const lineKeysActions = uaProfileMetaInfo?.keys_layout?.line_keys?.actions ?? [];

        const expansionKeysMetadata = uaProfileMetaInfo?.keys_layout?.expansion_keys?.keys ?? [];
        const expansionKeysValues = profile?.keys_options?.filter(e => e.type === 'expansion_key') ?? [];
        const expansionKeysActions = uaProfileMetaInfo?.keys_layout?.expansion_keys?.actions ?? [];

        const obj: DeviceProfileDetailsTabFormType = {
            profileName: isCopy 
                ? (profile?.name ?? '') + ' ' + t('screens:deviceProfiles.copyNameSuf')
                : (profile?.name ?? ''),
            softKeys: mapKeysToArray(softKeysMetadata, softKeysValues, softKeysActions, 'soft_key'),
            lineKeys: mapKeysToArray(lineKeysMetadata, lineKeysValues, lineKeysActions, 'line_key'),
            expansionKeys: mapKeysToArray(expansionKeysMetadata, expansionKeysValues, expansionKeysActions, 'expansion_key'),
        };

        return obj;
    }, [isCopy, profile, t, uaProfileMetaInfo]);

    const handleOnSave = useCallback((form: DeviceProfileDetailsTabFormType) => {
        if(!profile) 
            return;

        const options = mapToSaveObject(form);
        if(isCopy)
        {
            const model: AddOptionsTemplateRequest = {
                template_info: {
                    i_customer: profile.i_customer,
                    i_ua_list: [],
                    i_ua_type: profile.i_ua_type,
                    name: form.profileName,
                } as OptionsTemplateInfo,
                callback: (newId: number) => {
                    const payload: UpdateOptionsTemplateRequest = {
                        //@ts-ignore
                        template_info: {
                            i_ua_config_template: newId,
                            keys_options: options,
                            name: form.profileName
                        },
                        callback: () => {
                            showToast(t('screens:deviceProfiles.profileTemplateSaved'));
                            onSaveComplete?.();
                            history.push(
                                getRoute(Routes.DeviceProfiles, {}),
                            );
                        },
                    };
                    dispatch(actions.updateOptionsTemplate.request([payload]));
                },
                isCloning: true,
            };
            dispatch(actions.addDeviceProfile.request(model));
        }
        else {
            const payload: UpdateOptionsTemplateRequest = {
                //@ts-ignore
                template_info: {
                    i_ua_config_template: profile.i_ua_config_template,
                    keys_options: options,
                    name: form.profileName
                },
                callback: () => {
                    showToast(t('screens:deviceProfiles.profileTemplateUpdated'))
                    onSaveComplete?.();
                },
            };
            dispatch(actions.updateOptionsTemplate.request([payload]));
        }

    }, [profile]);

    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            i_ua_config_template: undefined,
            name: undefined
        });
    };

    const handleDelete = (obj: OptionsTemplateInfo) => {
        setDeleteDialog({isOpen: true, i_ua_config_template: obj.i_ua_config_template, name: obj.name});
    };

    const onChevronClick = useCallback(() => {
        history.push(Routes.DeviceProfiles);
    }, []);

    const handleDeleteAction = () => {
        dispatch(actions.deleteProfile.request({
            i_ua_config_template: deleteDialog.i_ua_config_template || 0,
            callback: () => {
                closeDeleteDialog();
                onChevronClick();
                showToast(t<string>('screens:deviceProfiles.profileDeleted'));
            }
        }));
    };

    const {
        values,
        handleSubmit,
        setFieldError,
        setFieldValue,
        handleChange,
        errors,
        dirty
    } = useFormik<DeviceProfileDetailsTabFormType>({
        initialValues,
        onSubmit: (form) => {
            handleOnSave(form);
        },
        validationSchema: deviceProfileValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
    });

    const changeFunctionCallBack = useCallback((item: DeviceProfileDetailsActionKeyRowData, newActionValue: string) => {
        const indxOfSoftKey = values.softKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        const indxOfLineKey = values.lineKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        const indxOfExpansionKey = values.expansionKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        if(indxOfSoftKey !== -1) {
            setFieldValue('softKeys[' + indxOfSoftKey + '].selectedAction', newActionValue);
            const configuration = values.softKeys[indxOfSoftKey].actionsConfiguration
                ?.find(e => e.type === newActionValue)
                ?.action_attributes;
            setFieldValue('softKeys[' + indxOfSoftKey + '].selectedActionConfiguration', configuration);
        }
        else if(indxOfLineKey !== -1) {
            setFieldValue('lineKeys[' + indxOfLineKey + '].selectedAction', newActionValue);
            const configuration = values.lineKeys[indxOfLineKey].actionsConfiguration
                ?.find(e => e.type === newActionValue)
                ?.action_attributes;
            setFieldValue('lineKeys[' + indxOfLineKey + '].selectedActionConfiguration', configuration);
        }
        else if(indxOfExpansionKey !== -1) {
            setFieldValue('expansionKeys[' + indxOfExpansionKey + '].selectedAction', newActionValue);
            const configuration = values.expansionKeys[indxOfExpansionKey].actionsConfiguration
                ?.find(e => e.type === newActionValue)
                ?.action_attributes;
            setFieldValue('expansionKeys[' + indxOfExpansionKey + '].selectedActionConfiguration', configuration);
        }
    }, [values, setFieldValue]);

    const changeValueCallBack = useCallback((item: DeviceProfileDetailsActionKeyRowData, newValue: string) => {
        const indxOfSoftKey = values.softKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        const indxOfLineKey = values.lineKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        const indxOfExpansionKey = values.expansionKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        if(indxOfSoftKey !== -1) {
            setFieldValue('softKeys[' + indxOfSoftKey + '].value', newValue);
            setFieldError('softKeys[' + indxOfSoftKey + '].value', undefined)
        }
        else if(indxOfLineKey !== -1) {
            setFieldValue('lineKeys[' + indxOfLineKey + '].value', newValue);
            setFieldError('lineKeys[' + indxOfLineKey + '].value', undefined);
        }
        else if(indxOfExpansionKey !== -1) {
            setFieldValue('expansionKeys[' + indxOfExpansionKey + '].value', newValue);
            setFieldError('expansionKeys[' + indxOfExpansionKey + '].value', undefined);
        }
    }, [values, setFieldValue]);

    const changeLabelCallBack = useCallback((item: DeviceProfileDetailsActionKeyRowData, newActionValue: string) => {
        const indxOfSoftKey = values.softKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        const indxOfLineKey = values.lineKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        const indxOfExpansionKey = values.expansionKeys.findIndex(e => e.itemIdentifierInternal === item.itemIdentifierInternal);
        if(indxOfSoftKey !== -1) {
            setFieldValue('softKeys[' + indxOfSoftKey + '].label', newActionValue);
        }
        else if(indxOfLineKey !== -1) {
            setFieldValue('lineKeys[' + indxOfLineKey + '].label', newActionValue);
        }
        else if(indxOfExpansionKey !== -1) {
            setFieldValue('expansionKeys[' + indxOfExpansionKey + '].label', newActionValue);
        }
    }, [values, setFieldValue]);

    const setActiveButton = useCallback((toActivate: DeviceProfileDetailsActionKeyRowData | undefined, scrollToRow = false) => {
        const indxOfSoftKeyDeactivate = values.softKeys.findIndex(e => e.isActive === true);
        const indxOfLineKeyDeactivate = values.lineKeys.findIndex(e => e.isActive === true);
        const indxOfExpansionKeyDeactivate = values.expansionKeys.findIndex(e => e.isActive === true);
        if(indxOfSoftKeyDeactivate !== -1 && values.softKeys[indxOfSoftKeyDeactivate].itemIdentifierInternal != toActivate?.itemIdentifierInternal) {
            setFieldValue('softKeys[' + indxOfSoftKeyDeactivate + '].isActive', false);
        }
        if(indxOfLineKeyDeactivate !== -1 && values.lineKeys[indxOfLineKeyDeactivate].itemIdentifierInternal != toActivate?.itemIdentifierInternal) {
            setFieldValue('lineKeys[' + indxOfLineKeyDeactivate + '].isActive', false);
        }
        if(indxOfExpansionKeyDeactivate !== -1 && values.expansionKeys[indxOfExpansionKeyDeactivate].itemIdentifierInternal != toActivate?.itemIdentifierInternal) {
            setFieldValue('expansionKeys[' + indxOfExpansionKeyDeactivate + '].isActive', false);
        }

        if(toActivate && (toActivate.isActive === false || toActivate.isActive === undefined)) {
            const indxOfSoftKey = values.softKeys.findIndex(e => e.itemIdentifierInternal === toActivate.itemIdentifierInternal);
            const indxOfLineKey = values.lineKeys.findIndex(e => e.itemIdentifierInternal === toActivate.itemIdentifierInternal);
            const indxOfExpansionKey = values.expansionKeys.findIndex(e => e.itemIdentifierInternal === toActivate.itemIdentifierInternal);
            if(indxOfSoftKey !== -1) {
                setFieldValue('softKeys[' + indxOfSoftKey + '].isActive', true);
            }
            else if(indxOfLineKey !== -1) {
                setFieldValue('lineKeys[' + indxOfLineKey + '].isActive', true);
            }
            else if(indxOfExpansionKey !== -1) {
                setFieldValue('expansionKeys[' + indxOfExpansionKey + '].isActive', true);
            }

            if(scrollToRow) {
                const divId = 'device-profile-row-' + toActivate.itemIdentifierInternal;
                const elementRow = document.getElementById(divId);

                const forms = document.getElementsByTagName('form');
                if(forms.length) {
                    forms[forms.length - 1].parentElement?.scrollTo({
                        top: (elementRow?.offsetTop ?? 0) - 256,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            }
        }
    }, [values, setFieldValue]);

    const mapToSaveObject = (_values: DeviceProfileDetailsTabFormType) => {
        const result: TemplateKeysOptionsInfo[] = [];
        
        const pushItem = (type: 'line_key' | 'soft_key' | 'expansion_key', obj: DeviceProfileDetailsActionKeyRowData) => {
            result.push({
                id: obj.keyId + 1,
                type: type,
                label: obj.label?.length ? obj.label : null,
                value: obj.value?.length ? obj.value : null,
                action: obj.selectedAction
            });
        };

        for(const obj of _values.lineKeys) {
            if(obj.selectedAction) {
                pushItem('line_key', obj);
            }
        }
        for(const obj of _values.softKeys) {
            if(obj.selectedAction) {
                pushItem('soft_key', obj);
            }
        }
        for(const obj of _values.expansionKeys) {
            if(obj.selectedAction) {
                pushItem('expansion_key', obj);
            }
        }

        return result;
    };

    const templatesDropdownList = useMemo(() => {
        const returnArray: DropDownDictionaryItem<string>[] = [];
        for(const itm of templates ?? []) {
            const type = ua_type_list?.find(e => e.i_ua_type === itm.i_ua_type);
            const v: DropDownDictionaryItem<string> = {
                name: (type?.name?.length
                    ? type.name + ' - '
                    : '')
                + itm.name,
                value: itm.name
            };
            returnArray.push(v);
        }
        return returnArray;
    }, [templates, ua_type_list]);

    const onClickExternalLink = useCallback(() => {
        const profileId = templates?.find(e => e.name === values.profileName)
            ?.i_ua_config_template;
        const route = getRoute(Routes.DeviceProfilesDetails, {id: profileId});
        window.open(route, '_blank')?.focus();
    }, [values, templates]);

    return (<>
        <form onSubmit={handleSubmit} autoComplete="off"
            className={classNames(classes.rootDeviceProfileDetailsTab, className)}
            data-qa={dataQa}
            data-testid={'device-profile-info'}
            style={{position: 'relative'}}
        >
            <Grid
                container
                spacing={3}
                className={classNames(
                    classes.itemsContainer
                )}
            >
                <div className={classes.dual}>
                    <div className={classes.scrollInsideDual}>
                        {readonly ? (
                            <div className={classes.profileDropdownContainer}>
                                <SelectField
                                    label={t('screens:deviceProfiles.profileName')}
                                    items={templatesDropdownList}
                                    value={templatesDropdownList?.find(e => e.value == values.profileName) ?? {}}
                                    dataQa="profile-name-dropdown"
                                    onChange={(e, value: DropDownDictionaryItem<string>) => {
                                        setFieldValue('profileName', value.value);
                                        handleChange(e);
                                        const selectedOption = templates?.find(e => e.name === value.value);
                                        if(selectedOption) {
                                            onChangeTemplate?.(selectedOption);
                                        }
                                    }}
                                    getOptionLabel={(v: DropDownDictionaryItem<string>) => {
                                        return v?.name ?? '';
                                    }}
                                    getOptionSelected={(v: DropDownDictionaryItem<string>) => {
                                        return v.value === values.profileName;
                                    }}
                                    disableClearable
                                    classes={{
                                        container: classes.profileName
                                    }}
                                />
                                <div className={classes.externalLinkeContainer}>
                                    <OpenExternalLink onClick={onClickExternalLink}/>
                                </div>
                            </div>
                        ) : (
                            <TextField
                                label={t('screens:deviceProfiles.profileName')}
                                value={values?.profileName}
                                id='profileName'
                                className={classes.profileName}
                                noTooltip
                                required
                                onChange={(e) => {
                                    setFieldValue('profileName', e.target.value);
                                    handleChange(e);
                                }}
                            />
                        )}

                        {(values?.softKeys?.length ?? 0) > 0 && (
                            <div className={classes.sectionHeader}>{t('screens:deviceProfiles.programmableKeys')}</div>
                        )}

                        {(values?.softKeys?.length ?? 0) > 0 && (
                            values?.softKeys?.map((k, indx) => (<DeviceProfileDetailsActionKeyRow
                                key={'soft_key_id' + indx}
                                item={k}
                                changeFunctionCallBack={changeFunctionCallBack}
                                changeValueCallBack={changeValueCallBack}
                                changeLabelCallBack={changeLabelCallBack}
                                setActiveButton={setActiveButton}
                                onChange={handleChange}
                                errors={errors?.softKeys?.[indx]}
                                readonly={readonly}
                            />))
                        )}
                        
                        {(values?.lineKeys?.length ?? 0) > 0 && (
                            <div className={classes.sectionHeader}>{t('screens:deviceProfiles.lineKeys')}</div>
                        )}
                        
                        {(values?.lineKeys?.length ?? 0) > 0 && (
                            values?.lineKeys?.map((k, indx) => (<DeviceProfileDetailsActionKeyRow
                                key={'line_key_id' + indx}
                                item={k}
                                changeFunctionCallBack={changeFunctionCallBack}
                                changeValueCallBack={changeValueCallBack}
                                changeLabelCallBack={changeLabelCallBack}
                                setActiveButton={setActiveButton}
                                onChange={handleChange}
                                errors={errors?.lineKeys?.[indx]}
                                readonly={readonly}
                            />))
                        )}

                        {(values?.expansionKeys?.length ?? 0) > 0 && (
                            <div className={classes.sectionHeader}>{t('screens:deviceProfiles.expansionKeys')}</div>
                        )}
                        
                        {(values?.expansionKeys?.length ?? 0) > 0 && (
                            values?.expansionKeys?.map((k, indx) => (<DeviceProfileDetailsActionKeyRow
                                key={'expansion_key_id' + indx}
                                item={k}
                                changeFunctionCallBack={changeFunctionCallBack}
                                changeValueCallBack={changeValueCallBack}
                                changeLabelCallBack={changeLabelCallBack}
                                setActiveButton={setActiveButton}
                                onChange={handleChange}
                                errors={errors?.expansionKeys?.[indx]}
                                readonly={readonly}
                            />))
                        )}

                    </div>
                    {uaProfileMetaInfo?.keys_layout?.image && (<div className={classes.keyPadContainer} style={{
                            minWidth: 1024 * scale,
                            minHeight: 1024 * scale,
                        }}>
                            <div className={classes.containerOfImageAndButtons}>
                                <div className={classes.imageAndButtonsContainer}>
                                    <div className={classes.phoneImageContainer} style={{
                                        width: 1024 * scale,
                                        height: 1024 * scale,
                                    }}>
                                        <img src={'data:image/gif;base64,' + uaProfileMetaInfo?.keys_layout?.image} style={{
                                            width: 1024 * scale,
                                            height: 1024 * scale,
                                        }} />
                                    </div>
                                    {
                                        values.softKeys?.map((e, indx) => (<DeviceProfileDetailsActionKeyButton
                                            key={'soft_key_keyboard_' + indx}
                                            scale={scale}
                                            item={e}
                                            onClick={() => setActiveButton(e, true)}
                                        />))
                                    }
                                    {
                                        values.lineKeys?.map((e, indx) => (<DeviceProfileDetailsActionKeyButton
                                            key={'line_key_keyboard_' + indx}
                                            scale={scale}
                                            item={e}
                                            onClick={() => setActiveButton(e, true)}
                                        />))
                                    }
                                    {
                                        values.expansionKeys?.map((e, indx) => (<DeviceProfileDetailsActionKeyButton
                                            key={'expansion_key_keyboard_' + indx}
                                            scale={scale}
                                            item={e}
                                            onClick={() => setActiveButton(e, true)}
                                        />))
                                    }
                                </div>
                            </div>
                    </div>)}
                </div>
            </Grid>
        </form>
        {!readonly && (
            <FormActions
                isSaveActive={dirty || isCopy}
                onSave={() => {
                    handleSubmit();
                }}
                hideCancel={false}
                hideDelete={isCopy}
                editPermission={Permission.Inventory.DeviceProfiles.value}
                deleteTitle={t('screens:deviceProfiles.deleteDeviceProfile')}
                onDelete={() => {profile && handleDelete(profile);}}
                deletePermission={Permission.Inventory.DeviceProfiles.value}
                className={classes.actionButtonsContainer}
                cancelButtonDisabled={dirty}
                cancelButtonTitle={isCopy ? t('common:cancel') : t('screens:deviceProfiles.saveAsCopy')}
                cancelButtonClass={classes.copyButtonStyle}
                onCancel={() => {
                    if(isCopy) {
                        history.push(
                            getRoute(Routes.DeviceProfilesDetails, {
                                id: profile?.i_ua_config_template,
                            }),
                        );
                    }
                    else {
                        history.push(
                            getRoute(Routes.DeviceProfilesCopy, {
                                id: profile?.i_ua_config_template,
                            }),
                        );
                    }
                }}
            />
        )}
        <AlertDialog
            className={classes.deleteDialog}
            contentClass={classes.contentDialog}
            isOpen={deleteDialog.isOpen}
            dataQa={'delete-profile-dialog'}
            hideHeader
            description={t('screens:deviceProfiles.deleteRule', {
                name: deleteDialog.name || '',
            })}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={closeDeleteDialog}
                />,
                <DialogButton
                    key="delete"
                    label={t('common:delete')}
                    onClick={handleDeleteAction}
                />,
            ]}
        />
    </>
    );
};

export default DeviceProfileDetailsTab;
