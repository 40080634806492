import React, { useMemo } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import { DeviceProfileDetailsActionKeyRowData } from './DeviceProfileDetailsActionKeyRow.utils';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../components/Tooltip/Tooltip';

export type DeviceProfileDetailsActionKeyIconButtonProps = {
    item: DeviceProfileDetailsActionKeyRowData;
    onClick?: (item: DeviceProfileDetailsActionKeyRowData) => void;
    className?: string;
    size: 'small' | 'medium';
}

export const useStyle = makeStyles(() => ({
    rootContainer: {
        cursor: 'pointer',
        border: '1px solid white',
        userSelect: 'none',
        boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.2)',

        '&:hover': {
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        },
    },
    rootContainerSizeSmall: {
        width: 18,
        height: 18,
        borderRadius: 9,
    },
    rootContainerSizeMedium: {
        width: 22,
        height: 22,
        borderRadius: 11,
    },
    rootContainerDisabled: {
        backgroundColor: 'rgba(146, 143, 142, 1)'
    },
    rootContainerActive: {
        backgroundColor: 'rgba(245, 132, 31, 1)',
    },
    rootContainerLineKeyAssigned: {
        backgroundColor: 'rgba(81, 166, 237, 1)',
    },
    rootContainerLineKeyNotAssigned: {
        backgroundColor: 'rgba(76, 134, 183, 1)',
    },
    rootContainerSoftKeyAssigned: {
        backgroundColor: 'rgba(117, 185, 67, 1)',
    },
    rootContainerSoftKeyNotAssigned: {
        backgroundColor: 'rgba(62, 92, 105, 1)',
    },
    rootContainerExpansionKeyAssigned: {
        backgroundColor: 'rgba(117, 185, 67, 1)',
    },
    rootContainerExpansionKeyNotAssigned: {
        backgroundColor: 'rgba(62, 92, 105, 1)',
    },
    label: {
        color: Colors.White,
        width: '100%',
        position: 'relative',
        fontWeight: 700,
        height: '100%',
        justifyContent: 'center',
        verticalAlign: 'middle',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    labelSmallSize: {
        fontSize: 8,
    },
    labelMediumSize: {
        fontSize: 9,
    },
    icon: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    tooltipContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: 'fit-content',
        width: 'fit-content',
        flexDirection: 'column',
        paddingTop: 4,
        paddingBottom: 4,
    },
    tooltipTop: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        letterSpacing: '0%',
        color: 'white'
    },
    tooltipBottom: {
        marginTop: 2,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        letterSpacing: '0%',
        opacity: 0.6
    },
    tooltipBottomAssigned: {
        color: 'white',
        opacity: 1,
    }
}));

const DeviceProfileDetailsActionKeyIconButton: React.FC<DeviceProfileDetailsActionKeyIconButtonProps> = ({
    item,
    onClick,
    className,
    size
}) => {
    const classes = useStyle();
    const {t} = useTranslation();
    
    const isActive = useMemo(() => {
        return item?.isActive ?? false;
    }, [item]);

    const isAssigned = useMemo(() => {
        return (item?.value?.length ?? 0) > 0;
    }, [item]);
    
    const isLineKey = useMemo(() => {
        return item?.type == 'line_key';
    }, [item]);
    
    const isSoftKey = useMemo(() => {
        return item?.type == 'soft_key';
    }, [item]);

    const isExpansionKey = useMemo(() => {
        return item?.type == 'expansion_key';
    }, [item]);

    const isDisabled = useMemo(() => {
        return item?.isDisabled ?? false;
    }, [item]);
    
    const toolTipTextTop = useMemo(() => {
        const typeOfKey = isDisabled ? t('screens:deviceProfiles.teamsHome')
                : isSoftKey ? t('screens:deviceProfiles.programmableKey')
                : isLineKey ? t('screens:deviceProfiles.lineKey')
                : t('screens:deviceProfiles.expansionKey');
        return t('screens:deviceProfiles.tooltipFormatTop', 
            {
                keyId: (item.keyId + 1),
                typeOfKey: typeOfKey,
            }
        );
    }, [t, item, isDisabled, isSoftKey, isLineKey]);

    const toolTipTextBottom = useMemo(() => {
        const assignText = item.selectedAction == 'N/A' ? t('enums:UAProfileAction.' + item.selectedAction + '')
            : !isAssigned 
            ? t('screens:deviceProfiles.notAssigned')
            : t('screens:deviceProfiles.assignedExt', {
                extension: item.value ?? ''
            });
        return t('screens:deviceProfiles.tooltipFormatBottom', 
            {
                assignedDetails: assignText
            }
        );
    }, [t, item, isAssigned]);

    const tooltipTextElement = useMemo(() => {
        return (<div className={classes.tooltipContainer}>
            <div className={classes.tooltipTop}>{toolTipTextTop}</div>
            <div className={classNames(classes.tooltipBottom, isAssigned && classes.tooltipBottomAssigned)}>{toolTipTextBottom}</div>
        </div>);
    }, [classes, toolTipTextTop, toolTipTextBottom]);
    
    return (
        <Tooltip
            title={tooltipTextElement}
            copy={false}
            disableHoverListener={size == 'medium'}
        >
            <div className={classNames(classes.rootContainer,
                className,
                size == 'small' && classes.rootContainerSizeSmall,
                size == 'medium' && classes.rootContainerSizeMedium,
                isDisabled && classes.rootContainerDisabled,
                isActive && !isDisabled && classes.rootContainerActive,
                !isActive && !isDisabled && isLineKey && isAssigned && classes.rootContainerLineKeyAssigned,
                !isActive && !isDisabled && isLineKey && !isAssigned && classes.rootContainerLineKeyNotAssigned,
                !isActive && !isDisabled && isSoftKey && isAssigned && classes.rootContainerSoftKeyAssigned,
                !isActive && !isDisabled && isSoftKey && !isAssigned && classes.rootContainerSoftKeyNotAssigned,
                !isActive && !isDisabled && isExpansionKey && isAssigned && classes.rootContainerExpansionKeyAssigned,
                !isActive && !isDisabled && isExpansionKey && !isAssigned && classes.rootContainerExpansionKeyNotAssigned,
            )} onClick={() => {onClick?.(item)}} >
                    <div className={classNames(classes.label,
                        size == 'small' && classes.labelSmallSize,
                        size == 'medium' && classes.labelMediumSize,
                    )}>
                        {item.keyId + 1}
                    </div>
            </div>
        </Tooltip>
    );
}

export default DeviceProfileDetailsActionKeyIconButton;