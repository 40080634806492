import { createAsyncAction } from 'typesafe-actions';
import {
    AddUAPayload,
    AssignedPortInfo,
    DeleteUARequest,
    GetAssignedCpePortsListRequest,
    GetUAListRequest,
    GetUATypeListRequest,
    GetUploadFileApiErrorRequest,
    UAInfo,
    UAListPayload,
    UATypeListPayload,
    UpdateUaRequest,
    UploadFileListRequest,
    AssignUaRequest,
    GetOptionsTemplateListRequest,
    OptionsTemplateListPayload,
    UpdateOptionsTemplateRequest,
    UpdateOptionsTemplateState,
    DeleteProfileRequest,
    AddOptionsTemplateRequest,
    AddOptionsTemplateResponse,
    OptionsTemplateInfo,
    GetOptionsTemplateRequest,
    GetUAProfileMetaInfoResponse,
    GetUAProfileMetaInfoRequest,
    GetPhoneBookListRequest,
    GetPhoneBoolListResponse,
    CreateDevicePhoneBookRequest,
    CreateDevicePhoneBookResponse,
    DeleteDevicePhoneBookRequest
} from '../../types/Devices';
import { APIErrorInterface } from '../../types/errors';
import { DropDownDictionaryItem } from '../../../views/CallHistory/ActiveCalls/ActiveCalls.utils';

export const devicesWithButtonsConfiguration: DropDownDictionaryItem<number>[] = [
    {
        value: 227,
        name: 'Yealink T58W',
    } as DropDownDictionaryItem<number>,
];

export const devicesList = createAsyncAction(
    'GET_UA_LIST',
    'GET_UA_LIST_SUCCESS',
    'GET_UA_LIST_FAILED',
)<GetUAListRequest, UAListPayload, undefined>();

export const releaseDevice = createAsyncAction(
    'RELEASE_DEVICE',
    'RELEASE_DEVICE_SUCCESS',
    'RELEASE_DEVICE_FAILED',
)<
    { 
        extension_id?: string; 
        i_cpe?: number; 
        onSuccess?: () => void;
        i_ua_link?: number;
        withAssign?: boolean;
        callback?:()=>void
    },
    undefined,
    undefined
>();

export const assignDevice = createAsyncAction(
    'ASSIGN_DEVICE',
    'ASSIGN_DEVICE_SUCCESS',
    'ASSIGN_DEVICE_FAILED',
)<
    {
        withRelease?: boolean;
        port: number;
        i_cpe: number;
        i_c_ext: number;
        onSuccess?: () => void;
        extension_id?: string;
        i_account?: number;
    },
    undefined,
    undefined
>();

export const fetchDeviceByIAccount = createAsyncAction(
    'FETCH_DEVICE_BY_I_ACCOUNT',
    'FETCH_DEVICE_BY_I_ACCOUNT_SUCCESS',
    'FETCH_DEVICE_BY_I_ACCOUNT_FAILED',
)<GetAssignedCpePortsListRequest, {
    items: AssignedPortInfo[]
}, undefined>();

export const deviceTypeList = createAsyncAction(
    'GET_UA_TYPE_LIST',
    'GET_UA_TYPE_LIST_SUCCESS',
    'GET_UA_TYPE_LIST_FAILED',
)<GetUATypeListRequest, UATypeListPayload, undefined>();

export const addDevice = createAsyncAction(
    'ADD_UA',
    'ADD_UA_SUCCESS',
    'ADD_UA_FAILED',
)<UAInfo, AddUAPayload, APIErrorInterface | undefined>();

export const getDeviceListCsvFile = createAsyncAction(
    'GET_UA_LIST_CSV_FILE',
    'GET_UA_LIST_CSV_FILE_SUCCESS',
    'GET_UA_LIST_CSV_FILE_FAILED',
)<
    GetUAListRequest & { fileName: string; callback?: () => void },
    undefined,
    undefined
>();

export const uploadDeviceListCsvFile = createAsyncAction(
    'UPLOAD_UA_LIST_CSV_FILE',
    'UPLOAD_UA_LIST_CSV_FILE_SUCCESS',
    'UPLOAD_UA_LIST_CSV_FILE_FAILED',
)<
    UploadFileListRequest,
    undefined,
    APIErrorInterface | undefined
>();

export const getUploadUAApiErrorFile = createAsyncAction(
    'GET_UA_LIST_ERRORS_CSV_FILE',
    'GET_UA_LIST_ERRORS_CSV_FILE_SUCCESS',
    'GET_UA_LIST_ERRORS_CSV_FILE_FAILED',
)<
    GetUploadFileApiErrorRequest,
    undefined,
    APIErrorInterface | undefined
>();

export const deleteUA = createAsyncAction(
    'DELETE_UA',
    'DELETE_UA_SUCCESS',
    'DELETE_UA_FAILED',
)<
    DeleteUARequest,
    undefined,
    APIErrorInterface | undefined
>();

export const updateUa = createAsyncAction(
    'UPDATE_UA',
    'UPDATE_UA_SUCCESS',
    'UPDATE_UA_FAILED',
)<UpdateUaRequest, undefined, APIErrorInterface | undefined>();

export const assignUA = createAsyncAction(
    'ASSIGN_UA',
    'ASSIGN_UA_SUCCESS',
    'ASSIGN_UA_FAILED',
)<AssignUaRequest, undefined, APIErrorInterface | undefined>();

export const getOptionsTemplateList = createAsyncAction(
    'GET_OPTIONS_TEMPLATE_LIST',
    'GET_OPTIONS_TEMPLATE_SUCCESS',
    'GET_OPTIONS_TEMPLATE_FAILED',
)<GetOptionsTemplateListRequest, OptionsTemplateListPayload, APIErrorInterface | undefined>();

export const updateOptionsTemplate = createAsyncAction(
    'UPDATE_OPTIONS_TEMPLATE',
    'UPDATE_OPTIONS_TEMPLATE_SUCCESS',
    'UPDATE_OPTIONS_TEMPLATE_FAILED',
)<UpdateOptionsTemplateRequest[], UpdateOptionsTemplateState, APIErrorInterface | undefined>();

export const deleteProfile = createAsyncAction(
    'DELETE_PROFILE',
    'DELETE_PROFILE_SUCCESS',
    'DELETE_PROFILE_FAILED',
)<
    DeleteProfileRequest,
    undefined,
    APIErrorInterface | undefined
>();

export const addDeviceProfile = createAsyncAction(
    'ADD_PROFILE_UA',
    'ADD_PROFILE_UA_SUCCESS',
    'ADD_PROFILE_UA_FAILED',
)<AddOptionsTemplateRequest, AddOptionsTemplateResponse, APIErrorInterface | undefined>();

export const getProfileTemplate = createAsyncAction(
    'GET_PROFILE',
    'GET_PROFILE_SUCCESS',
    'GET_PROFILE_FAILED',
)<GetOptionsTemplateRequest, OptionsTemplateInfo, APIErrorInterface | undefined>();

export const getUaProfileMetaInfo = createAsyncAction(
    'GET_UA_PROFILE_METAINFO',
    'GET_UA_PROFILE_METAINFO_SUCCESS',
    'GET_UA_PROFILE_METAINFO_FAILED',
)<GetUAProfileMetaInfoRequest, GetUAProfileMetaInfoResponse, APIErrorInterface | undefined>();

export const getDevicePhoneBookList = createAsyncAction(
    'GET_PHONE_BOOK_LIST_METAINFO',
    'GET_PHONE_BOOK_LIST_SUCCESS',
    'GET_PHONE_BOOK_LIST_FAILED',
)<GetPhoneBookListRequest, GetPhoneBoolListResponse, APIErrorInterface | undefined>();

export const addDevicePhoneBook = createAsyncAction(
    'CREATE_DEVICE_PHONE_BOOK_METAINFO',
    'CREATE_DEVICE_PHONE_BOOK_SUCCESS',
    'CREATE_DEVICE_PHONE_BOOK_FAILED',
)<CreateDevicePhoneBookRequest, CreateDevicePhoneBookResponse, APIErrorInterface | undefined>();

export const deleteDevicePhoneBook = createAsyncAction(
    'DELETE_DEVICE_PHONE_BOOK',
    'DELETE_DEVICE_PHONE_BOOK_SUCCESS',
    'DELETE_DEVICE_PHONE_BOOK_FAILED',
)<
    DeleteDevicePhoneBookRequest,
    undefined,
    APIErrorInterface | undefined
>();