import React, {useCallback, useEffect, useMemo, useState} from 'react';
import history from '../../history';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import {OptionsTemplateInfo, UAInfo, UpdateOptionsTemplateRequest} from '../../store/types/Devices';
import Loader from '../../components/Loader/Loader';
import {actions} from '../../store';
import {Routes} from '../../routes/routes';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission, PermissionType} from "../../store/types/Permission";
import FormActions from '../../components/Forms/FormActions';
import {usePermissions, useRawPermissions} from '../../hooks/usePermissions';
import {useTranslation} from 'react-i18next';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import PreventLeavingPage from '../../components/AlertDialog/PreventLeavingPage';
import {DeviceDetailsProps, useStyles} from './DeviceDetails.utils';
import NotFound from '../NotFound/NotFound';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {getFreeLines} from "./FreeLines";
import {showToast} from "../../utils/showToast";
import { generateTabs } from '../../utils/generateTabs';
import DeviceDetailsTab from './DeviceDetailsTab';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import DeviceTitle from '../../components/Devices/DeviceTitle';
import PortsConfigList from '../../components/Devices/PortsConfigList';
import DeviceProfileDetailsTab from '../DeviceProfiles/DeviceProfileDetailsTab';
import { devicesWithButtonsConfiguration } from '../../store/actions';

const DeviceDetails: React.FC<DeviceDetailsProps> = ({id}) => {
    const {t} = useTranslation();
    usePageTitle();
    const dispatch = useDispatch();
    const classes = useStyles();
    const editPagePermission = usePermissions(...Permission.Inventory.Devices.DeviceDetails.value);
    const permissions = useRawPermissions();

    const {items, isLoading, isReleasingDevice, isEditing} = useSelector(
        (state: ReduxState) => state.devices,
    );

    const [showLoader, setShowLoader] = useState(false)
    const [device, setDevice] = useState<UAInfo | undefined>();
    const [form, setForm] = useState<UAInfo | undefined>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);
    const [isSetDeviceComplete, setIsSetDeviceComplete] = useState<boolean>(false);
    const [invalidDeviceId, setInvalidDeviceId] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        i_ua?: number;
        name?: string;
    }>({
        isOpen: false
    });
    const i_customer: number = useSelector((state: ReduxState) => state.generic?.sessionData?.i_customer ?? 0);
    const [templateInfo, setTemplateInfo] = useState<OptionsTemplateInfo | undefined>();
    const [isDirtyTemplateInfo, setIsDirtyTemplateInfo] = useState<boolean>(false);

    const {
        profileDetails,
        uaProfileMetaInfo,
        templates,
        isLoadingTemplates,
        isLoadingProfileDetails
    } = useSelector((state: ReduxState) => state.devices);

    useEffect(() => {
        if(profileDetails) {
            setTemplateInfo(profileDetails);
        }
    }, [profileDetails, setTemplateInfo]);

    useEffect(() => {
        if (id && items == undefined) {
            dispatch(
                actions.devicesList.request({
                    i_ua: id,
                    with_available_ports: 1,
                    with_busy_ports: 1,
                })
            );
        }
    }, [id, items]);
    
    useEffect(() => {
        if (items) {
            const selected = items?.find((o) => o.i_ua == id);
            selected && setDevice(selected);

            if (selected == undefined) {
                setInvalidDeviceId(true);
            }
            setIsSetDeviceComplete(true);
        }
    }, [items]);

    const handleDeleteAction = () => {
        dispatch(actions.deleteUA.request({
                i_ua: deleteDialog.i_ua || 0,
                callback: () => {
                    closeDeleteDialog();
                    showToast(t<string>('screens:devices.deviceDeleted'));
                    //@ts-ignore
                    window.history.replaceState(null, null, '/');
                    history.push(Routes.Devices);
                }
            }
        ));
        setIsDirty(false);
        setIsDirtyTemplateInfo(false);
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            i_ua: undefined,
            name: undefined
        });
    };

    const onChevronClick = () => {
        history.push(Routes.Devices);
    };

    const handleUpdateAction = useCallback((returnToList: boolean) => {
        setShowLoader(true);
        if (!form) {
            setShowLoader(false);
            return;
        }
        if(isDirtyTemplateInfo && templateInfo)
        {
            const initialDeviceList = templateInfo?.i_ua_list ?? [];
            const addedDevices = [form.i_ua];

            const updTemplates: UpdateOptionsTemplateRequest[] = [];
            const removeFromTemplate = templates?.find(e => e.i_ua_list?.find(c => c === form.i_ua));
            if(removeFromTemplate) {
                updTemplates.push({
                    template_info: {
                        i_ua_list: removeFromTemplate?.i_ua_list?.filter(e => e !== form.i_ua) ?? [],
                        i_ua_type: removeFromTemplate.i_ua_type,
                        name: removeFromTemplate.name,
                        i_ua_config_template: removeFromTemplate.i_ua_config_template
                    }
                });
            }

            const payload: UpdateOptionsTemplateRequest = {
                template_info: {
                    i_ua_list: [...initialDeviceList, ...addedDevices],
                    i_ua_type: templateInfo.i_ua_type,
                    name: templateInfo.name,
                    i_ua_config_template: templateInfo.i_ua_config_template
                }
            };
            updTemplates.push(payload);

            dispatch(actions.updateOptionsTemplate.request(updTemplates));
        }

        dispatch(actions.updateUa.request({
            object: form,
            callback: () => {
                showToast(t<string>('screens:devices.deviceUpdated'));
                setShowLoader(false);

                if (returnToList) {
                    window.history.replaceState(null, '', '/');
                    history.push(Routes.Devices);
                } else {
                    dispatch(
                        actions.devicesList.request({
                            i_ua: id,
                            with_available_ports: 1,
                            with_busy_ports: 1,
                        }),
                    );
                }
            },
            callbackOnError: () => {
                setShowLoader(false);
            }
        }));
    }, [form, isDirtyTemplateInfo, templateInfo, templates]);

    const portedDeviceOnSave = useCallback((isValid: boolean, returnToList = false) => {
        setValidate(false);
        if (isValid) handleUpdateAction(returnToList);
    }, [setValidate, handleUpdateAction]);

    let allLinesFree = false;
    if (device) {
        const freeLines = getFreeLines(device);
        allLinesFree = freeLines.left == freeLines.right;
    }
    
    useEffect(() => {
        if (device?.i_ua && device?.i_ua_type && profileDetails == undefined && i_customer && !isLoadingProfileDetails
            && devicesWithButtonsConfiguration && devicesWithButtonsConfiguration.find(e => e.value === device?.i_ua_type)
        ) {
            dispatch(actions.getProfileTemplate.request({
                i_customer: i_customer,
                i_ua: device?.i_ua
            }));
        }
    }, [device, profileDetails, i_customer, isLoadingProfileDetails, devicesWithButtonsConfiguration]);

    useEffect(() => {
        if(device?.i_ua && !templates && !isLoadingTemplates && i_customer) {
            dispatch(
                actions.getOptionsTemplateList.request({
                    i_customer: i_customer,
                    get_total: 1,
                    limit: 1000,
                    offset: 0
                })
            );
        }
    }, [templates, device, isLoadingTemplates, i_customer]);

    useEffect(() => {
        if(profileDetails?.i_ua_type)
        {
            dispatch(
                actions.getUaProfileMetaInfo.request({
                    i_ua_type: profileDetails.i_ua_type,
                })
            );
        }
    }, [profileDetails]);

    const isShowProfileTab = useMemo(() => {
        return devicesWithButtonsConfiguration && device &&
            devicesWithButtonsConfiguration.find(e => e.value == device.i_ua_type);
    }, [devicesWithButtonsConfiguration, device])

    const tabsArray = useMemo(() => {
        const array = [
            {
                title: t('common:general'),
                permission: Permission.Inventory.Devices.DeviceDetails.value,
                tab: (
                    <DeviceDetailsTab
                        device={device}
                        setForm={setForm}
                        validate={validate}
                        setIsDirty={setIsDirty}
                        portedDeviceOnSave={portedDeviceOnSave}    
                    />
                ),
            },
            {
                title: t('screens:devices.lines'),
                permission: Permission.Inventory.Devices.DeviceDetails.value,
                tab: (
                    <PortsConfigList
                        device={device}
                        className={classes.devicePorts}
                        isLoading={isLoading}
                    />
                ),
            }
        ];
        if(isShowProfileTab) {
            array.push(
                {
                    title: t('screens:devices.profile'),
                    permission: Permission.Inventory.Devices.DeviceDetails.value,
                    tab: (
                        <DeviceProfileDetailsTab
                            profile={templateInfo}
                            uaProfileMetaInfo={uaProfileMetaInfo} 
                            readonly
                            onChangeTemplate={(t: OptionsTemplateInfo) => {
                                setIsDirtyTemplateInfo(true);
                                dispatch(
                                    actions.getProfileTemplate.request({
                                        i_customer: i_customer,
                                        i_ua_config_template: t.i_ua_config_template
                                    })
                                );
                            }}
                        />
                    ),
                }
            );
        }
        return array;
    }, [device, setForm, validate, setIsDirty, portedDeviceOnSave, isLoading, templateInfo, 
        uaProfileMetaInfo, setIsDirtyTemplateInfo, isShowProfileTab, devicesWithButtonsConfiguration]);

    const isSaveActive = useMemo(() => {
        return (isDirty || isDirtyTemplateInfo);
    }, [isDirty, isDirtyTemplateInfo]);

    const {tabNames, tabs} = useMemo(() => generateTabs(
        tabsArray,
        permissions
    ), [tabsArray, permissions]);

    if ((isLoading && !isReleasingDevice && !isEditing && items == undefined) && !isSetDeviceComplete) {
        return (
            <PermissionPlaceholder permission={Permission.Inventory.Devices.DeviceDetails.value}>
                <div className={classes.loader}>
                    <Loader dataQa={'device-details-loader'}/>
                </div>
            </PermissionPlaceholder>)
    }

    return !isLoading && invalidDeviceId ? (
        <NotFound/>
    ) : (
        <PermissionProvider permission={Permission.Inventory.Devices.DeviceDetails.value}>
            <div className={classes.root}>
                {(showLoader) && (
                    <Loader
                        dataQa="edit-ring-device-loader"
                        absolutePosition
                    />
                )}
                <div className={classes.scrollableDeviceDetails}>
                    <DeviceTitle
                        device={device}
                        className={classes.deviceHeader}
                        onChevronClick={onChevronClick}
                    />
                    <DetailsWrapper
                        showActionButtons={false}
                        tabs={tabs}
                        tabsNames={tabNames}
                        headerCustomClass={classes.tabsHeader}
                        doNotControlForms
                        customRootClass={classes.rootDeviceDetailsPageTabRoot}
                    >
                    </DetailsWrapper>
                </div>
                {(device?.ported === 'Y' || isShowProfileTab) && editPagePermission !== PermissionType.Hidden && (
                    <FormActions
                        isSaveActive={isSaveActive}
                        onSave={() => {
                            if(!validate) {
                                setValidate(true);
                            }
                        }}
                        hideCancel={false}
                        hideDelete={!allLinesFree}
                        editPermission={Permission.Inventory.Devices.DeviceDetails.value}
                        deleteTitle={t('screens:devices.deleteDevice')}
                        onDelete={() => {
                            if (!form) {
                                return;
                            }
                            setDeleteDialog({isOpen: true, i_ua: form.i_ua, name: form.name});
                        }}
                        deleteButtonDisabled={!allLinesFree}
                        deletePermission={Permission.Inventory.Devices.DeviceDetails.DeleteDevice.value}
                        onCancel={onChevronClick}
                    />)}
                <AlertDialog
                    className={classes.deleteDialog}
                    contentClass={classes.contentDialog}
                    isOpen={deleteDialog.isOpen}
                    dataQa={'delete-rule-dialog'}
                    hideHeader
                    description={t('screens:devices.deleteRule', {
                        name: deleteDialog.name || '',
                    })}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={closeDeleteDialog}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={handleDeleteAction}
                        />,
                    ]}
                />
                <PreventLeavingPage
                    isOpen={isDirty || isDirtyTemplateInfo}
                    onSave={() => {
                        if(!validate) {
                            portedDeviceOnSave(true, true);
                        }
                    }}
                />
            </div>
        </PermissionProvider>
    );
};

export default DeviceDetails;
