import { UAInfo } from "../../store/types/Devices";
import { makeStyles } from "@material-ui/core";
import { Colors } from "../../styles/Colors";
import * as Yup from 'yup';
import i18n from '../../services/i18n';

export const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
        },
    },
    root: {
        '& .MuiDialogContent-root': {
            padding: 0,
            width: 722,
            height: 'fit-content'
        },
        '& .MuiGrid-item': {
            margin: 0,
        },
    },
    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        marginTop: 0,
        display: 'flex',
        width: 'calc(100% - 29px - 61px)',
        padding: '42px 29px 72px 61px',
        height: '100%',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    itemsContainerStep1: {
        padding: '66.5px 175px 45.9px 175px',
        width: 'calc(100% - 175px - 175px)',
    },
    uploadBox: {
        height: 'auto',
        width: '100%',
        display: 'flex',
        padding: 0,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 16,
    },
    boxField: {
        width: 304,
        maxWidth: 304,
    },
    loaderContainer: {
        display: 'flex',
        width: 372,
        height: 140,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        '& span': {
            marginTop: 20
        }
    },
    statusLabel: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        color: Colors.Text,
        marginTop: 40
    },
    modelLabel: {
        marginTop: 3,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '24px',
        color: Colors.Text3
    },
    resultsIcon: {
        width: 70.76,
        height: 70.76,
        transform: 'scale(1.577)'
    },
    uploadDoneText: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'center',
        color: Colors.Gray5,
        width: '100%'
    },
}));

export type AssignProfileDialogProps = {
    devices: UAInfo[];
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean, succsess: boolean) => void;
};

export type ModelsUniqueItem = {
    devices: UAInfo[],
    typeId: number;
    typeName: string;
    profileId: number;
}

export type DropDownDictionaryItemExtended<T, TExtended> = {
    name: string;
    value: T | undefined;
    extendedValue: TExtended;
};

const requiredFieldError = i18n.t('errors:common.emptyInput');
export const assignProfileValidationSchema = Yup.array()
    .of(
        Yup.object().shape({
            profileId: Yup.number().required(requiredFieldError),
        }),
    )
    .required();