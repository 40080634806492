import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
        position: 'relative'
    },

    scrollableDeviceDetails: {
        flex: 1,
        overflowY: 'hidden',
        height: 'fit-content',
        maxHeight: '100%'
    },

    deviceHeader: {
        padding: `50px 0px 16px 96px`,
        backgroundColor: Colors.White,
    },
    
    devicePorts: {
        maxWidth: 1040,
        display: 'flex',
        marginBottom: 156,
    },

    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '@media (max-width: 1276px)': {
        deviceHeader: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        deviceHeader: {
            paddingLeft: 90,
            paddingRight: 90,
        },
    },

    '@media (min-width: 1441px)': {
        deviceHeader: {
            paddingLeft: 96,
            paddingRight: 96,
        },
    },

    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '365px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
    },

    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },

    tabsHeader: {
        padding: 'unset'
    },

    rootDeviceDetailsPageTabRoot: {
        '& > :first-child': {
            overflowY: 'hidden',
            height: 'fit-content',
            maxHeight: 'calc(100vh - 130px)',
            
            '& [role="tabpanel"]': {
                '& > :last-child': {
                    height: 'fit-content !important',
                    overflowY: 'auto !important',
                    maxHeight: 'calc(100vh - 172px)',
                }
            }
        }
    }
}));

export type DeviceDetailsProps = {
    id: number;
};