import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DialogContainer, {
    DialogButton,
} from '../../AlertDialog/DialogContainer';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import TextField from '../../TextField/TextField';
import { Formik } from 'formik';
import { AddNewMenuForm, initialValues, useStyles } from './utils';
import IntervalSelect from '../../IntervalSelect/IntervalSelect';
import AllowCellars from '../../AllowCellers/AllowCellars';
import WhenMenuIsActive from '../../WhenMenuIsActive/WhenMenuIsActive';
import { actions } from '../../../store';
import { ReduxState } from '../../../store/types';
import Loader from '../../Loader/Loader';
import { settingsFormValidationSchema } from '../../Forms/AutoAttendantsMenus/SettingsForm.utils';
import { ExtensionType } from '../../../store/types/Extension';
import history from '../../../history';
import {IntervalStatus} from "../../IntervalSelect/IntervalSelect.utils";
import {EditAutoAttendantsMenuForm, isDirty} from "../../../views/AutoAttendants/AutoAttendantsMenuDetails.utils";

interface CreatMenuDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
}

const CreatMenuDialog: React.VFC<CreatMenuDialogProps> = ({
    isOpen,
    toggleVisibility,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [stateInitValues, setStateInitValues] = useState(initialValues);

    const loading = useSelector<ReduxState, boolean>(
        (state) => !!state.callQueues.isLoading,
    );

    const formSending = useSelector<ReduxState, boolean>(
        (state) => !!state.menus.isDataSaving,
    );

    const accountInfo = useSelector<ReduxState, ExtensionType | undefined>(
        (state) => state.autoAttendants.autoAttendantInfo?.account_info,
    );

    const refreshMenuList = (id: number) => {
        toggleVisibility?.();
        history.push(`${history.location.pathname}/menu/${id}`);
    };

    const onSubmitForm = (form: AddNewMenuForm) => {
        const { id, um_domain } = accountInfo ?? {};
        if (id && um_domain)
            dispatch(
                actions.createMenu.request({
                    login: id,
                    domain: um_domain,
                    dataToStore: form,
                    callback: (id: number) => refreshMenuList(id),
                }),
            );
    };

    useEffect(() => {
        isOpen && setStateInitValues(initialValues);
    }, [isOpen]);


    return (
        <Formik<AddNewMenuForm>
            initialValues={stateInitValues}
            validationSchema={formValidationSchema}
            onSubmit={onSubmitForm}
            enableReinitialize
            validateOnChange={false}
        >
            {({
                submitForm,
                dirty,
                handleChange,
                values,
                errors,
                resetForm,
                setFieldError,
                  initialValues
            }) => (
                <>
                    <DialogContainer
                        isOpen={isOpen}
                        dataQa="add-new-menu-dialog"
                        header={t('screens:autoAttendants.addAMenuDialogTitle')}
                        className={classes.dialogContainer}
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={() => {
                                    toggleVisibility?.();
                                    resetForm();
                                }}
                                dataQa={'add-new-menu-dialog-cancel-button'}
                            />,
                            <DialogButton
                                disabled={!isDirty(dirty, values as EditAutoAttendantsMenuForm, initialValues as EditAutoAttendantsMenuForm) || !values.name }
                                key="save"
                                label={t('common:save')}
                                onClick={submitForm}
                                dataQa={'add-new-menu-dialog-save-button'}
                                primary
                            />,
                        ]}
                    >
                        {loading ? (
                            <Loader
                                dataQa="fetch-data-new-menu-loader"
                                absolutePosition={true}
                                dataTestId={'fetch-data-new-menu-loader'}
                            />
                        ) : (
                            <>
                                <form className={classes.inputs}>
                                    <Grid
                                        item
                                        className={classes.itemsContainer}
                                    >
                                        <TextField
                                            id="name"
                                            label={t(
                                                'screens:autoAttendants.menuName',
                                            )}
                                            onChange={handleChange}
                                            value={values.name}
                                            setFieldError={setFieldError}
                                            dataQa="new-menu-name-input"
                                            helperText={
                                                (typeof errors?.name ===
                                                    'string' &&
                                                    errors?.name) ||
                                                ''
                                            }
                                            required
                                            maxLength={64}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        className={classNames(
                                            classes.itemsContainer,
                                            classes.intervalsContainer,
                                        )}
                                    >
                                        <IntervalSelect />
                                    </Grid>

                                    {values.intervals.activity !==
                                        IntervalStatus.Always && (
                                        <>
                                            <div
                                                className={classes.separator}
                                            />
                                            <Grid
                                                item
                                                className={
                                                    classes.itemsContainer
                                                }
                                            >
                                                <WhenMenuIsActive />
                                            </Grid>
                                        </>
                                    )}

                                    <div className={classes.separator} />

                                    <Grid
                                        item
                                        className={classes.itemsContainer}
                                    >
                                        <AllowCellars />
                                    </Grid>
                                </form>
                                {formSending && (
                                    <Loader
                                        dataQa="add-new-menu-loader"
                                        absolutePosition={true}
                                        dataTestId={'add-new-menu-loader'}
                                    />
                                )}
                            </>
                        )}
                    </DialogContainer>
                </>
            )}
        </Formik>
    );
};

const formValidationSchema = settingsFormValidationSchema;

export default CreatMenuDialog;
