import React, {useState} from 'react';
import FormActions from '../Forms/FormActions';
import PreventLeavingPage from '../AlertDialog/PreventLeavingPage';
import {useStyles} from './utils';
import Tabs from '../Tabs/Tabs';
import {getTabNumber} from '../../utils/getTabNumber';
import classNames from 'classnames';
import {PermissionType} from '../../store/types/Permission';

interface DetailsWraper {
    showActionButtons?: boolean;
    onBackPress?: () => void;
    onSavePress?: () => void;
    onDeletePress?: () => void;
    deleteEnable?: boolean;
    saveEnable?: boolean;
    deleteText?: string;
    preventIsOpen?: boolean;
    preventSavePress?: () => void;
    tabsNames?: (string | undefined)[];
    defaultTabIndex?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTabChange?: (event: React.ChangeEvent<{}>, value: any) => void;
    top?: React.ReactNode;
    tabs: (JSX.Element | undefined | boolean)[];
    callFlowOnFirstTab?: boolean;
    hideCancel?: boolean;
    savePermission?: string[];
    deletePermission?: string[];
    subTabMode?: boolean;
    customRightSideComponent?: React.ReactNode;
    doNotControlForms?: boolean;
    customizeDeletePermission?: (permissionValue: PermissionType) => PermissionType;
    headerCustomClass?: string,
    perm?:PermissionType[],
    actionsCustomClass?: string,
    noPadding?: boolean,
    customFormContainerClass?: string,
    customRootClass?: string,
}

const DetailsWrapper: React.FC<DetailsWraper> = (
    {
        showActionButtons,
        onBackPress,
        onSavePress,
        onDeletePress,
        saveEnable,
        deleteEnable,
        deleteText,
        preventIsOpen,
        preventSavePress,
        tabsNames,
        tabs,
        top,
        onTabChange,
        children,
        callFlowOnFirstTab,
        hideCancel,
        savePermission,
        deletePermission,
        subTabMode,
        customRightSideComponent,
        doNotControlForms = false,
        defaultTabIndex,
        customizeDeletePermission,
        headerCustomClass,
        perm,
        actionsCustomClass,
        noPadding,
        customFormContainerClass,
        customRootClass
    }) => {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(
        defaultTabIndex ?? getTabNumber([...Array(tabsNames?.length || 0).keys()]),
    );

    const mainBody = (
        <div className={classNames(
            !subTabMode ? classes.scrollable : null,
            subTabMode ? classes.scrollableMinHeight : null,
        )}>
            <div className={classNames(
                classes.headerContainer,
                subTabMode ? classes.hidden : null,
                headerCustomClass ? headerCustomClass: null,
            )}>
                {top}
            </div>
            <Tabs
                tabsNames={tabsNames || []}
                defaultTabIndex={tabIndex}
                onChange={(_, index) => {
                    setTabIndex(index);
                    onTabChange?.(_, index);
                }}
                className={classNames(
                    classes.tabs,
                    subTabMode ? classes.subTubs : null
                )}
                subTabMode={subTabMode}
                CustomRightSideComponent={customRightSideComponent}
                permissions={perm?.filter(val => val !== PermissionType.Hidden) || []}
                noPadding={noPadding}
            >
                {tabs}
            </Tabs>
            {children}
        </div>);

    return (
        <div
            className={classNames(
                classes.root,
                callFlowOnFirstTab ? classes.customTab : null,
                subTabMode ? classes.customTabOnSubTabMode : null,
                noPadding && classes.noPadding,
                customRootClass
            )}
        >
            {!doNotControlForms ? (
                <form className={classNames(classes.formContainer, customFormContainerClass)}>
                    {mainBody}
                    {showActionButtons && (
                        <FormActions
                            onCancel={onBackPress}
                            onSave={onSavePress}
                            deleteTitle={deleteEnable ? deleteText : undefined}
                            onDelete={onDeletePress}
                            deleteButtonDisabled={!deleteEnable}
                            isSaveActive={saveEnable}
                            hideCancel={hideCancel}
                            deletePermission={deletePermission}
                            editPermission={savePermission}
                            customizeDeletePermission={customizeDeletePermission}
                            className={actionsCustomClass}
                        />
                    )}
                    <PreventLeavingPage
                        isOpen={preventIsOpen}
                        onSave={preventSavePress}
                    />
                </form>) : (
                <>
                    {mainBody}
                </>
            )}
        </div>
    );
};

export default DetailsWrapper;
