import React, { useCallback, useEffect, useMemo } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import { Permission } from '../../store/types/Permission';
import DeviceProfileTitle from './DeviceProfileTitle';
import Loader from '../../components/Loader/Loader';
import { Colors } from '../../styles/Colors';
import { actions } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import { generateTabs } from '../../utils/generateTabs';
import { useRawPermissions } from '../../hooks/usePermissions';
import DeviceProfileDetailsTab from './DeviceProfileDetailsTab';
import PermissionPlaceholder from '../../components/PermissionProvider/PermissionPlaceholder';
import history from '../../history';
import { Routes } from '../../routes/routes';

export const useStyle = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
        position: 'relative'
    },

    deviceProfileHeader: {
        padding: `50px 0px 24px 96px`,
        backgroundColor: Colors.White,
    },
    
    '@media (max-width: 1276px)': {
        deviceHeader: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
    
    '@media (min-width:1277px) and (max-width: 1440px)': {
        deviceHeader: {
            paddingLeft: 90,
            paddingRight: 90,
        },
    },

    '@media (min-width: 1441px)': {
        deviceHeader: {
            paddingLeft: 96,
            paddingRight: 96,
        },
    },

    scrollableDeviceProfileDetails: {
        flex: 1,
        //overflowY: 'auto',
        height: 'fit-content',
        //maxHeight: '100%',
    },
    
    tabsHeader: {
        display: 'none'
    },
    
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    customRootClassForDeviceProfilePage: {
        '& > :first-child': {
            '& > :last-child': {
                overflowY: 'hidden',
                height: 'fit-content',
                
                '& > :last-child': {
                    overflow: 'auto !important',
                    height: 'fit-content !important'
                }
            }
        }
    }
}));

export interface DeviceProfileDetailsProps {
    id: number;
    copy?: boolean;
}

const DeviceProfileDetails: React.FC<DeviceProfileDetailsProps> = ({
    id,
    copy
}) => {
    const classes = useStyle();
    const {t} = useTranslation();
    const showLoader = false;
    const dispatch = useDispatch();
    const permissions = useRawPermissions();
    const i_customer: number = useSelector((state: ReduxState) => state.generic?.sessionData?.i_customer ?? 0);

    const {
        profileDetails,
        uaProfileMetaInfo,
        isLoadingMetadata,
        isTypeListLoading,
        isLoadingTemplates,
        isUpdatingOptionsTemplate,
        isLoadingProfileDetails
    } = useSelector((state: ReduxState) => state.devices);

    const onChevronClick = useCallback(() => {
        history.push(Routes.DeviceProfiles);
    }, []);

    useEffect(() => {
        if (id && profileDetails == undefined && i_customer && !isLoadingProfileDetails) {
            dispatch(
                actions.getProfileTemplate.request({
                    i_customer: i_customer,
                    i_ua_config_template: id
                })
            );
        }
    }, [i_customer, id, profileDetails, isLoadingProfileDetails]);

    const isLoading = useMemo(() => {
        return isLoadingMetadata 
            || isTypeListLoading 
            || isLoadingTemplates
            || !i_customer 
            || !profileDetails 
            || isUpdatingOptionsTemplate
            || !uaProfileMetaInfo;
    }, [isLoadingMetadata, isTypeListLoading, isLoadingTemplates, i_customer, 
            profileDetails, isUpdatingOptionsTemplate, uaProfileMetaInfo]);

    useEffect(() => {
        if(profileDetails?.i_ua_type)
        {
            if(isLoadingMetadata !== undefined || uaProfileMetaInfo) {
                return;
            }
            dispatch(
                actions.getUaProfileMetaInfo.request({
                    i_ua_type: profileDetails.i_ua_type,
                })
            );
        }
    }, [profileDetails, isLoadingMetadata]);

    const {tabNames, tabs} = useMemo(() => generateTabs(
        [
            {
                title: t('screens:deviceProfiles.programmableKeys'),
                permission: Permission.Inventory.DeviceProfiles.value,
                tab: (
                    <DeviceProfileDetailsTab
                        profile={profileDetails}
                        uaProfileMetaInfo={uaProfileMetaInfo} 
                        onSaveComplete={() => {
                            dispatch(
                                actions.getProfileTemplate.request({
                                    i_customer: i_customer,
                                    i_ua_config_template: id
                                })
                            )
                        }}
                    />
                ),
            }
        ],
        permissions
    ), [profileDetails, uaProfileMetaInfo, i_customer, id]);
    
    if (isLoading) {
        return (
            <PermissionPlaceholder permission={Permission.Inventory.DeviceProfiles.value}>
                <div className={classes.loader}>
                    <Loader dataQa={'device-details-loader'}/>
                </div>
            </PermissionPlaceholder>)
    }

    return (
        <PermissionProvider permission={Permission.Inventory.DeviceProfiles.value}>
            <div className={classes.root}>
                {(showLoader) && (
                    <Loader
                        dataQa="edit-device-profile-loader"
                        absolutePosition
                    />
                )}
                <div className={classes.scrollableDeviceProfileDetails}>
                    <DeviceProfileTitle
                        profile={profileDetails}
                        className={classes.deviceProfileHeader}
                        onChevronClick={onChevronClick}
                        copy={copy ?? false}
                    />
                    
                    <DetailsWrapper
                        showActionButtons={false}
                        tabs={tabs}
                        tabsNames={tabNames}
                        headerCustomClass={classes.tabsHeader}
                        customRootClass={classes.customRootClassForDeviceProfilePage}
                        doNotControlForms
                    >
                    </DetailsWrapper>
                </div>
            </div>
        </PermissionProvider>
    );
};

export default DeviceProfileDetails;
