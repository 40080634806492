
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import { GetUAProfileMetaInfoResponse, OptionsTemplateInfo, UAProfileKeyActionConfiguration } from '../../store/types/Devices';
import { DeviceProfileDetailsActionKeyRowData } from './DeviceProfileDetailsActionKeyRow.utils';
import i18n from '../../services/i18n';
import { Colors } from '../../styles/Colors';

export type DeviceProfileDetailsTabProps = {
    profile?: OptionsTemplateInfo;
    dataQa?: string;
    className?: string;
    showAsciiValue?: boolean;
    uaProfileMetaInfo: GetUAProfileMetaInfoResponse | undefined;
    onSaveComplete?: () => void;
    readonly?: boolean;
    onChangeTemplate?: (v: OptionsTemplateInfo) => void;
};

export type DeviceProfileDetailsTabFormType = {
    profileName: string;
    softKeys: DeviceProfileDetailsActionKeyRowData[],
    lineKeys: DeviceProfileDetailsActionKeyRowData[],
    expansionKeys: DeviceProfileDetailsActionKeyRowData[],
};

export const useStyle = makeStyles(() => ({
    topContainer: {
        position: 'relative',
    },
    rootDeviceProfileDetailsTab: {
        position: 'relative',
    },
    itemsContainer: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },

    ascii: {
        width: 440
    },

    dual: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: 12,
        paddingTop: 0,
        '& .MuiFormControl-root': {
            maxWidth: 440,
        },
        '& div.container':{
            width:'100%'
        }
    },

    toEnd: {
        justifyContent: 'end',
        display: 'flex',
        width:'100%'
    },

    profileName: {
        width: '400px !important',
        maxWidth: '400px !important',
        height: 58,
        marginBottom: 22,
    },

    descriptionTooltipContainer: {
        position: 'absolute',
        marginTop: -35,
        marginLeft: 9,
        right: 32,
        left: 32,
        opacity: 0,
    },

    tooltipTitle: {
        wordBreak: 'break-word',
    },

    initMacGeneral: {
        width: '100%',
        border: 0,
        margin: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
        verticalAlign: 'top',
        maxWidth: 440
    },

    scrollInsideDual: {
        height: 'fit-content',
        width: 676,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginBottom: 190,
        marginTop: 12,
    },

    sectionHeader: {
        width: 676,
        height: 40,
        minHeight: 40,
        marginBottom: 8,
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left'
    },

    keyPadContainer: {
        top: 12,
        height: 'calc(100vh - 120px - 49px - 40px)',
        position: 'sticky',
        display: 'flex',
        justifyContent: 'center',
        width: 'calc(100% - 676px)',
        flexDirection: 'row',
    },

    containerOfImageAndButtons: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },

    imageAndButtonsContainer: {
        position: 'relative'
    },

    phoneImageContainer: {
        userSelect: 'none',
    },

    actionButtonsContainer: {
        top: 'calc(100vh - 96px)',
        left: 96,
    },
    
    '@media (max-width: 1276px)': {
        actionButtonsContainer: {
            top: 'calc(100vh - 96px)',
            left: 24,
        },
    },
    
    '@media (min-width:1277px) and (max-width: 1440px)': {
        actionButtonsContainer: {
            top: 'calc(100vh - 96px)',
            left: 90,
        },
    },

    '@media (min-width: 1441px)': {
        actionButtonsContainer: {
            top: 'calc(100vh - 96px)',
            left: 96,
        },
    },

    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '365px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
    },
    
    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },

    copyButtonStyle: {
        width: 'fit-content',
        textWrapMode: 'nowrap',
        cursor: 'pointer'
    },

    profileDropdownContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    externalLinkeContainer: {
        paddingTop: 20,
        paddingLeft: 30,
        cursor: 'pointer',
    }
}));

const requiredFieldError = i18n.t('errors:common.emptyInput');
const actionKeyValidator = Yup.object().shape({
    value: Yup.string().when('selectedActionConfiguration', {
        is: (selectedActionConfiguration: UAProfileKeyActionConfiguration[]) => 
            {
                const mandatory = selectedActionConfiguration
                    ?.find(e => e.attribute === 'value')
                    ?.mandatory ?? 0;
                return mandatory > 0;
            },
        then: Yup.string().required(requiredFieldError),
        otherwise: Yup.string().notRequired().nullable(),
    }),
    label: Yup.string().when('selectedActionConfiguration', {
        is: (selectedActionConfiguration: UAProfileKeyActionConfiguration[]) => 
            {
                const mandatory = selectedActionConfiguration
                    ?.find(e => e.attribute === 'label')
                    ?.mandatory ?? 0;
                return mandatory > 0;
            },
        then: Yup.string().required(requiredFieldError),
        otherwise: Yup.string().notRequired().nullable(),
    }),
});

export const deviceProfileValidationSchema = Yup.object().shape({
    softKeys: Yup.array()
        .of(actionKeyValidator)
        .notRequired(),
    lineKeys: Yup.array()
        .of(actionKeyValidator)
        .notRequired(),
    expansionKeys: Yup.array()
        .of(actionKeyValidator)
        .notRequired(),
    profileName: Yup.string().required(requiredFieldError),
});