import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/Tabs/Tabs';
import history from '../../history';
import { ReduxState } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { useEditAutoAttendantsMenuFormData } from '../../hooks/useEditAutoAttendantMenuFormData';
import ExtensionTitle from '../../components/Extensions/ExtensionTitle';
import FormActions from '../../components/Forms/FormActions';
import AutoAttendantName from '../../components/AutoAttendants/AutoAttendantName';
import SettingsForm from '../../components/Forms/AutoAttendantsMenus/SettingsForm';
import PromptForm from '../../components/Forms/AutoAttendantsMenus/PromptForm';
import ActionsForm from '../../components/Forms/AutoAttendantsMenus/ActionsForm';
import { actions } from '../../store';
import { getTabNumber } from '../../utils/getTabNumber';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { DialogButton } from '../../components/AlertDialog/DialogContainer';
import { getRoute, Routes } from '../../routes/routes';
import usePageTitle from '../../hooks/usePageTitle';
import { generateTabs } from '../../utils/generateTabs';
import { Permission, PermissionType } from '../../store/types/Permission';
import { useRawPermissions } from '../../hooks/usePermissions';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {
    AutoAttendantsMenuDetailsProps,
    EditAutoAttendantsMenuForm,
    formValidationSchema,
    isDirty,
    useStyles,
} from './AutoAttendantsMenuDetails.utils';

const AutoAttendantsMenuDetails: React.VFC<AutoAttendantsMenuDetailsProps> = ({
    autoAttendantId,
    menuId,
}) => {
    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2]));
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const permissions = useRawPermissions();

    const loading = useSelector<ReduxState, boolean>(
        (state) => state.menus.isDataLoading ?? false,
    );

    const isFormSaving = useSelector<ReduxState, boolean>(
        (state) => !!state.menus.isDataSending,
    );

    const { initialValues, data } = useEditAutoAttendantsMenuFormData();

    useEffect(() => {
        if (autoAttendantId?.length && menuId?.length) {
            dispatch(
                actions.getMenuDetails.request({ autoAttendantId, menuId }),
            );
        }
    }, [autoAttendantId, menuId]);

    const onSubmitForm = useCallback(
        (form: EditAutoAttendantsMenuForm) => {
            if (
                data.autoAttendantInfo?.account_info?.i_account &&
                data.menuDetails
            ) {
                dispatch(
                    actions.editMenu.request({
                        menuId: data.menuDetails.i_menu,
                        initialValues,
                        changedValues: form,
                        redirectTab: tabIndex,
                    }),
                );
            }
        },
        [initialValues, autoAttendantId, menuId, tabIndex],
    );

    const removeMenu = useCallback(() => {
        dispatch(
            actions.removeMenu.request({
                i_menu: parseInt(menuId),
                autoAttendantId: parseInt(autoAttendantId),
                withRedirectToList: true,
            }),
        );
        setIsRemoveModalOpen(false);
    }, [menuId, autoAttendantId]);

    const onBackPress = useCallback(() => {
        history.push(
            getRoute(Routes.AutoAttendantsDetails, {
                id: autoAttendantId,
            }),
        );
    }, []);

    if (loading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="auto-attendant-menu-loader" />
            </div>
        );
    }

    const { tabs, tabNames } = generateTabs(
        [
            {
                title: t('common:settings'),
                permission:
                    Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Menu
                        .MenuDetails.MenuSettings.value,
                tab: <SettingsForm />,
            },
            {
                title: t('screens:autoAttendants.prompts'),
                permission:
                    Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Menu
                        .MenuDetails.MenuPrompts.value,
                tab: <PromptForm />,
            },
            {
                title: t('common:actions'),
                permission:
                    Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Menu
                        .MenuDetails.MenuActions.value,
                tab: <ActionsForm />,
            },
        ],
        permissions,
    );

    return (
        <Formik<EditAutoAttendantsMenuForm>
            initialValues={initialValues}
            validationSchema={formValidationSchema}
            onSubmit={onSubmitForm}
            enableReinitialize
            validateOnChange={false}
        >
            {({ submitForm, dirty, initialValues, values }) => (
                <>
                    <div className={classes.root}>
                        <form className={classes.formContainer}>
                            <div className={classes.scrollable}>
                                <div className={classes.extensionHeader}>
                                    <div className={classes.extensionTop}>
                                        <ExtensionTitle
                                            headerText={t(
                                                'screens:autoAttendants.menu',
                                            )}
                                            isBlocked={false}
                                            onChevronClick={onBackPress}
                                            dataQa={
                                                'auto-attendant-details-title'
                                            }
                                            subTitle={data.menuDetails?.name}
                                            customClasses={{
                                                header: classes.header,
                                            }}
                                        />
                                        <div
                                            className={classes.extensionStatus}
                                        >
                                            <AutoAttendantName
                                                name={
                                                    data.autoAttendantInfo
                                                        ?.name ||
                                                    t('common:unnamed')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Tabs
                                    tabsNames={tabNames}
                                    defaultTabIndex={tabIndex}
                                    onChange={(_, index) => setTabIndex(index)}
                                >
                                    {tabs}
                                </Tabs>
                            </div>

                            <PermissionProvider
                                permission={
                                    Permission.CloudPBX.AutoAttendants
                                        .AutoAttendantDetails.Menu.MenuDetails
                                        .value
                                }
                            >
                                <FormActions
                                    onCancel={onBackPress}
                                    onSave={submitForm}
                                    isSaveActive={isDirty(
                                        dirty,
                                        values,
                                        initialValues,
                                    )}
                                    onDelete={() => setIsRemoveModalOpen(true)}
                                    deleteTitle={t(
                                        'screens:autoAttendants.deleteMenu',
                                    )}
                                    deletePermission={
                                        Permission.CloudPBX.AutoAttendants
                                            .AutoAttendantDetails.Menu
                                            .DeleteMenu.value
                                    }
                                    customizeDeletePermission={(
                                        value: PermissionType,
                                    ) => {
                                        if (initialValues.name === 'ROOT') {
                                            return PermissionType.Hidden;
                                        }
                                        if (!values.deleteEnabled) {
                                            return PermissionType.Hidden;
                                        }
                                        return value;
                                    }}
                                />
                            </PermissionProvider>
                        </form>

                        {isFormSaving && (
                            <Loader
                                dataQa="edit-auto-attendant-menu-sending-loader"
                                absolutePosition
                            />
                        )}
                    </div>

                    <AlertDialog
                        isOpen={isRemoveModalOpen}
                        hideHeader
                        description={t(
                            'screens:autoAttendants.deleteMenuDescription',
                            {
                                value: data.menuDetails?.name,
                            },
                        )}
                        className={classes.removeModalContainer}
                        contentClass="alert-content"
                        dialogActionsButtons={[
                            <DialogButton
                                key="no"
                                label={t('common:cancel')}
                                onClick={() => {
                                    setIsRemoveModalOpen(false);
                                }}
                            />,
                            <DialogButton
                                key="yes"
                                label={t('common:delete')}
                                className={classes.primaryModalButton}
                                onClick={removeMenu}
                            />,
                        ]}
                        dataQa="remove-menu-modal"
                    />
                </>
            )}
        </Formik>
    );
};

export default AutoAttendantsMenuDetails;
