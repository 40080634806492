import React, { useMemo } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '../../components/IconButton/IconButton';
import { DeviceProfileDetailsActionKeyRowData } from './DeviceProfileDetailsActionKeyRow.utils';
import DeviceProfileDetailsActionKeyIconButton from './DeviceProfileDetailsActionKeyIconButton';

export type DeviceProfileDetailsActionKeyButtonProps = {
    scale: number;
    item: DeviceProfileDetailsActionKeyRowData;
    onClick?: (item: DeviceProfileDetailsActionKeyRowData) => void;
};

export const useStyle = makeStyles(() => ({
    keypadButtonRoot: {
        position: 'absolute',
        width: 20,
        height: 20,
    },
    buttonContainer: {
        width: 20,
        height: 20,
        padding: 0
    },
}));

const DeviceProfileDetailsActionKeyButton: React.FC<DeviceProfileDetailsActionKeyButtonProps> = ({
    scale,
    item,
    onClick
}) => {
    const classes = useStyle();

    const x = useMemo(() => {
        return (item?.position?.x) * scale;
    }, [scale, item]);
    
    const y = useMemo(() => {
        return (item?.position?.y) * scale;
    }, [scale, item]);

    return (<div className={classes.keypadButtonRoot} style={{
        display: item?.position ? 'block' : 'none',
        left: x + 'px',
        top: y + 'px'
    }}>
            <IconButton
                onClick={() => {
                    onClick?.(item);
                }}
                data-testid={'icon_button_' + item.itemIdentifierInternal}
                data-qa={'icon_button_' + item.itemIdentifierInternal}
                className={classes.buttonContainer}
            >
                <DeviceProfileDetailsActionKeyIconButton item={item} size='small' />
            </IconButton>
        </div>
    );
};
    
export default DeviceProfileDetailsActionKeyButton;