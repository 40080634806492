import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '../TextField/TextField';
import {useTranslation} from 'react-i18next';
import {DeviceInfoProps, renderByDefinition, useStyle} from './DeviceInfo';
import {DevicesFaultCodes, UAInfo, UATypeInfo} from '../../store/types/Devices';
import {useFormik} from 'formik';
import {
    addDeviceValidationSchema,
    formatAsciiAesAddress,
    macAddressLengthFunc,
    macAddressLengthFuncForMask,
    MacAddressPlaceHolder
} from '../../views/Devices/NewDeviceDialog.utils';
import CustomizedTextField from '../TextField/TextField';
import {ReduxState} from '../../store/types';
import {useSelector} from 'react-redux';
import TextFieldWithMask from '../TextFieldWithMAsk/TextFieldWithMask';
import MacMaskForFilters from '../TextFieldWithMAsk/Masks/MacMaskForFilters';
import MacMaskForFiltersWithoutMask from '../TextFieldWithMAsk/Masks/MacMaskForFiltersWithoutMask';
import AsciiTextField from "../AsciiTextField/AsciiTextField";
import {usePermissionContext} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';

const DEVICE_NAME_DUPLICATE_ERROR_DESCRIPTION = "The 'name' field is not unique";

export const PortedDeviceInitialValues: UAInfo = {
    mac: '',
    name: '',
    ascii_key: '',
    inventory_id: '',
    description: '',
    used: '',
    ports_config: [],
    i_ua: 0,
    type: ''
};

const PortedDeviceInfoForm: React.FC<DeviceInfoProps & {
    setDirty: (isDirty: boolean) => void,
    setIsFormValid: (isValid: boolean) => void,
    setFormValues: (device: UAInfo) => void,
    onSubmitValidation: boolean
}> = ({
            device,
            className,
            dataQa,
            definition,
            customClasses,
            setDirty,
            setFormValues,
            onSubmitValidation,
            setIsFormValid
    }) => {
    const classes = useStyle();
    const {t} = useTranslation();
    const [macAddressFocusOn, setMackAddressFocusOn] = useState(false);

    const infoFromDefinition = renderByDefinition(device, definition);

    const {isUpdating, updateApiError, ua_type_list} = useSelector(
        (state: ReduxState) => state.devices,
    );

    const [initialValues, setInitialValues] = useState<UAInfo>(PortedDeviceInitialValues);

    const model = useMemo(() => {
        return ua_type_list?.find((type: UATypeInfo) => type.i_ua_type == device?.i_ua_type)
    }, [ua_type_list, device]);

    const permission = usePermissionContext();
    const initValuesWithModel = {...initialValues, model};
    const {
        values,
        setFieldValue,
        handleChange,
        errors,
        setFieldError,
        dirty,
        validateForm
    } = useFormik<UAInfo>({
        //@ts-ignore
        device,
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: addDeviceValidationSchema,
        initialValues: initValuesWithModel,
        validateOnBlur: true
    });

    useEffect(() => {
        if (device) {
            setInitialValues(device);
        }
    }, [device]);

    useEffect(() => {

        const newDirty = dirty &&
            (values.ascii_key?.length == 0 || !![16, 32, 64].find((n: number) => n === values.ascii_key?.length));

        setDirty(newDirty);

    }, [dirty, values.ascii_key]);

    useEffect(() => {
        setFormValues(values);
    }, [values]);

    useEffect(() => {
        if (onSubmitValidation) {
            validateForm(values).then(res => {
                const hasErrors = res && JSON.stringify(res) !== JSON.stringify({});
                setIsFormValid(!hasErrors);
            });
        }
    }, [onSubmitValidation]);

    useEffect(() => {
        if (!isUpdating && updateApiError) {
            switch (updateApiError.faultcode) {
                case DevicesFaultCodes.EditDeviceMacDuplicate:
                    setFieldError('mac', t('errors:devices.macIsNotUnique'));
                    break;
                case DevicesFaultCodes.EditDeviceGeneralError:
                    if (updateApiError.faultstring === DEVICE_NAME_DUPLICATE_ERROR_DESCRIPTION) {
                        setFieldError('name', t('errors:devices.deviceNameIsNotUnique'));
                    }
                    break;
            }
        }
    }, [updateApiError, isUpdating]);

    return (
        <div
            className={classNames(classes.root, className)}
            data-qa={dataQa}
            data-testid={'device-info'}
        >
            <form>
                <Grid
                    container
                    spacing={3}
                    className={classNames(
                        classes.itemsContainer,
                        customClasses?.container || '',
                    )}
                >
                    {definition?.length ? (
                        infoFromDefinition
                    ) : (
                        <>
                            <div className={classes.dual}>
                                <TextField
                                    id="model"
                                    label={t('screens:devices.model')}
                                    value={device?.type}
                                    readOnly
                                    className={customClasses?.field || ''}
                                />

                                <TextFieldWithMask
                                    id="mac"
                                    label={t('screens:devices.macAddress')}
                                    value={values?.mac}
                                    helperText={
                                        errors.mac && errors.mac.length > 0
                                            ? errors.mac
                                            : ''
                                    }
                                    setFieldError={setFieldError}
                                    placeholder={MacAddressPlaceHolder}
                                    required
                                    className={classNames(
                                        customClasses?.field || '',
                                        classes.initMacGeneral
                                    )}
                                    onFocus={() => {
                                        setMackAddressFocusOn(true);
                                    }}
                                    onBlur={() => {
                                        setMackAddressFocusOn(false);
                                    }}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'mac',
                                            e.target.value
                                        );
                                    }}
                                    mask={
                                        macAddressFocusOn
                                            ? MacMaskForFilters
                                            : MacMaskForFiltersWithoutMask
                                    }
                                    autoComplete={"off"}
                                    InputProps={{
                                        maxLength: MacAddressPlaceHolder.length,
                                        max: MacAddressPlaceHolder.length,
                                        required: true,
                                        disabled: permission === PermissionType.ReadOnly,
                                    }}
                                    counterText={
                                        macAddressLengthFunc(values.mac) + '/' + macAddressLengthFuncForMask(MacAddressPlaceHolder)
                                    }
                                />
                            </div>

                            <div className={classes.dual}>
                                <CustomizedTextField
                                    id="name"
                                    label={t('screens:devices.deviceName')}
                                    value={values?.name}
                                    helperText={
                                        errors.name && errors.name.length > 0
                                            ? errors.name
                                            : ''
                                    }
                                    setFieldError={setFieldError}
                                    onChange={handleChange}
                                    required
                                    iconPosition="end"
                                    maxLength={50}
                                    dataQa="devicename-field"
                                />

                                <AsciiTextField
                                    value={values.ascii_key}
                                    onChange={(e: any) => {
                                        setFieldValue(
                                            'ascii_key',
                                            formatAsciiAesAddress(e.target.value)
                                        );
                                    }}
                                    setFieldError={setFieldError}
                                    error={errors.ascii_key && errors.ascii_key.length > 0
                                        ? errors.ascii_key
                                        : ''}
                                    model={model}
                                    className={classes.ascii}
                                />
                            </div>

                            <div className={classes.dual}>
                                <div className={'container'}>
                                    <CustomizedTextField
                                        id="inventory_id"
                                        label={t('screens:devices.inventoryId')}
                                        value={values?.inventory_id}
                                        helperText={
                                            errors.inventory_id && errors.inventory_id.length > 0
                                                ? errors.inventory_id
                                                : ''
                                        }
                                        setFieldError={setFieldError}
                                        onChange={handleChange}
                                        maxLength={64}
                                        dataQa="inventory-id-field"
                                    />
                                </div>

                                <div className={classes.toEnd}>
                                    <CustomizedTextField
                                        id="description"
                                        label={t('screens:devices.description')}
                                        value={values?.description}
                                        helperText={
                                            errors.description && errors.description.length > 0
                                                ? errors.description
                                                : ''
                                        }
                                        setFieldError={setFieldError}
                                        onChange={handleChange}
                                        maxLength={255}
                                        dataQa="description-field"
                                    />
                                </div>

                            </div>
                        </>
                    )}
                </Grid>
            </form>
        </div>
    );
};

export default PortedDeviceInfoForm;
