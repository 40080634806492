import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactFlow, {ConnectionLineType, OnLoadParams, removeElements,} from 'react-flow-renderer';
import StartNode from './components/StartNode';
import {getLayoutedElements} from './utils/getLayoutedNodes';
import MenuNode from './components/MenuNode';
import ActionNode from './components/ActionNode';
import {generateFlowBasedOnTransitions} from './utils/generateFlowBasedOnTransitions';
import AddNode from './components/AddNode';
import Connector from './components/Connector';
import CustomControls from './components/Controls';
import HoursNode from './components/HoursNode';
import {FlowElements} from './utils/types';
import {MenuActionFormType} from '../../AutoAttendantsMenus/ActionsForm.utils';
import MenuActionDialog from '../../../AutoAttendants/MenuActionDialog';
import {useFormikContext} from 'formik';
import lodash from 'lodash';
import EditMenuDialog from '../../../AutoAttendants/EditMenu/EditMenuDialog';
import {AutoAttendantMenu, UserInput} from '../../../../store/types/AutoAttendant';
import CustomizedSwitch from '../../../Switch/Switch';
import {useTranslation} from 'react-i18next';
import {useStyles} from './IvrFlowDiagram.utils';
import {Permission} from '../../../../store/types/Permission';
import {useAutoAttendantMenuFormTab} from '../../../../hooks/useAutoAttendantMenuFormTab';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../../store/types';
import {ServiceFeatureName} from '../../../../store/types/ServiceFeature';
import {EditAutoAttendantForm} from "../../../../views/AutoAttendants/Details/utils";
import {EditAutoAttendantsMenuForm} from "../../../../views/AutoAttendants/AutoAttendantsMenuDetails.utils";

const nodeTypes = {
    startNode: StartNode,
    menuNode: MenuNode,
    actionNode: ActionNode,
    addNode: AddNode,
    hoursNode: HoursNode,
};

const linkTypes = {
    connector: Connector,
};

export type IvrFlowFormType = {
    menus: EditAutoAttendantsMenuForm[];
};

type IvrFlowDiagramProps = {
    handleSubmitForm?: (formData: Partial<EditAutoAttendantForm>) => void;
    handleDirtyChange?: (funcName: string, isDirty: boolean) => void;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
};

const IvrFlowDiagram: React.VFC<IvrFlowDiagramProps> = ({
                                                            handleDirtyChange,
                                                            handleSetSubmitFunc,
                                                        }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [, setReactFlowInstance] = useState<OnLoadParams<unknown> | null>(
        null,
    );
    const [elements, setElements] = useState<FlowElements>([]);

    const visibleMenus = useRef(new Map<number, boolean>());
    const [menusExpanded, setMenusExpanded] = useState(false);
    const {initValues} = useAutoAttendantMenuFormTab();

    const {
        values,
        setFieldValue,
        dirty,
        handleSubmit,
    } = useFormikContext<IvrFlowFormType>();

    useEffect(() => {
        handleDirtyChange?.('ivrDiagram', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('ivrDiagram', handleSubmit);
    }, [handleSubmit]);

    const [showActionModal, setShowActionModal] = useState(false);
    const [bindedAction, setBindedAction] = useState<| {
        transition?: MenuActionFormType;
        menu: EditAutoAttendantsMenuForm;
    }
        | undefined>(undefined);
    const [showEditMenuModal, setShowEditMenuModal] = useState(false);
    const [menuToEdit, setMenuToEdit] = useState<EditAutoAttendantsMenuForm | undefined>(undefined);

    const autoAttendantMenus = useSelector<ReduxState,
        AutoAttendantMenu[] | undefined>((state) => state.autoAttendants.autoAttendantMenus);

    const serviceFeatures = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension?.serviceFeatures
            || state.autoAttendants?.autoAttendantInfo?.account_info?.service_features
            || state.autoAttendants?.autoAttendantInfo?.serviceFeatures
            || state.extensions?.serviceFeatures
    );

    const callQueues = useSelector(
        (state: ReduxState) => state.callQueues?.callQueues || [],
    );

    const unifiedMessagingVoiceCallEnabled = useMemo(() => serviceFeatures
                ?.find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
                ?.attributes.find((v) => v.name === 'fax_only_mode')?.values[0]
            === "N",
        [serviceFeatures]);

    const onPressTransitionNode = useCallback(
        (action: {
            transition?: MenuActionFormType;
            menu: EditAutoAttendantsMenuForm;
        }) => {
            setBindedAction(action);
            setShowActionModal(true);
        },
        [values.menus],
    );

    const onElementsRemove = (elementsToRemove: FlowElements) =>
        setElements((els: FlowElements) =>
            removeElements(elementsToRemove, els),
        );

    const generateDiagram = useCallback(() => {
        const menuList = values.menus?.length > 0 ? values.menus : initValues.menus;

        const nodes = getLayoutedElements(
            generateFlowBasedOnTransitions(
                menuList,
                visibleMenus.current,
                unifiedMessagingVoiceCallEnabled,
                generateDiagram,
                onPressTransitionNode,
                onPressMenuEdit,
            ),
        );

        setElements(nodes);
    }, [values.menus, initValues.menus, autoAttendantMenus, callQueues]);

    useEffect(() => {
        if (visibleMenus.current.size === 0) {

            visibleMenus.current = new Map<number, boolean>();
            values.menus.forEach((v) =>
                visibleMenus.current.set(v.menuId, false),
            );
        }
        if (values.menus.length) {
            generateDiagram();
        }
    }, [values.menus]);

    const onLoad = (_reactFlowInstance: OnLoadParams<unknown>) =>
        setReactFlowInstance(_reactFlowInstance);

    const onPressMenuEdit = useCallback((menu?: EditAutoAttendantsMenuForm) => {
        setMenuToEdit(menu);
        setShowEditMenuModal(true);
    }, []);

    const onSaveMenu = useCallback(
        (form: EditAutoAttendantsMenuForm) => {

            const newMenus = lodash.cloneDeep(values.menus);

            const menuIndex = newMenus.findIndex(
                (v) => v.menuId === menuToEdit?.menuId,
            );

            const actions = [...(menuToEdit?.actions || [])];

            const actionIndex = actions.findIndex(
                (v) => v.userInput === UserInput.NotActive,
            );

            const inactiveAction = {
                userInput: UserInput.NotActive,
                action: form.inactiveMenuAction,
                playBeforeActionStatus: form.playBeforeAction,
                queue: form.queue,
                menu: form.menu,
                transferCallerToPhoneNumberExtension: form.transferDestination,
                maxDigits: form.maxDigits,
                playBeforeActionFile: form.recordBeforeActionFile,
                transitionId: !!actions[actionIndex]?.transitionId ? actions[actionIndex].transitionId : undefined
            };

            if (actionIndex !== -1) {
                actions[actionIndex] = inactiveAction;
            } else {
                actions.push(inactiveAction);
            }

            newMenus[menuIndex] = {
                ...form,
                actions,
            };

            setFieldValue('menus', newMenus);

            setShowEditMenuModal(false);
            setMenuToEdit(undefined);
        },
        [menuToEdit, values.menus],
    );

    const onSaveTransitionNode = useCallback(
        (form: MenuActionFormType) => {

            const actions = [...(bindedAction?.menu.actions || [])];

            if (bindedAction?.transition) {
                const index = actions.findIndex(
                    (v) => v.userInput === bindedAction.transition?.userInput,
                );
                if (index !== -1) {
                    actions[index] = form;
                }
            } else {
                actions.push(form);
            }

            const newMenus = lodash.cloneDeep(values.menus);
            const menuIndex = newMenus.findIndex(
                (v) => v.menuId === bindedAction?.menu.menuId,
            );

            setTimeout(() => {
                if (menuIndex !== -1) {
                    newMenus[menuIndex].actions = actions;
                    setFieldValue('menus', newMenus);
                }
                setBindedAction(undefined)
            }, 200)
            setShowActionModal(false);
        },
        [bindedAction, values.menus],
    );

    const onRemoveAction = useCallback(() => {
        let actions = [...(bindedAction?.menu.actions || [])];

        if (bindedAction?.transition) {
            actions = actions.filter(
                (v) => v.userInput !== bindedAction.transition?.userInput,
            );

            const newMenus = lodash.cloneDeep(values.menus);
            const menuIndex = newMenus.findIndex(
                (v) => v.menuId === bindedAction?.menu.menuId,
            );

            if (menuIndex !== -1) {
                newMenus[menuIndex].actions = actions;
                setFieldValue('menus', newMenus);
            }
        }

        setBindedAction(undefined);
        setShowActionModal(false);
    }, [bindedAction, values.menus]);

    const toggleModalVisiblity = useCallback(
        () => setShowActionModal(!showActionModal),
        [showActionModal],
    );

    const onExpandMenusClick = useCallback(() => {
        visibleMenus.current.forEach((_, key) => {
            visibleMenus.current.set(key, !menusExpanded);
        });

        generateDiagram();
        setMenusExpanded(!menusExpanded);
    }, [values.menus, initValues.menus, autoAttendantMenus, callQueues, visibleMenus, menusExpanded]);

    return (
        <>
            <div
                className="reactflow-wrapper"
                style={{flex: 1}}
                ref={reactFlowWrapper}
            >
                <ReactFlow
                    elements={elements}
                    onElementsRemove={onElementsRemove}
                    onLoad={onLoad}
                    connectionLineType={ConnectionLineType.Straight}
                    nodeTypes={nodeTypes}
                    edgeTypes={linkTypes}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    draggable={false}
                    selectNodesOnDrag={false}
                    connectionLineComponent={Connector}
                    snapToGrid={true}
                    elementsSelectable={false}
                    onlyRenderVisibleElements={false}
                >
                    <CustomControls/>
                </ReactFlow>

                <div className={classes.expandMenusContainer}>
                    <CustomizedSwitch
                        checked={menusExpanded}
                        onChange={onExpandMenusClick}
                        skipPermission
                    />
                    <span className={classes.expandMenusLabel}>
                        {t('screens:autoAttendants.expandMenus')}
                    </span>
                </div>
            </div>

            <MenuActionDialog
                isOpen={showActionModal}
                toggleVisibility={toggleModalVisiblity}
                initialValue={bindedAction?.transition}
                menuActions={bindedAction?.menu.actions || []}
                onSave={onSaveTransitionNode}
                deleteAction={onRemoveAction}
                isDialExtensionDirectlyBlocked={
                    bindedAction?.menu.allowCallersToDialKnownNumber
                }
            />

            <EditMenuDialog
                isOpen={showEditMenuModal}
                toggleVisibility={() =>
                    setShowEditMenuModal(!showEditMenuModal)
                }
                menu={menuToEdit}
                onSave={onSaveMenu}
                menuSettingsPermission={Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.CallFlow.value}
                menuPromptsPermission={Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.CallFlow.value}
            />
        </>
    );
};

export default IvrFlowDiagram;
