import React, {memo, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Handle, Position} from 'react-flow-renderer';
import {useTranslation} from 'react-i18next';
import {Colors} from '../../../../../styles/Colors';
import {FlowNode} from '../utils/types';
import {ReactComponent as MusicIcon} from '../../../../../assets/music.svg';
import {ReactComponent as MenuBack} from '../../../../../assets/ivr_back.svg';
import classNames from 'classnames';
import TransitionSubNode from './TransitionSubNode';
import {
    UserInput
} from '../../../../../store/types/AutoAttendant';
import {MenuActionFormType} from '../../../AutoAttendantsMenus/ActionsForm.utils';
import {Assignment, ChevronLeft, ChevronRight, Edit, Settings} from '@material-ui/icons';
import CustomizedTooltip from '../../../../Tooltip/Tooltip';
import OverflowTooltip from '../../../../OverflowTooltip/OverflowTooltip';
import i18n from '../../../../../services/i18n';
import NodeActionButton from './NodeActionButton';
import PermissionProvider from '../../../../PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../../../../store/types/Permission';
import { usePermissionContext } from '../../../../../hooks/usePermissions';
import {EditAutoAttendantsMenuForm} from "../../../../../views/AutoAttendants/AutoAttendantsMenuDetails.utils";

const useStyles = makeStyles(() => ({
    mainContainer: {
        display: 'flex',
        height: 52,
    },
    container: {
        height: 50,
        width: 175,
        borderRadius: 44,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: Colors.Secondary11,
        border: `2px solid ${Colors.Secondary12}`,
        pointerEvents: 'auto',
    },
    titleText: {
        fontSize: 12,
        color: Colors.Gray9,
        fontWeight: 500,
        lineHeight: '16px',
    },
    descriptionText: {
        fontSize: 14,
        color: Colors.Black,
        fontWeight: 500,
        lineHeight: '18px',
        maxWidth: 150,
        height: 18,
        flex: 'unset',
    },
    sourceHandler: {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
        right: '0px !important',
        top: 26,
    },
    targetHandler: {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
        left: '0px !important',
    },
    ringtoneContainer: {
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: Colors.Secondary11,
        border: `1px solid ${Colors.Secondary12}`,
        borderRadius: '50%',
        position: 'absolute',
        top: -12,
        right: 147,
        pointerEvents: 'auto',
    },
    clockContainer: {
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.Secondary12}`,
        borderRadius: '50%',
    },
    verticalPath: {
        height: 10,
        width: 2,
        backgroundColor: Colors.Secondary12,
    },
    horizontalPath: {
        width: 40,
        height: 2,
        backgroundColor: Colors.Secondary12,
        marginTop: 25,
    },
    hoursContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    menuExpander: {
        position: 'absolute',
        top: 12,
        right: -12,
        height: 28,
        width: 28,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.White,
        border: `2px solid ${Colors.Secondary12}`,
        borderRadius: 25,
        pointerEvents: 'auto',
        cursor: 'pointer',

        '& .menu-closed-container': {
            backgroundColor: Colors.Secondary,
            height: 24,
            width: 24,
            borderRadius: 24,

            '&:hover': {
                backgroundColor: Colors.Secondary1,
            },
        },
    },
    menuExpanderOpened: {
        backgroundColor: Colors.Secondary12,
        border: `2px solid ${Colors.White}`,

        '&:hover': {
            backgroundColor: Colors.Secondary,
        },
    },
    backMenu: {
        '&:hover': {
            '& svg': {
                '& path:first-of-type': {
                    fill: Colors.Secondary12,
                },
            },
        },
        cursor: 'unset',
    },
    editButton: {
        width: 40,
        height: 40,
        backgroundColor: Colors.White,
        borderRadius: 20,
        boxShadow: `0px 4px 4px ${Colors.Gray8}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'auto',
        cursor: 'pointer',
        margin: '0 5px',
        '& .MuiSvgIcon-root': {
            '&:hover': {
                fill: Colors.Text,
            },
        },
    },
    editContainer: {
        width: 120,
        height: 50,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 28,
        pointerEvents: 'auto',
        bottom: -32,
    },
}));

const isTransitionHasPrompt = (
    menu: EditAutoAttendantsMenuForm,
    transition?: MenuActionFormType,
) => {
    return (
        (transition?.playBeforeActionStatus &&
            transition?.playBeforeActionFileName) ||
        menu.menuPromptStatus ||
        menu.introPromptStatus ||
        menu.onTimeoutPromptStatus ||
        menu.onUnavailableSelectionPromptStatus
    );
};

const transitionPromptsTooltip = (
    menu: EditAutoAttendantsMenuForm,
    transition?: MenuActionFormType,
    parentMenu?: EditAutoAttendantsMenuForm,
) => {
    const menuStyle = {
        color: Colors.Gray3,
        marginBottom: 5,
    };
    let prompts = '';
    if (
        transition?.playBeforeActionStatus &&
        transition?.playBeforeActionFileName
    ) {
        prompts += `${i18n.t('screens:autoAttendants.promptBeforeAction')}\n`;
    }
    if (menu.introPromptStatus) {
        prompts += `${i18n.t('screens:autoAttendants.intro')}\n`;
    }
    if (menu.menuPromptStatus) {
        prompts += `${i18n.t('screens:autoAttendants.menu')}\n`;
    }
    if (menu.onTimeoutPromptStatus) {
        prompts += `${i18n.t('screens:autoAttendants.promptOnTimeout')}\n`;
    }
    if (menu.onUnavailableSelectionPromptStatus) {
        prompts += `${i18n.t(
            'screens:autoAttendants.promptOnUnavailableSelection',
        )}\n`;
    }

    return (
        <div>
            {transition?.userInput === UserInput.NotActive &&
                parentMenu &&
                parentMenu.playBeforeAction &&
                parentMenu.recordBeforeActionName && (
                    <>
                        <span style={menuStyle}>{`${parentMenu.name}\n`}</span>
                        {`${i18n.t(
                            'screens:autoAttendants.promptWhenTheMenuIsInactive',
                        )}\n`}
                    </>
                )}
            <span style={menuStyle}>{`${menu.name}\n`}</span>
            {prompts}
        </div>
    );
};

const MenuNode = (node: FlowNode) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const permission = usePermissionContext();

    const {data} = node;

    const [focused, setFocused] = useState(false);

    const timeoutTime =
        data?.transition?.userInput === UserInput.NoInput &&
        data?.transition?.timeout
            ? parseInt(data?.transition?.timeout, 10)
            : 0;

    return (
        <div
            className={classes.mainContainer}
            onMouseEnter={() => setFocused(true)}
            onMouseLeave={() => setFocused(false)}
            data-testid="menu-node"
            data-qa="menu-node"
        >
            {data?.transition && (
                <>
                    {data.transition.userInput !== UserInput.NotActive ? (
                        <TransitionSubNode transition={data?.transition}/>
                    ) : (
                        <div className={classes.horizontalPath}/>
                    )}

                    <div className={classes.horizontalPath}/>
                </>
            )}

            <div>
                <div className={classes.container}>
                    <Handle
                        className={classes.targetHandler}
                        type="target"
                        position={Position.Left}
                        id="a"
                    />

                    <span className={classes.titleText}>
                        {timeoutTime
                            ? t('screens:autoAttendants.inSecGoToMenu', {
                                sec: timeoutTime,
                            })
                            : t('screens:autoAttendants.menu')}
                    </span>

                    <OverflowTooltip
                        classes={{text: classes.descriptionText}}
                        tooltip={data?.menu?.name}
                        text={data?.menu?.name}
                        copy={false}
                    />

                    <Handle
                        className={classNames(classes.sourceHandler)}
                        type="source"
                        position={Position.Right}
                        id="b"
                    />
                </div>

                {data?.menu &&
                    isTransitionHasPrompt(data?.menu, data?.transition) && (
                        <CustomizedTooltip
                            title={transitionPromptsTooltip(
                                data?.menu,
                                data?.transition,
                                data?.parentMenu,
                            )}
                            copy={false}
                        >
                            <div className={classes.ringtoneContainer}>
                                <MusicIcon
                                    data-qa="ringtone-icon"
                                    data-testid="ringtone-icon"
                                />
                            </div>
                        </CustomizedTooltip>
                    )}

                {data?.menu?.name !== 'ROOT' &&
                    !data?.menuOpened &&
                    !data?.menuExisting && (
                        <CustomizedTooltip
                            title={t('tooltips:autoAttendants.expandMenu')}
                            copy={false}
                        >
                            <div
                                className={classes.menuExpander}
                                onClick={data?.toggleMenu}
                            >
                                <div
                                    className="menu-closed-container"
                                    data-qa="menu-closed-icon"
                                    data-testid="menu-closed-icon"
                                >
                                    <ChevronRight htmlColor={Colors.White}/>
                                </div>
                            </div>
                        </CustomizedTooltip>
                    )}

                {data?.menu?.name !== 'ROOT' &&
                    data?.menuOpened &&
                    !data?.menuExisting && (
                        <CustomizedTooltip
                            title={t('tooltips:autoAttendants.collapseMenu')}
                            copy={false}
                        >
                            <div
                                className={classNames(
                                    classes.menuExpander,
                                    classes.menuExpanderOpened,
                                )}
                                onClick={data?.toggleMenu}
                            >
                                <ChevronLeft
                                    htmlColor={Colors.White}
                                    data-qa="menu-opened-icon"
                                    data-testid="menu-opened-icon"
                                />
                            </div>
                        </CustomizedTooltip>
                    )}

                {(data?.transition?.menu?.name === 'ROOT' ||
                    data?.menuExisting) && (
                    <div
                        className={classNames(
                            classes.menuExpander,
                            classes.backMenu,
                        )}
                    >
                        <MenuBack
                            data-qa="menu-back-icon"
                            data-testid="menu-back-icon"
                        />
                    </div>
                )}
            </div>

            {focused && (
                <div className={classes.editContainer}>
                    {data?.parentMenu &&
                        data?.transition?.userInput !== UserInput.NotActive && (
                            <PermissionProvider
                                permission={
                                    Permission.CloudPBX.AutoAttendants
                                        .AutoAttendantDetails.CallFlow.value
                                }
                            >
                                <NodeActionButton
                                    tooltip={permission === PermissionType.Visible ? 
                                        t('screens:autoAttendants.editAction')
                                        : t('screens:autoAttendants.menuActionDetails')
                                    }
                                    onClick={() =>
                                        data?.transition &&
                                        data?.parentMenu &&
                                        data?.onPressTransitionNode?.({
                                            transition: data.transition,
                                            menu: data.parentMenu,
                                        })
                                    }
                                    dataTestId="edit-action-button"
                                    dataQa="edit-action-button"
                                    skipPermission
                                >
                                    {permission === PermissionType.Visible ? (
                                            <Edit htmlColor={Colors.Gray5}/>
                                        ) : (
                                            <Assignment htmlColor={Colors.Gray5}/>
                                        )
                                    }
                                </NodeActionButton>
                            </PermissionProvider>
                        )}

                    <PermissionProvider
                        permission={
                            Permission.CloudPBX.AutoAttendants
                                .AutoAttendantDetails.CallFlow.value
                        }
                        skipReadOnly
                    >
                        <NodeActionButton
                            tooltip={permission === PermissionType.Visible
                                ? t('tooltips:autoAttendants.changeMenuSettings')
                                : t('tooltips:autoAttendants.viewMenuSettings')
                            }
                            onClick={() => data?.onPressMenuEdit?.(data?.menu)}
                            dataTestId="edit-menu-button"
                            dataQa="edit-menu-button"
                        >
                            <Settings htmlColor={Colors.Gray5}/>
                        </NodeActionButton>
                    </PermissionProvider>
                </div>
            )}
        </div>
    );
};

export default memo(MenuNode);
