import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as actions from '../../store/actions';
import {
    addDevicePhoneBookValidationSchema,
    DevicePhoneBookFormProps,
    getRoute,
    NewDevicePhoneBookDialogProps,
    useStyles,
} from './NewDevicePhoneBookDialog.utils';
import {ReduxState} from '../../store/types';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../store/types/Permission';
import DialogContainer, {DialogButton} from '../../components/AlertDialog/DialogContainer';
import Loader from '../../components/Loader/Loader';
import CustomizedTextField from '../../components/TextField/TextField';
import { YesNo } from '../../store/types/CallScreening';
import Radio from '../../components/Radio/Radio';
import { CloudUpload } from '@material-ui/icons';
import Button from '../../components/Button/Button';
import {useDropzone} from 'react-dropzone';
import { CreateDevicePhoneBookRequest, PhoneDirectory } from '../../store/types/Devices';
import { usePermissions } from '../../hooks/usePermissions';
import history from '../../history';
import { showToast } from '../../utils/showToast';
import { Routes } from '../../routes/routes';

const NewDevicePhoneBookDialog: React.FC<NewDevicePhoneBookDialogProps> = ({
        isOpen,
        clickOnClose,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [uploadStep, setUploadStep] = useState<number>(1);

    const {
        isCreatingPhoneBook, isLoadingPhoneDirectories, createPhoneBookApiError
    } = useSelector(
        (state: ReduxState) => state.devices,
    );

    const i_customer = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.i_customer,
    );

    const isLoading: boolean = useMemo(
        () => isLoadingPhoneDirectories || isCreatingPhoneBook || false,
        [isLoadingPhoneDirectories, isCreatingPhoneBook],
    );

    const viewDetails = usePermissions(...Permission.Inventory.DeviceProfiles.value);
    
    const initialValues: DevicePhoneBookFormProps = useMemo(
        () => ({
            name: '',
            mode: 'manual',
        } as DevicePhoneBookFormProps),
        [],
    );

    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        setFieldError,
        handleChange,
        dirty
    } = useFormik<DevicePhoneBookFormProps>({
        initialValues,
        onSubmit: (form) => {
            const model: CreateDevicePhoneBookRequest = {
                phone_directory_info: {
                    name: form.name,
                    auto_sync: form.mode === 'auto' ? YesNo.Yes : YesNo.No,
                } as PhoneDirectory,
                callback: (createdPhoneBookId) => {
                    clickOnClose?.();
                    if (viewDetails !== PermissionType.Hidden) {
                        history.push(
                            getRoute(Routes.DevicePhoneBookDetails, {
                                id: createdPhoneBookId,
                            }),
                        );
                    }
                    showToast(t<string>('screens:devicePhoneBooks.devicePhoneBookAdded'));
                }
            };
            dispatch(actions.addDevicePhoneBook.request(model));
        },
        validationSchema: addDevicePhoneBookValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });

    useEffect(() => {
        if (isOpen) {
            resetForm();
            if(i_customer)
            {
                const _pageSize = 100;
                const _page = 1;
                dispatch(
                    actions.getDevicePhoneBookList.request({
                        get_total: 1,
                        get_total_records: YesNo.Yes,
                        i_customer: i_customer,
                        limit: _pageSize,
                        offset: (_page - 1) * _pageSize,
                    })
                );
            }
        }
    }, [isOpen, i_customer]);

    useEffect(() => {
        if (!isCreatingPhoneBook && createPhoneBookApiError) {
            switch (createPhoneBookApiError.faultcode) {
                case 'internal_server_error':
                    setFieldError('name', t('errors:devicePhoneBooks.devicePhoneBookNameIsNotUnique'));
                    break;
            }
        }
    }, [isCreatingPhoneBook, createPhoneBookApiError]);

    const isDisabled = (): boolean => {
        return (
            !dirty ||
            !(values.name?.length) ||
            isLoading)
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : undefined;
        if (file) {
            /*setValues(prevValues => {
                return ({
                    ...prevValues,
                    file: file,
                    file_format: ListFileFormat.CSV,
                    name: file.name,
                    fileSizeReadable: getReadableSize(file.size),
                    callback: () => {
                        setUploadStep(4);
                    },
                    errorCallback: (error: APIErrorInterface) => {
                        //@ts-ignore
                        setUuidString(error.faultdetails?.uuid);
                        setApiErrorString(getUploadFileErrorMsg(error.faultstring));
                        if (error.faultcode === 'Server.UA.upload_ua_list.partially_succeeded') {
                            setUploadStep(5);
                        } else {
                            setUploadStep(6);
                        }
                    }
                });
            });*/
            setUploadStep(2);
        }
    }, []);
    
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: {'text/csv': ['.csv']},
        disabled: values.mode !== 'csv'
    });

    return (
        <PermissionProvider permission={Permission.Inventory.DeviceProfiles.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'device-phone-book-add-new-modal'}
                header={t('screens:devicePhoneBooks.addNewDevicePhoneBook')}
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            isDisabled()
                        }
                        onClick={handleSubmit}
                        dataQa={'add-new-device-phone-book-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-device-phone-book-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="name"
                                label={t('screens:devicePhoneBooks.name')}
                                value={values.name}
                                helperText={
                                    errors.name && errors.name.length > 0
                                        ? errors.name
                                        : ''
                                }
                                setFieldError={setFieldError}
                                onChange={handleChange}
                                required
                                maxLength={64}
                                dataQa="device-phone-book-name-field"
                            />
                        </Box>
                        
                        <Radio
                            label={t('screens:devicePhoneBooks.manualEntries')}
                            dataQa={'manual-radio'}
                            checked={values.mode === 'manual'}
                            onChange={() => {
                                setFieldValue('mode', 'manual');
                            }}
                        />

                        <Radio
                            label={t('screens:devicePhoneBooks.uploadCsvFile')}
                            dataQa={'csv-radio'}
                            checked={values.mode === 'csv'}
                            onChange={() => {
                                setFieldValue('mode', 'csv');
                            }}
                        />

                        <div className={classes.fileDragAndDropBox} {...getRootProps()} data-testid="file-drop-zone">
                            <div className={classes.uploadBox}>
                                <div>
                                    <CloudUpload className={classes.uploadIcon}/>
                                </div>
                                <div className={classes.textWrapper}>
                                    <div className={classes.importTop}>
                                        {uploadStep === 1
                                            ? t('screens:devicePhoneBooks.importDevicesSelectFile')
                                            : values.name
                                        }
                                    </div>
                                    <div className={classes.importBottom}>
                                        {uploadStep === 1
                                            ? t('screens:devicePhoneBooks.importDevicesSelectFileSubTitle')
                                            : values.fileSizeReadable
                                        }
                                    </div>
                                </div>
                                <div className={classes.lastColumn}>
                                    <Button
                                        dataQa="browse-button"
                                        primary
                                        accent
                                        className={classes.browseButton}
                                        disabled={values.mode !== 'csv'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        {t('screens:devicePhoneBooks.browse')}
                                    </Button>
                                </div>
                            </div>
                            <input {...getInputProps()} data-testid='file-upload-input'/>
                        </div>
                        
                        <Radio
                            label={t('screens:devicePhoneBooks.automaticExtList')}
                            dataQa={'csv-radio'}
                            checked={values.mode === 'auto'}
                            onChange={() => {
                                setFieldValue('mode', 'auto');
                            }}
                        />
                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition/>
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewDevicePhoneBookDialog;
