import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import CustomizedButton from '../../components/Button/Button';

interface DeviceListActionsPanelProps {
    numberOfItems: number;
    onClickAssign?: () => void;
    customClasses?: { buttonsContainer: string };
}

const useStyles = makeStyles(() => ({
    container: {
        flex: 1,
        padding: '19px 40px 19px 40px',
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.Border}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 8,
        boxShadow: `0px 4px 4px ${Colors.Gray8}`,
    },

    assignButton: {
        paddingLeft: 16,
        paddingRight: 16,
    },

    buttonsContainer: {
        display: 'flex',
        padding: '10px 0',
        position: 'absolute',
        bottom: 0,
        width: 'calc(100% - 194px)',
        maxWidth: 1042,
        margin: 0,
        left: 94,
    },
}));

const DeviceListActionsPanel: React.VFC<DeviceListActionsPanelProps> = ({
    numberOfItems,
    onClickAssign,
    customClasses,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!numberOfItems) {
        return null;
    }

    return (
        <div
            className={classNames(
                classes.buttonsContainer,
                customClasses?.buttonsContainer,
            )}
        >
            <Grid className={classes.container}>
                <div></div>
                <CustomizedButton
                    dataQa="assign-template-button"
                    onClick={onClickAssign}
                    className={classes.assignButton}
                    primary
                >
                    {t('screens:devices.assignProfile')}
                </CustomizedButton>
            </Grid>
        </div>
    );
};

export default DeviceListActionsPanel;
