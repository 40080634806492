import * as actions from '../../actions';
import { ActionType, createReducer } from 'typesafe-actions';
import { GetUAProfileMetaInfoResponse, OptionsTemplateInfo, PhoneDirectory, UAInfo, UATypeInfo, UpdateOptionsTemplateState } from '../../types/Devices';
import { ExtensionsListItem } from '../extensions/extensions/reducer';
import { APIErrorInterface } from '../../types/errors';

export interface DevicesStateType {
    items?: UAInfo[];
    total?: number;
    allTotal?: number;
    active?: number;
    isLoading?: boolean;
    isReleasingDevice?: boolean;
    extension?: ExtensionsListItem;
    isExtensionLoading?: boolean;
    isEditing?: boolean;
    isTypeListLoading?: boolean;
    isCreating?: boolean;
    ua_type_list?: UATypeInfo[];
    createdUAId?: number;
    createApiError?: APIErrorInterface;
    isUpdating?: boolean;
    updateApiError?: APIErrorInterface;
    isLoadingTemplates?: boolean;
    templates?: OptionsTemplateInfo[];
    updateOptionsTemplateState?: UpdateOptionsTemplateState;
    totalProfiles?: number;
    isCreatingProfile?: boolean;
    createdProfileId?: number;
    createProfileApiError?: APIErrorInterface;
    profileDetails?: OptionsTemplateInfo;
    isLoadingProfileDetails?: boolean;
    uaProfileMetaInfo?: GetUAProfileMetaInfoResponse;
    isLoadingMetadata?: boolean;
    isUpdatingOptionsTemplate?: boolean;
    phoneDirectories: PhoneDirectory[];
    totalPhoneDirectories?: number;
    isLoadingPhoneDirectories?: boolean;
    createdPhoneDirectoryId?: number;
    isCreatingPhoneBook?: boolean;
    createPhoneBookApiError?: APIErrorInterface;
    createdPhoneBookId?: number;
}

export type DevicesActionsType = ActionType<typeof actions>;

export const initialState: DevicesStateType = {};

const devicesReducer = createReducer<DevicesStateType, DevicesActionsType>(
    initialState,
)
    .handleAction(actions.devicesList.request, (state) => ({
        ...state,
        isLoading: true,
        isReleasingDevice: undefined,
        createdUAId: undefined,
        updateApiError: undefined,
    }))
    .handleAction(actions.devicesList.success, (state, action) => ({
        ...state,
        isLoading: false,
        ...action.payload,
        allTotal: action.payload.total !== undefined ?  action.payload.total : state.allTotal
    }))
    .handleAction(actions.devicesList.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.releaseDevice.request, (state) => ({
        ...state,
        isReleasingDevice: true,
    }))
    .handleAction(actions.releaseDevice.success, (state) => ({
        ...state,
        isReleasingDevice: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.releaseDevice.failure, (state) => ({
        ...state,
        isReleasingDevice: false,
    }))
    .handleAction(actions.getExtensionById.request, (state) => ({
        ...state,
        isExtensionLoading: true,
        extension: undefined,
    }))
    .handleAction(actions.getExtensionById.success, (state, action) => ({
        ...state,
        isExtensionLoading: false,
        extension: action.payload.extension,
    }))
    .handleAction(actions.getExtensionById.failure, (state) => ({
        ...state,
        isExtensionLoading: false,
        extension: undefined,
    }))
    .handleAction(actions.assignDevice.request, (state) => ({
        ...state,
        isEditing: true,
    }))
    .handleAction(actions.assignDevice.success, (state) => ({
        ...state,
        isEditing: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.assignDevice.failure, (state) => ({
        ...state,
        isEditing: false,
    }))
    .handleAction(actions.assignUA.request, (state) => ({
        ...state,
        isEditing: true,
    }))
    .handleAction(actions.assignUA.success, (state) => ({
        ...state,
        isEditing: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.assignUA.failure, (state) => ({
        ...state,
        isEditing: false,
    }))
    .handleAction(actions.deviceTypeList.request, (state) => ({
        ...state,
        isTypeListLoading: true,
        isCreating: false,
        createdUAId: undefined,
        createApiError: undefined,
        updateApiError: undefined,
    }))
    .handleAction(actions.deviceTypeList.success, (state, action) => ({
        ...state,
        isTypeListLoading: false,
        ua_type_list: action.payload.ua_type_list,
    }))
    .handleAction(actions.deviceTypeList.failure, (state) => ({
        ...state,
        isTypeListLoading: false,
    }))
    .handleAction(actions.addDevice.request, (state) => ({
        ...state,
        items: undefined,
        isCreating: true,
        createApiError: undefined,
    }))
    .handleAction(actions.addDevice.success, (state, action) => ({
        ...state,
        isCreating: false,
        createdUAId: action.payload.i_ua,
        createApiError: undefined,
    }))
    .handleAction(actions.addDevice.failure, (state, action) => ({
        ...state,
        isCreating: false,
        createApiError: action.payload,
    }))
    .handleAction(actions.uploadDeviceListCsvFile.request, (state) => ({
        ...state
    }))
    .handleAction(actions.uploadDeviceListCsvFile.success, (state) => ({
        ...state
    }))
    .handleAction(actions.uploadDeviceListCsvFile.failure, (state) => ({
        ...state
    }))
    .handleAction(actions.getUploadUAApiErrorFile.request, (state) => ({
        ...state
    }))
    .handleAction(actions.getUploadUAApiErrorFile.success, (state) => ({
        ...state
    }))
    .handleAction(actions.getUploadUAApiErrorFile.failure, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteUA.request, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteUA.success, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteUA.failure, (state) => ({
        ...state
    }))
    .handleAction(actions.updateUa.request, (state) => ({
        ...state,
        isUpdating: true,
        updateApiError: undefined,
    }))
    .handleAction(actions.updateUa.success, (state) => ({
        ...state,
        isUpdating: false,
        updateApiError: undefined,
    }))
    .handleAction(actions.updateUa.failure, (state, action) => ({
        ...state,
        isUpdating: false,
        updateApiError: action.payload,
    }))
    .handleAction(actions.getOptionsTemplateList.request, (state) => ({
        ...state,
        isLoadingTemplates: true,
        templates: undefined,
        totalProfiles: undefined,
        profileDetails: undefined,
    }))
    .handleAction(actions.getOptionsTemplateList.success, (state, action) => ({
        ...state,
        isLoadingTemplates: false,
        templates: action.payload.template_list ?? [],
        totalProfiles: action.payload.total ?? 0,
        profileDetails: undefined,
    }))
    .handleAction(actions.getOptionsTemplateList.failure, (state) => ({
        ...state,
        isLoadingTemplates: false,
        templates: undefined,
        totalProfiles: undefined,
        profileDetails: undefined,
    }))
    .handleAction(actions.updateOptionsTemplate.request, (state, action) => ({
        ...state,
        updateOptionsTemplateState: {
            total: action.payload?.length ?? 0,
            done: 0,
            profileName: action.payload?.[0]?.modelName
        },
        isUpdatingOptionsTemplate: true,
    }))
    .handleAction(actions.updateOptionsTemplate.success, (state, action) => ({
        ...state,
        updateOptionsTemplateState: action.payload,
        isUpdatingOptionsTemplate: false,
    }))
    .handleAction(actions.updateOptionsTemplate.failure, (state) => ({
        ...state,
        updateOptionsTemplateState: {
            total: -1,
            done: -1
        },
        isUpdatingOptionsTemplate: false,
    }))
    .handleAction(actions.addDeviceProfile.request, (state, action) => ({
        ...state,
        templates: undefined,
        isCreatingProfile: action.payload.isCloning ?? true,
        createProfileApiError: undefined,
    }))
    .handleAction(actions.addDeviceProfile.success, (state, action) => ({
        ...state,
        isCreatingProfile: false,
        createdProfileId: action.payload.i_ua_config_template,
        createProfileApiError: undefined,
    }))
    .handleAction(actions.addDeviceProfile.failure, (state, action) => ({
        ...state,
        isCreatingProfile: false,
        createProfileApiError: action.payload,
    }))
    .handleAction(actions.deleteProfile.request, (state) => ({
        ...state,
    }))
    .handleAction(actions.deleteProfile.success, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteProfile.failure, (state) => ({
        ...state
    }))
    .handleAction(actions.getProfileTemplate.request, (state) => ({
        ...state,
        profileDetails: undefined,
        isLoadingProfileDetails: true,
    }))
    .handleAction(actions.getProfileTemplate.success, (state, action) => ({
        ...state,
        profileDetails: action.payload,
        isLoadingProfileDetails: false,
    }))
    .handleAction(actions.getProfileTemplate.failure, (state) => ({
        ...state,
        profileDetails: undefined,
        isLoadingProfileDetails: false,
    }))
    .handleAction(actions.getUaProfileMetaInfo.request, (state) => ({
        ...state,
        isLoadingMetadata: true,
        uaProfileMetaInfo: undefined
    }))
    .handleAction(actions.getUaProfileMetaInfo.success, (state, action) => ({
        ...state,
        isLoadingMetadata: undefined,
        uaProfileMetaInfo: action.payload
    }))
    .handleAction(actions.getUaProfileMetaInfo.failure, (state) => ({
        ...state,
        isLoadingMetadata: false,
        uaProfileMetaInfo: undefined
    }))
    .handleAction(actions.getDevicePhoneBookList.request, (state) => ({
        ...state,
        isLoadingPhoneDirectories: true,
        phoneDirectories: undefined,
        totalPhoneDirectories: undefined,
    }))
    .handleAction(actions.getDevicePhoneBookList.success, (state, action) => ({
        ...state,
        isLoadingPhoneDirectories: false,
        phoneDirectories: action.payload.phone_directory_list ?? [],
        totalPhoneDirectories: action.payload.total ?? 0,
    }))
    .handleAction(actions.getDevicePhoneBookList.failure, (state) => ({
        ...state,
        isLoadingPhoneDirectories: false,
        phoneDirectories: undefined,
        totalPhoneDirectories: undefined,
    }))
    .handleAction(actions.addDevicePhoneBook.request, (state, action) => ({
        ...state,
        phoneDirectories: undefined,
        isCreatingPhoneBook: action.payload.isCloning ?? true,
        createPhoneBookApiError: undefined,
    }))
    .handleAction(actions.addDevicePhoneBook.success, (state, action) => ({
        ...state,
        isCreatingPhoneBook: false,
        createdPhoneBookId: action.payload.i_ua_config_template,
        createPhoneBookApiError: undefined,
    }))
    .handleAction(actions.addDevicePhoneBook.failure, (state, action) => ({
        ...state,
        isCreatingPhoneBook: false,
        createPhoneBookApiError: action.payload,
    }))
    .handleAction(actions.deleteDevicePhoneBook.request, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteDevicePhoneBook.success, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteDevicePhoneBook.failure, (state) => ({
        ...state
    }));

export default devicesReducer;
