import {
    SettingsFormType,
    settingsFormValidationSchema,
} from '../../components/Forms/AutoAttendantsMenus/SettingsForm.utils';
import * as Yup from 'yup';
import {
    IntervalFormProps,
    intervalFormValidationSchema,
} from '../../components/IntervalSelect/IntervalSelect.utils';
import {
    PromptsFormType,
    promptsFormValidationSchema,
} from '../../components/Forms/AutoAttendantsMenus/PromptForm.utils';
import {
    ActionsFormType,
    actionsFormValidationSchema,
} from '../../components/Forms/AutoAttendantsMenus/ActionsForm.utils';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { compareObjectsAndReturnDifferencesInValues } from '../../utils/compareObjects';

export const useStyles = makeStyles(() => ({
    removeModalContainer: {
        '& .MuiDialogContent-root': {
            minWidth: 280,
            width: 280,
            backgroundColor: Colors.White,
        },
    },
    root: {
        flex: 1,
        height: '100vh',
        width: '100%',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        background: Colors.SmokeBackground,
        paddingBottom: 90,
    },
    extensionHeader: {
        padding: '40px 90px 24px 90px',
        backgroundColor: Colors.White,
        display: 'flex',
        alignItems: 'center',
    },
    extensionTop: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        padding: '30px 90px 30px 90px',
        borderTop: `1px rgba(0, 0, 0, 0.12) solid`,
        backgroundColor: Colors.White,
        margin: 0,
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    extensionStatus: {
        display: 'flex',
        fontSize: 22,
        weight: 400,

        '& > div': {
            marginRight: 40,
        },
        '& > div:last-child': {
            marginRight: 0,
        },
    },
    header: {
        '& .header-subtitle': {
            fontWeight: 500,
            fontSize: 32,
            color: Colors.Text,
            opacity: 1,
        },
    },
}));
export type EditAutoAttendantsMenuForm = SettingsFormType & {
    intervals: IntervalFormProps;
} & ActionsFormType &
    PromptsFormType;
export const formValidationSchema = settingsFormValidationSchema.concat(
    Yup.object()
        .shape({
            intervals: intervalFormValidationSchema,
        })
        .concat(promptsFormValidationSchema)
        .concat(actionsFormValidationSchema),
);
export type AutoAttendantsMenuDetailsProps = {
    autoAttendantId: string;
    menuId: string;
};

function isEmptyObject(value: any): boolean {
    return Object.keys(value).length === 0 && value.constructor === Object;
}

export const isDirty = (
    dirty: boolean,
    newValues: EditAutoAttendantsMenuForm,
    oldValues: EditAutoAttendantsMenuForm,
) => {
    const dataToSave = compareObjectsAndReturnDifferencesInValues(
        oldValues,
        newValues,
    );

    if (isEmptyObject(dataToSave)) {
        return false;
    }

    return dirty;
};