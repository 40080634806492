import { YesNo } from './CallScreening';
import { APIErrorInterface } from './errors';

export enum ListFileFormat {
    CSV = 'csv',
    XLS = 'xls',
    XLSX = 'xlsx',
}

export interface UAInfo {
    name: string;
    used: string;
    ports_config: UAPortConfiguration[];
    mac: string;
    inventory_id: string;
    i_ua: number;
    type: string;
    busy_ports?: number[];
    available_ports?: number[];
    description?: string;
    profile?: string;
    i_tenant_customer?: number;
    tenant_customer_name?: string;
    is_multiservice?: number;
    shared?: string;
    ascii_key?: string;
    ua_profile_id?: number;
    i_customer?: number | null;
    i_ua_type?: number;
    ported?: 'Y' | 'N';
}

export interface UAInfoList extends UAInfo {
    id: number;
}

export interface UAPortConfiguration {
    account_id?: string;
    allowed_services?: UAPortApplicableServiceInfo[];
    i_account?: number;
    i_service_type?: number;
    physical_interface_number?: number;
    port: number;
    release_allowed: YesNo;
    extension_id?: string;
    i_did_number?: string;
    did_number?: string;
    extension_name?: string;
}

export interface UAPortApplicableServiceInfo {
    i_service_type: number;
}

export interface GetUAListResponse {
    total: number;
    ua_list: UAInfo[];
}

export enum UAUsage {
    Used = 'U',
    CompletelyUsedAllPorts = 'C',
    NotUsed = 'F',
    Default = 'A',
}

export interface GetUAListRequest {
    extension_id?: string;
    name?: string;
    mac?: string;
    inventory_id?: string;
    usage?: UAUsage | string;
    get_total?: number;
    offset?: number;
    limit?: number;
    with_available_ports?: number;
    with_busy_ports?: number;
    i_ua?: number;
    file_format?: ListFileFormat;
    callback?:()=>void
}

export interface AssignedPortInfo {
    device_name: string;
    i_account: number;
    i_ua: number;
    i_ua_link: number;
    i_ua_type: number;
    id: string;
    is_multiservice: number;
    mac: string;
    port: number;
    ua_info?: UAInfo;
}

export interface UAListPayload {
    items: UAInfo[];
    total: number;
    active?: number;
}

export interface DeviceFilters {
    extension_id?: string;
    name?: string;
    mac?: string;
    inventory_id?: string;
    usage?: UAUsage | string;
}

export interface ReleaseDevicePayload {
    i_c_ext: number;
    i_cpe?: string | null;
}

export interface ReleaseDeviceResponse {
    i_c_ext: number;
}

export interface GetAssignedCpePortsListRequest {
    get_total?: number;
    i_account: number;
    limit?: number;
    offset?: number;
    withDeviceDetails?: boolean;
}

export interface GetAssignedCpePortsListResponse {
    assigned_cpe_ports_list: AssignedPortInfo[];
    total?: number;
}

export interface GetUATypeListRequest {
    get_total?: number;
    i_customer?: number;
    i_tenant_customer?: number;
    only_available?: number;
    with_hidden?: number;
    with_shared?: number;
    with_untenanted?: number;
}

export interface UATypeInfo {
    hidden?: number;
    i_ua_type?: number;
    manufacturer?: string;
    name?: string;
    ports?: number;
    product?: string;
    version?: string;
}

export interface UATypeListPayload {
    ua_type_list: UATypeInfo[];
    total?: number;
}

export interface AddUAPayload {
    i_ua: number;
}

export interface UploadFileListRequest {
    file_format?: ListFileFormat;
    file: File | null;
    name?: string;
    fileSizeReadable?: string;
    callback?: () => void;
    errorCallback?: (error: APIErrorInterface) => void;
}

export interface GetUploadFileApiErrorRequest {
    uuid: string;
    fileName: string;
    errorCallback?: (error: APIErrorInterface) => void;
}

export interface DeleteUARequest {
    i_ua: number;
    callback?: () => void;
}

export interface UpdateUaRequest {
    object: UAInfo;
    callback?: () => void;
    callbackOnError?: () => void;
}

export enum DevicesFaultCodes {
    EditDeviceMacDuplicate = 'Server.UA.mac_duplicate',
    EditDeviceGeneralError = 'Server.UA.internal_error'
}

export interface AssignUaRequest {
    i_account: number;
    i_ua: number;
    port: number;
    callback?: () => void;
    onSuccess?:() => void
}

export interface GetOptionsTemplateListRequest {
    //Indicates whether to return the total number of the records found. Possible values: 1 - yes, 0 - no.
    get_total: number;
    //The unique ID of the customer.<br>Note: for customer realm this field is automatically populated.
    i_customer: number;
    //The number of rows to retrieve.<br>Mandatory for an API request with the offset property specified.
    limit: number;
    //The number of rows to skip at the beginning of the list.<br>Requires the limit property to be specified in the API request.
    offset: number;
    //Filters by device model and profile name
    i_ua_type?: number;
    name?: string;
}

export interface TemplateKeysOptionsInfo {
    //The action to be done when the button is pressed.
    action?: string;
    //Specifies if the button configuration can be overriden. Possible values: 'Y', 'N'.<br>Default value: 'N'.
    allow_override?: YesNo;
    //The unique ID of the button in the scope of a device.
    id: number;
    //The label of the button to display.
    label?: string | null;
    //The type of the button. Possible values: line_key, soft_key, expansion_key.<br>Default value: 'line_key'.
    type: 'line_key' | 'soft_key' | 'expansion_key';
    //The value of the button option. Note: may be required for some
    //  specific actions. For example, for speed dial it can be the number
    //  to call.
    value?: string | null;
}

export interface OptionsTemplateInfo {
    //The unique ID of the template owner.
    i_customer?: number;
    //The unique ID of the template. Use this field as a primary key for Templates.
    //Note: this field is not used for new template creation.
    i_ua_config_template?: number;
    //The list of IDs of devices the template is linked to.
    i_ua_list?: number[];
    //The device type ID.
    i_ua_type?: number;
    keys_options?: TemplateKeysOptionsInfo[];
    //The unique ID of the reseller this template is managed by.
    managed_by?: number;
    //The name of the template.
    name: string;
    //The unique ID of the CPE profile.
    ua_profile_id?: number;
}

export interface OptionsTemplateListPayload {
    template_list: OptionsTemplateInfo[];
    total?: number;
}

export interface UpdateOptionsTemplateRequest {
    i_ua_list?: number[];
    template_info: OptionsTemplateInfo;
    modelName?: string;
    callback?: () => void;
}

export interface UpdateOptionsTemplateState {
    total: number;
    done: number;
    profileName?: string;
}

export interface DeleteProfileRequest {
    i_ua_config_template: number;
    callback?: () => void;
}

export interface AddOptionsTemplateRequest {
    template_info: OptionsTemplateInfo;
    callback?: (newTemplateId: number) => void;
    isCloning?: boolean;
}

export interface AddOptionsTemplateResponse {
    i_ua_config_template: number;
}

export interface GetOptionsTemplateRequest {
    i_customer?: number;
    i_ua?: number;
    i_ua_config_template?: number;
    i_ua_profile?: number;
}

export interface GetUAProfileMetaInfoRequest {
    //Specifies whether related tabs should be grouped.<br>Note: if set to
    //  1 the response info will contain the "subtabs" property.<br>Possible
    //  values: 1, 0.<br>Default value: 0.
    description?: number;
    //The unique ID of the UA profile.
    i_ua_profile?: number;
    //The CPE type identifier.
    i_ua_type?: number;
}

export interface UAProfileKeysInfo {
    actions: UAProfileKeyAction[];
    keys: UAProfileKeyInfo[];
    pager: number;
    total_keys: number;
}

export interface UAProfileKeyAction {
    type: string;
    action_attributes: UAProfileKeyActionConfiguration[];
}

export interface UAProfileKeyActionConfiguration {
    attribute: 'value' | 'label' | 'line';
    mandatory?: 1 | 0 ;
}

export interface UAProfileKeyPositionInfo {
    x: number;
    y: number;
}

export interface UAProfileKeyInfo {
    position: UAProfileKeyPositionInfo;
}

export interface UAProfileKeysLayoutInfo {
    expansion_keys?: UAProfileKeysInfo;
    image: string;
    line_keys: UAProfileKeysInfo;
    soft_keys: UAProfileKeysInfo;
}

export interface UAProfileRemoteDirectoriesInfo {
    total_supported: number;
}

export interface UAProfileParamOption {
    name: string;
    value: string;
}

export interface UAParamValidationRule {
    length: string;
    mandatory: number;
    max: string;
    min: string;
    pattern: string;
    type: string;
}

export interface UAProfileMetaInfoParam {
    name: string;
    options: UAProfileParamOption[];
    title: string;
    type: string;
    validation: UAParamValidationRule[];
    value: string;

}

export interface UAProfileMetainfo {
    //The name of the tab.
    //    Note: it is unique per device model.
    name: string;
    //The list of the device parameters placed on the tab.
    params: UAProfileMetaInfoParam[];
    //The list of dependent sub-tabs with options.
    subtabs: UAProfileMetainfo[];
    //Tab name visible on the web interface.
    //  Note: should be the same
    //  as the tab name on the original web interface of the device.
    title: string;
}

export interface ArrayOfUAProfileMetainfo {
    items: UAProfileMetainfo[];
}

export interface GetUAProfileMetaInfoResponse {
    keys_layout: UAProfileKeysLayoutInfo;
    remote_directories: UAProfileRemoteDirectoriesInfo;
    ua_profile_metainfo: ArrayOfUAProfileMetainfo[];
}

export interface GetPhoneBookListRequest {
    //Indicates whether to return the total number of the records found.
    //Possible values: 1 - yes, 0 - no.
    //Default value: 0.
    get_total?: 1 | 0;

    //Indicates whether to return the total number of phone number records included in the phone directory.
    //Possible values: 'Y', 'N'.
    //Default value: 'N'.
    get_total_records?: YesNo;

    //The unique ID of the customer who manages the phone directory.
    i_customer?: number;

    //The number of rows to retrieve.
    //Mandatory for an API request with the offset property specified.
    limit?: number;

    //The pattern to match phone directories by the name.
    //Note: use the following wildcard symbols: the percent '%' wildcard matches any string of characters; the underscore '_' wildcard matches any single character.
    name?: string;

    //The number of rows to skip at the beginning of the list.
    //Requires the limit property to be specified in the API request.
    offset?: number;
}

export interface GetPhoneBoolListResponse {
    phone_directory_list: PhoneDirectory[];
    total: number;
}

export interface PhoneDirectory {
    auto_sync: string;
    //Indicates whether the phone directory is managed automatically by the system.
    //Note: Directories with 'auto_sync' set to 'Y' are always created in the 'active' state and are synchronized with all extensions of the customer.
    //Possible values:
    //'Y' - the phone directory is automatically synchronized and populated by the system using the extension data;
    //'N' - the phone directory is managed manually by the user and is not automatically synchronized by the system.
    //Default value: 'N'.
    
    //The list of phone directory records.
    directory_records: PhoneDirectoryRecord[];

    //The unique ID of the customer who manages the phone directory.
    i_customer: number;
    
    //The unique ID of the phone directory.
    i_ua_config_directory: number;
    
    //The date of the last directory records update in an active state.
    //Format: 'YYYY-MM-DD HH24:MI:SS' in the UTC time zone.
    //Note: this field is updated when the directory status is set to 'active' or when the directory records are amended.
    last_active_update: string;

    //The name of the phone directory.
    name: string;
    
    state: 'active' | 'draft';
    //The state of the phone directory.
    //Possible values:
    //'active' - the phone directory is ready for provisioning;
    //'draft' - the phone directory is a draft and cannot be provisioned.
    //Default behavior:
    //- If 'auto_sync' is set to 'Y', the phone directory is automatically created in the 'active' state and is managed by the system.
    //- For all other cases, the default value is 'draft'.
    
    //The total number of records included in the phone directory.
    total_records: number;
}

export interface PhoneDirectoryRecord {
    first_name: string;
    //The first name of the contact stored in the phone directory record.

    i_ua_config_directory_item: number;
    //The unique ID of the phone directory record.

    last_name: string;
    //The last name of the contact stored in the phone directory record.

    mobile_number: string;
    //The cell phone number of the contact stored in the phone directory record.

    office_number: string;
    //The office phone number of the contact stored in the phone directory record.

    other_number: string;
    //The additional phone number of the contact stored in the phone directory record.
}

export interface CreateDevicePhoneBookRequest {
    phone_directory_info: PhoneDirectory;
    callback?: (newPhoneBookId: number) => void;
}

export interface CreateDevicePhoneBookResponse {
    i_ua_config_directory: number;
}

export interface DeleteDevicePhoneBookRequest {
    i_ua_config_directory: number;
    callback?: () => void;
}