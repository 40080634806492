import React, {useEffect, useRef} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Redirect, Router, Switch} from 'react-router';

import history from './history';
import './services/i18n';
import {actions, initReduxStore, store} from './store';
import BaseRoute from './routes/BaseRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import SignIn from './views/SignIn/SignIn';
import {ReduxState} from './store/types';
import {Dashboard} from './views/Dashboard/Dashboard';
import {ExtensionsList} from './views/Extensions/List/ExtensionsList';
import {RingGroupsList} from './views/RingGroups/RingGroupsList';
import Toaster from './components/Toaster/Toaster';
import AddRingGroup from './views/RingGroups/AddRingGroup';
import RingGroupDetails from './views/RingGroups/RingGroupDetails';
import DidNumbersList from './views/DidNumbers/DidNumbersList';
import {CallHistoryComponent} from './views/CallHistory/CallHistory';
import {SidebarProvider} from './hooks/useSidebarContext';
import MyCompanyDetails from './views/MyCompany/MyCompanyDetails';
import {PhoneBookList} from './views/PhoneBook/PhoneBookList';
import {CallRecordingsList} from './views/CallRecordings/CallRecordingsList';
import DevicesList from './views/Devices/DevicesList';
import DeviceProfilesList from './views/DeviceProfiles/DeviceProfilesList';
import DeviceDetails from './views/Devices/DeviceDetails';
import AutoAttendantDetails from './views/AutoAttendants/Details/AutoAttendantsDetails';
import PortalUsersDetails from './views/PortalUsers/PortalUsersDetails';
import FaxMailboxDetails from './views/FaxMailboxes/Details/FaxMailboxDetails';
import AutoAttendantsList from './views/AutoAttendants/List/AutoAttendantsList';
import AutoAttendantsMenuDetails from './views/AutoAttendants/AutoAttendantsMenuDetails';
import FaxMailboxesList from './views/FaxMailboxes/List/FaxMailboxesList';
import {PublicRoutes, Routes} from './routes/routes';
import CallSettingsDetails from './views/CallSettings/CallSettingsDetails';
import Config from './config.json';
import EditPortalUser from './views/PortalUsers/EditPortalUser';
import ExtensionDetails from './views/Extensions/ExtensionDetails/ExtensionDetails';
import PasswordRecovery from './views/PasswordRecovery/PasswordRecovery';
import ResetPassword from './views/ResetPassword/ResetPassword';
import PasswordExpiration from './views/PasswordExpiration/PasswordExpiration';
import NotFound from './views/NotFound/NotFound';
import BillingDetails from './views/Billing/BillingDetails';
import TagManager from 'react-gtm-module';
import * as crypto from 'crypto';
import Zendesk from './components/Zendesk/Zendesk';
import {useDefaultScreenBasedOnPermissions} from './hooks/usePermissions';
import NotSupportedBrowser, {isBrowserSupported} from './NotSupportedBrowser';
import Wallboard from './views/Wallboard/Wallboard';
import SipTrunksList from './views/SipTrunks/List/SipTrunksList';
import SipTrunkDetails from './views/SipTrunks/SipTrunkDetails/SipTrunkDetails';
import ChangePassword from './views/ChangePassword/ChangePassword';
import useBackButton from './hooks/useBackButton';
import SupervisorPanel from './views/SupervisorPanel/SupervisorPanel';
import {IndividualType} from './store/types/CustomerInfo';
import {CallParkingSlotList} from './views/CallParkingSlots/CallParkingSlotList';
import MySvProfile from './views/MyProfile/MySvProfile';
import MyProfile from './views/MyProfile/MyProfile';
import {ComponentPlugin, PluginConfig} from './store/types/Plugins';
import PluginWrapper from './routes/PluginWrapper';
import PluginLoader from './components/PluginLoader/PluginLoader';
import {AuthReducerStateType} from './store/reducers/auth/reducer';
import {api} from './store/services/axios';
import DeviceProfileDetails from './views/DeviceProfiles/DeviceProfileDetails';
import DevicePhoneBookList from './views/DeviceProfiles/DevicePhoneBookList';

initReduxStore(Config.CUSTOMER_API_URL);

function validatePluginsConfig(config: PluginConfig[]) {
    const reqKeys = [
        'route',
        'routerLink',
        'linkLabel',
        'component',
        'directory'
    ];

    const pluginsName: string[] = [];
    const pluginsRoutes: string[] = [];


    config.forEach((el) => {
        if(!reqKeys.every(item => Object.keys(el).includes(item))){
            throw new Error(`Invalid configuration for plugin: ${el.component}`)
        }

        if(pluginsName.includes(el.component))
        {
            throw new Error(`Plugin name need to be unique: ${el.component}`)
        }

        if(pluginsRoutes.includes(el.route))
        {
            throw new Error(`Plugin route need to be unique: ${el.component}`)
        }

        const regex = /^\/[a-zA-Z0-9\-]+(\/[a-zA-Z0-9\-]+)*$/;
        if(!regex.test(el.route))
        {
            throw new Error(`Invalid route path in: ${el.component} -> invalid route path : ${el.route}`)
        }

        pluginsName.push(el.component)
        pluginsRoutes.push(el.route)
    });

}

const loadPlugins = async (
    data: AuthReducerStateType,
): Promise<PluginConfig[]> => {
    try {
        // @ts-ignore
        const pluginsManifest = await fetch('/plugins/plugins.json');
        const config: PluginConfig[] = await pluginsManifest.json();

        if(config.length)
        {
            validatePluginsConfig(config)

            if (!localStorage.getItem('custom_plugins')) {
                localStorage.setItem(
                    'custom_plugins',
                    JSON.stringify(
                        config.map(
                            (config: Omit<ComponentPlugin, 'Component'>) => ({
                                ...config,
                                data,
                                apiHandler: api
                            }),
                        ),
                    ),
                );
            }

            return config || [];
        }

        return config || [];

    } catch (e: any) {
        let msg = e?.message || e;

        if (typeof msg === 'string' && (msg.includes('JSON.parse') || msg.includes('Unexpected token'))) {
            msg = 'Missing plugin config file – plugins not initialized or file not found';
        }

        console.info('Fetch plugins init info:', msg);
        return [];
    }
};

const App = () => {
    useBackButton(() => location.reload());

    return (
        <Provider store={store}>
            <AppNavigation />
            <Zendesk />
        </Provider>
    );
};

const AppNavigation = () => {
    const dispatch = useDispatch();

    const pluginsRef = useRef<PluginConfig[]>([]);

    const session_id = useSelector(
        (state: ReduxState) => state.auth?.session_id,
    );
    const sessionData = useSelector((state: ReduxState) => state.auth);
    const userType = useSelector(
        (state: ReduxState) =>
            state.generic.sessionData?.individual_type ||
            IndividualType.Manager,
    );

    const { pathname } = history.location;
    const i_customer = useSelector(
        (state: ReduxState) => state.generic.sessionData?.i_customer,
    );
    const reload = useSelector(
        (state: ReduxState) => state.generic.sessionData?.reload,
    );
    const { customer } = useSelector((state: ReduxState) => state.myProfile);
    const { isLoading } = useSelector((state: ReduxState) => state.permissions);
    const { login, oldPassword } = useSelector(
        (state: ReduxState) => state.auth,
    );

    const defaultRoute = useDefaultScreenBasedOnPermissions();

    const userId =
        customer?.i_individual != undefined && customer?.i_individual !== -1
            ? `individual${customer?.i_individual.toString()}`
            : `customer${i_customer?.toString()}`;

    const tagManagerArgs = {
        dataLayer: {
            iCustomer: i_customer,
            userId: crypto
                .createHash('md5')
                .update(userId || '')
                .digest('hex'),
        },
    };

    Config.GTM_TAG_ID.length > 0 && TagManager.dataLayer(tagManagerArgs);

    useEffect(() => {
        const isPublicRoute = Object.values(PublicRoutes).includes(pathname);
        const params = new URLSearchParams(window.location.search || '');
        const path = (pathname || '').toLocaleLowerCase();
        if (
            (pathname || '').toLocaleLowerCase() ===
                Routes.Default.toLocaleLowerCase() &&
            params.has('data_for_portal')
        ) {
            const value = params.get('data_for_portal') || '';
            dispatch(actions.signInFromAdmin.request({ value }));
        } else if (
            !session_id &&
            !isPublicRoute &&
            path != Routes.ChangePassword
        ) {
            history.push(Routes.SignIn);
        } else if (path === Routes.ChangePassword && session_id) {
            history.push(Routes.MyProfile + '?tab=1');
        } else if (
            path === Routes.ChangePassword &&
            !session_id &&
            (!login || !oldPassword)
        ) {
            history.push(Routes.SignIn);
        } else if (isPublicRoute && session_id) {
            history.push(Routes.Default);
        }
    }, [session_id, pathname]);

    useEffect(() => {
        if (session_id) {
            dispatch(actions.getMyProfileDetails.request());
            dispatch(actions.getPortalPermissions.request());
            dispatch(actions.getGlobalCustomerInfo.request());

            setInterval(() => {
                dispatch(actions.cleanAsyncReq.request());
            }, 300000);

            // @ts-ignore
            loadPlugins(sessionData).then((data) => {
                pluginsRef.current.length = 0;
                data && pluginsRef.current.push(...data);
            });
        }
    }, [session_id]);

    useEffect(() => {
        if (session_id && reload) {
            location.reload();
        }
    }, [reload, session_id]);

    const isSupported = isBrowserSupported(window.navigator.userAgent);
    if (!isSupported) {
        return <NotSupportedBrowser />;
    }

    if (session_id && isLoading) {
        return <div role="progressbar" />;
    }

    return (
        <Router history={history}>
            <Toaster />

            <SidebarProvider userType={userType}>
                {!session_id ? (
                    <Switch>
                        <>
                            <BaseRoute
                                exact
                                path={Routes.SignIn}
                                component={SignIn}
                            />
                            <BaseRoute
                                path={Routes.ResetPassword}
                                component={ResetPassword}
                            />
                            <BaseRoute
                                path={Routes.PasswordRecovery}
                                exact
                                component={PasswordRecovery}
                            />
                            <BaseRoute
                                path={Routes.PasswordExpiration}
                                exact
                                component={PasswordExpiration}
                            />
                            <BaseRoute
                                path={Routes.ChangePassword}
                                exact
                                component={ChangePassword}
                            />
                        </>
                    </Switch>
                ) : (
                    <Switch>
                        <ProtectedRoute
                            exact
                            path={Routes.Default}
                            component={() => <Redirect to={defaultRoute} />}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.Dashboard}
                            component={Dashboard}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.SupervisorPanel}
                            component={SupervisorPanel}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.Wallboard}
                            component={Wallboard}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.Extensions}
                            component={ExtensionsList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.ExtensionsDetails}
                            component={(props: any) => (
                                <ExtensionDetails id={props.match.params.id} />
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.SipTrunkDetails}
                            component={(props: any) => (
                                <SipTrunkDetails id={props.match.params.id} />
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.RingGroups}
                            component={RingGroupsList}
                            exact
                        />
                        <ProtectedRoute
                            path={Routes.AddRingGroup}
                            component={AddRingGroup}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.RingGroupsDetails}
                            component={(props: any) => (
                                <RingGroupDetails id={props.match.params.id} />
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.DidNumbers}
                            component={DidNumbersList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.Devices}
                            component={DevicesList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.DeviceProfiles}
                            component={DeviceProfilesList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.DevicesDetails}
                            component={(props: any) =>
                                <DeviceDetails id={props.match.params.id} />
                            }
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.DevicePhoneBooks}
                            component={DevicePhoneBookList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.DevicePhoneBookDetails}
                            //todo! add tge proper value
                            component={(props: any) =>
                                <DeviceDetails id={props.match.params.id} />
                            }
                        />
                        <ProtectedRoute
                            path={Routes.CallActivity}
                            component={CallHistoryComponent}
                        />
                        <ProtectedRoute
                            path={Routes.MyProfile}
                            component={
                                userType == IndividualType.Supervisor
                                    ? MySvProfile
                                    : MyProfile
                            }
                        />
                        <ProtectedRoute
                            path={Routes.MyCompany}
                            component={MyCompanyDetails}
                        />
                        <ProtectedRoute
                            path={Routes.CallRecordings}
                            component={CallRecordingsList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.PortalUsers}
                            component={PortalUsersDetails}
                        />
                        <ProtectedRoute
                            path={Routes.EditPortalUser}
                            component={(props: any) => (
                                <EditPortalUser id={props.match.params.id} />
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.Billing}
                            component={BillingDetails}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.FaxMailboxes}
                            component={FaxMailboxesList}
                        />
                        <ProtectedRoute
                            path={Routes.PhoneBook}
                            component={PhoneBookList}
                        />
                        <ProtectedRoute
                            path={Routes.CallParkingSlots}
                            component={CallParkingSlotList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.AutoAttendants}
                            component={AutoAttendantsList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.AutoAttendantsDetails}
                            component={(props: any) => (
                                <AutoAttendantDetails
                                    id={props.match.params.id}
                                />
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.FaxMailboxesDetails}
                            component={(props: any) => (
                                <FaxMailboxDetails id={props.match.params.id} />
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.AutoAttendantsMenu}
                            component={(props: any) => (
                                <AutoAttendantsMenuDetails
                                    autoAttendantId={
                                        props.match.params.autoAttendantId
                                    }
                                    menuId={props.match.params.menuId}
                                />
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.CallSettings}
                            component={CallSettingsDetails}
                        />
                        <ProtectedRoute
                            path={Routes.SipTrunks}
                            component={SipTrunksList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.DeviceProfilesDetails}
                            component={(props: any) => (<DeviceProfileDetails id={props.match.params.id}/>)}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.DeviceProfilesCopy}
                            component={(props: any) => (<DeviceProfileDetails id={props.match.params.id} copy />)}
                        />
                        {pluginsRef.current.map(
                            (pluginConfig: PluginConfig, index) => (
                                <PluginWrapper
                                    key={
                                        'custom-pluginConfig-' +
                                        index.toString()
                                    }
                                    exact
                                    path={pluginConfig.route}
                                    // @ts-ignore
                                    component={() => (
                                        <PluginLoader {...pluginConfig} />
                                    )}
                                />
                            ),
                        )}
                        <ProtectedRoute component={NotFound} />
                    </Switch>
                )}
            </SidebarProvider>
        </Router>
    );
};

export default App;
