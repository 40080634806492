import React, {useMemo, useState} from "react";
import {ReactComponent as RedIcon} from '../../assets/call_control_icons/red.svg';
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {useDispatch} from "react-redux";
import {SipCallState} from "../../store/actions/ringgroups/payloads";
import {actions} from "../../store";
import {CallControlsProps} from "./callControls.utils";
import classNames from "classnames";
import DisconnectDialog from "./DisconnectDialog";


const useStyles = makeStyles(() => ({
    root: {
        '& path, & rect': {transition: 'fill .4s ease'},
        cursor: 'pointer',


    },
    icon: {
        '& > rect': {
            fill: Colors.White
        },

        '&:hover > rect': {
            fill: Colors.Error
        },
        '&:hover  path': {
            fill: Colors.White
        },
    },
    disabled: {
        '& > rect': {
            fill: 'transparent'
        },

        '& path': {
            fill: Colors.Gray6
        },
    }
}))


const Disconnect: React.VFC<CallControlsProps> = (
    {activeCallEntity, callbackAfterOnClick, accounts, isReadOnly}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);


    const enableState = [
        SipCallState.Connected,
        SipCallState.Trying,
        SipCallState.Ringing,
        SipCallState.Holding,
        SipCallState.Held,
        SipCallState.Queued,
        SipCallState.Early,
        SipCallState.Terminated,
        SipCallState.Dequeued,
        SipCallState.Transferred,
        SipCallState.Parked,
    ];

    const enabledStateCondition = useMemo(()=>{
        return enableState.find((item) => item == activeCallEntity.state) == undefined;
    },[activeCallEntity])

    const disabled = isReadOnly || enabledStateCondition;

    const handleOnClick = () => {
        dispatch(actions.terminateCall.request({
            id: activeCallEntity.call.id,
            tag: activeCallEntity.call.tag,
            callback: callbackAfterOnClick ? callbackAfterOnClick : undefined
        }))
    }

    const toggleVisibility = (value: boolean) => setIsOpen(value);


    const tooltipToShow = useMemo(()=>{
        return isReadOnly
            ? t('common:noPermissionToResource')
            : t('enums:inactiveMenuAction.DisconnectCall')
    }, [isReadOnly])


    return (
        <>
            <CustomizedTooltip
                copy={false}
                title={tooltipToShow}
                disableHoverListener={!isReadOnly && enabledStateCondition}
            >
                <RedIcon
                    className={classNames(classes.root, disabled ? classes.disabled : classes.icon)}
                    onClick={!disabled ? () => toggleVisibility(true) : undefined}
                />
            </CustomizedTooltip>

            <DisconnectDialog handleClickOnConfirm={handleOnClick}
                isOpen={isOpen}
                toggleVisibility={() => toggleVisibility(false)}
                accounts={accounts || []}
                activeCallEntity={activeCallEntity}
            />
        </>

    )
}

export default Disconnect;