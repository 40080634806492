import React from 'react';
import { useStyles } from "./DeviceDetailsTab.utils";
import classNames from 'classnames';
import PortedDeviceInfoForm from '../../components/Devices/PortedDeviceInfoForm';
import DeviceInfo from '../../components/Devices/DeviceInfo';
import { UAInfo } from '../../store/types/Devices';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { IndividualType } from '../../store/types/CustomerInfo';

const DeviceDetailsTab: React.VFC<{
    device: UAInfo | undefined,
    setForm: (value: UAInfo | undefined) => void,
    validate: boolean,
    setIsDirty: (value: boolean) => void,
    portedDeviceOnSave: (isValid: boolean, returnToList: boolean) => void,
}> = ({
    device,
    setForm,
    validate,
    setIsDirty,
    portedDeviceOnSave
}) => {
    const classes = useStyles();
    const userType = useSelector((state: ReduxState) => state.generic.sessionData?.individual_type || IndividualType.Manager);
    const isSupervisor = userType == IndividualType.Supervisor;

    return (
            <div className={classNames(classes.contentDevice, device?.ported === 'Y'
                && classes.extraBottomPadding)}
            >
                {isSupervisor ? (
                    <DeviceInfo
                        className={classes.deviceInfo}
                        device={device}
                        showAsciiValue={device?.ported === 'Y'}
                    />) 
                : device?.ported === 'Y' ? (
                    <PortedDeviceInfoForm
                        setFormValues={setForm}
                        onSubmitValidation={validate}
                        setDirty={setIsDirty}
                        setIsFormValid={(v) => {
                            portedDeviceOnSave(v, false);
                        }}
                        className={classes.deviceInfo}
                        device={device}
                    />
                ) : (
                    <DeviceInfo
                        className={classes.deviceInfo}
                        device={device}
                        showAsciiValue
                    />
                )}
            </div>);
};

export default DeviceDetailsTab;