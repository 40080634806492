import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogContainer, {
    DialogButton,
} from '../../AlertDialog/DialogContainer';
import { Formik } from 'formik';
import SettingsForm from '../../Forms/AutoAttendantsMenus/SettingsForm';
import { settingsFormValidationSchema } from '../../Forms/AutoAttendantsMenus/SettingsForm.utils';
import PromptForm from '../../Forms/AutoAttendantsMenus/PromptForm';
import { promptsFormValidationSchema } from '../../Forms/AutoAttendantsMenus/PromptForm.utils';
import { defaultValues } from '../../../hooks/useEditAutoAttendantMenuFormData';
import Tabs from '../../Tabs/Tabs';
import { useStyles } from './utils';
import classNames from 'classnames';
import PermissionProvider from '../../PermissionProvider/PermissionProvider';
import {intervalFormValidationSchema} from "../../IntervalSelect/IntervalSelect.utils";
import {EditAutoAttendantsMenuForm, isDirty} from "../../../views/AutoAttendants/AutoAttendantsMenuDetails.utils";

interface EditMenuDialogProps {
    isOpen: boolean;
    menu?: EditAutoAttendantsMenuForm;
    toggleVisibility?: () => void;
    onSave?: (form: EditAutoAttendantsMenuForm) => void;
    menuSettingsPermission: string[];
    menuPromptsPermission: string[];
}

const formValidationSchema = settingsFormValidationSchema
    .concat(promptsFormValidationSchema)
    .concat(intervalFormValidationSchema);

const EditMenuDialog: React.VFC<EditMenuDialogProps> = ({
    menu,
    isOpen,
    toggleVisibility,
    onSave,
    menuSettingsPermission,
    menuPromptsPermission
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Formik<EditAutoAttendantsMenuForm>
            initialValues={menu || defaultValues}
            validationSchema={formValidationSchema}
            onSubmit={(form) => {
                onSave?.(form);
            }}
            enableReinitialize
            validateOnChange={false}
        >
            {({ submitForm, dirty, resetForm, values, initialValues }) => (
                <>
                    <DialogContainer
                        contentClass={classes.content}
                        isOpen={isOpen}
                        dataQa="add-new-menu-dialog"
                        dataTestId="add-new-menu-dialog"
                        header={t('screens:autoAttendants.editNamedMenu', {
                            value: menu?.name,
                        })}
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={() => {
                                    toggleVisibility?.();
                                    resetForm();
                                }}
                                dataQa={'add-new-menu-dialog-cancel-button'}
                            />,
                            <DialogButton
                                className={classNames(
                                    !dirty && classes.disabledButton,
                                )}
                                disabled={!isDirty(dirty, values, initialValues)}
                                key="save"
                                label={t('common:save')}
                                onClick={submitForm}
                                dataQa={'add-new-menu-dialog-save-button'}
                                primary
                            />,
                        ]}
                    >
                        <Tabs
                            tabsNames={[
                                t('common:settings'),
                                t('screens:autoAttendants.prompts'),
                            ]}
                            className={classes.tabs}
                        >
                            <PermissionProvider
                                permission={
                                    menuSettingsPermission
                                }
                            >
                                <SettingsForm />
                            </PermissionProvider>

                            <PermissionProvider
                                permission={
                                    menuPromptsPermission
                                }
                            >
                                <PromptForm />
                            </PermissionProvider>
                        </Tabs>
                    </DialogContainer>
                </>
            )}
        </Formik>
    );
};

export default EditMenuDialog;
