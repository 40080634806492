import React from 'react';
import {makeStyles} from '@material-ui/core';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import DateTimeCell from '../../../components/Calls/DateTimeCell';
import {Colors} from '../../../styles/Colors';
import TwoLineCell from '../../../components/Calls/TwoLineCell';
import {
    CalleeOrCaller,
    ConferenceInfo,
    SipCall,
    SipCallGroup,
    SipCallState,
    SipCallType
} from '../../../store/actions/ringgroups/payloads';
import {ExtensionType} from '../../../store/types/Extension';
import {RingGroupType} from '../../../store/types/RingGroup';
import Transfer from "../../../components/CallControls/Transfer";
import Disconnect from "../../../components/CallControls/Disconnect";
import PutOnHold from "../../../components/CallControls/PutOnHold";
import AddAnotherExtensionToCall from "../../../components/CallControls/AddAnotherExtensionToCall";
import Resume from "../../../components/CallControls/Resume";
import {PermissionType} from "../../../store/types/Permission";
import RingGroupCell from "../../../components/Calls/RingGroupCell";
import {getRoute, Routes} from "../../../routes/routes";
import i18next from "i18next";
import CallerCell from "../../../components/Calls/CallerCell";
import Spy from "../../../components/CallControls/Spy";
import Whisper from "../../../components/CallControls/Whisper";
import Barge from "../../../components/CallControls/Barge";
import BarLoader from "../../../components/BarLoader/BarLoader";
import {YesNo} from '../../../store/types/CallScreening';

export type ActiveCallsFiltersProps = {
    state?: SipCallState;
    type?: SipCallType;
    ringGroup?: string;
    extension?: ExtensionType,
};

export type DropDownDictionaryItem<T> = {
    name: string;
    value: T | undefined;
};

export type GroupedDropDownDictionaryItem<T> = {
    name: string;
    value: T | undefined;
    category: string;
};

export const useStyles = makeStyles(() => ({
    formsContainer: {
        minWidth: 1124.910
    },
    extensionField: {
        width: 'fit-content',
        minWidth: 220,
    },
    extensionFieldWithoutSocket: {
        width: 'fit-content',
        minWidth: 160,
    },
    tableContainer: {
        maxWidth: 1040,

        '& tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4) span': {
            marginLeft: -7,
        },

        '& th:nth-child(2)': {
            padding: 0,
            width: '24px !important'
        },

        '& tr td:nth-child(2)': {
            padding: '0 !important',
            width: '24px !important',
            justifyContent: 'end'
        },
    },
    connectingText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: Colors.Gray5,
        fontFamily: 'Roboto',
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 32,
        width: "auto"
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 'auto',

        '& > *': {
            marginRight: 16,
        },
    },
    button: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
        width: 96,
        marginTop: 8
    },
    durationHeader: {
        paddingLeft: 15,
        width: 'fit-content'
    },
    actionHeader: {
        textAlign: "center",
        width: '100%'
    },
    duration: {
        fontSize: 17,
    },
    actions: {
        display: "flex",
        '& svg': {
            marginRight: 10
        },

        '& svg:last-child': {
            marginRight: 0
        }

    },

    actions2: {
        display: "flex",
        width:'100%',
        justifyContent:'center',
        '& svg': {
            marginRight: 10
        },

        '& svg:last-child': {
            marginRight: 0
        }
    },

    formDropdown: {
        minWidth: 160
    },

    highlightedActiveCall: {
        background: '#FFF5DD !important',
        borderLeft: '4px solid ' + Colors.Primary2,

        '& [custom-attrib-file-counter="custom-attrib-file-counter"]': {
            background: Colors.Primary2,
            color: Colors.White,

            '& svg > path': {
                fill: Colors.White
            }
        },
    },
}));

export const displayCallerText = (accounts: ExtensionType[], obj?: CalleeOrCaller) => {
    if (!obj?.extension_id) {
        return '';
    }

    const filtered = accounts.filter(e => e.extension_id?.length
        && obj.extension_id == e.extension_id
        && obj.centrex_id + '' == e.i_customer + '');

    if (!filtered.length) {
        return '';
    }

    return obj.extension_id + (filtered[0].extension_name ? (" - " + filtered[0].extension_name) : '');
}

export const fetchRingGroupNameByQueue = (call: SipCall, huntGroupList?: RingGroupType[]): RingGroupType | undefined => {
    return huntGroupList?.find(e => e?.assigned_callqueue?.i_c_queue === call.queue_info?.i_c_queue);
}

export const fetchRingGroupNameByHuntId = (huntId: string, huntGroupList?: RingGroupType[]): RingGroupType | undefined => {
    return huntGroupList?.find(e => e?.id === huntId);
}

export const displayCallerHeader = (t: TFunction<string>, obj?: CalleeOrCaller) => {
    return obj?.id == 'call_supervision'
        ? t('screens:calls.conference')
        : (obj?.id ?? obj?.access_number ?? '');
}

export const initialValues: ActiveCallsFiltersProps = {
    state: undefined,
    type: undefined,
    ringGroup: undefined,
    extension: undefined
};

export const generateColumns = (
    accounts: ExtensionType[],
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    actionsPermission: PermissionType,
    userDateTimeFormat?: string,
    handleOnClick?: () => void,
    isLoadingRingGroupsAccounts?:boolean,
    sipCalls?: SipCall[]
): Column<SipCall>[] | Column<SipCallGroup>[] | undefined => {

    const actions = actionsPermission != PermissionType.Hidden ?
        {
            accessor: 'id',
            Header: (<div className={classes.actionHeader}>{t<string>('screens:calls.actions')}</div>),
            width: 2,
            Cell: function Cell(params: any) {


                console.log('render actions')
                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <span className={classes.actions}>
                        <Transfer activeCallEntity={params.row.original}
                                i_customer={params.row.original.i_customer ?? ''}
                                callbackAfterOnClick={handleOnClick}
                                isReadOnly={actionsPermission == PermissionType.ReadOnly}
                                accounts={accounts}
                                sipCalls={sipCalls}
                        />
                        <PutOnHold activeCallEntity={params.row.original} callbackAfterOnClick={handleOnClick}
                                isReadOnly={actionsPermission == PermissionType.ReadOnly}
                        />
                        <Resume activeCallEntity={params.row.original} callbackAfterOnClick={handleOnClick}
                                isReadOnly={actionsPermission == PermissionType.ReadOnly}
                        />
                        <AddAnotherExtensionToCall activeCallEntity={params.row.original}
                                i_customer={params.row.original.i_customer ?? ''}
                                callbackAfterOnClick={handleOnClick}
                                isReadOnly={actionsPermission == PermissionType.ReadOnly}
                                accounts={accounts}
                                sipCalls={sipCalls}
                        />
                        <Disconnect activeCallEntity={params.row.original} callbackAfterOnClick={handleOnClick}
                                    isReadOnly={actionsPermission == PermissionType.ReadOnly}
                                    accounts={accounts}
                        />
                    </span>
                );
            }
        } : undefined;

    const main = [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.startTime'),
            width: 1,
            Cell: function Cell(params: any) {


                if (params.row.original.groupName) {
                    return (
                        <div id={'group_row'}>
                            <RingGroupCell
                                title={params.row.original.groupName}
                                link={params.row.original.link}
                            />
                        </div>
                    )
                }

                const time = params.row.original.start_time || params.row.original.connect_time;
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        //@ts-ignore
                        time={time}
                        customDateFormat={userDateTimeFormat}
                        hideDateWhenIsToday
                    />
                );
            },
        },
        {
            accessor: 'type',
            Header: '',
            Cell: function Cell(params: any) {

                return (<CallerCell type={params.row.original.type} groupName={params.row.original?.groupName}/>)
            },
            minWidth: 24,
            maxWidth: 24
        },
        {
            accessor: 'caller',
            Header: t<string>('screens:calls.caller'),
            width: 1.5,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <TwoLineCell
                        headerText={displayCallerHeader(t, params.row.original.caller)}
                        descriptionText={displayCallerText(accounts, params.row.original.caller)}
                        placeHolder={isLoadingRingGroupsAccounts && <BarLoader/>}
                    />
                );
            },
        },
        {
            accessor: 'callee',
            Header: t<string>('screens:calls.destination'),
            width: 1.5,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <TwoLineCell
                        headerText={displayCallerHeader(t, params.row.original.callee)}
                        descriptionText={
                            displayCallerText(accounts, params.row.original.callee)
                        }
                        placeHolder={isLoadingRingGroupsAccounts && <BarLoader/>}
                    />
                );
            },
        },
        {
            accessor: 'state',
            Header: t<string>('screens:calls.callState'),
            width: 2,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                const call = params.row.original;

                let secondRowText = '';
                if (call.state === SipCallState.Queued) {
                    secondRowText = t<string>('screens:calls.queuedAndBelow', {
                        position: call.queue_info?.position || 0,
                    });
                } else if(call.state === SipCallState.Parked) {
                    secondRowText = t<string>('screens:calls.callParkingId', {
                        park_id: call.park_id ?? '',
                    });
                }

                return (
                    <TwoLineCell
                        headerText={t(
                            `enums:sipCallState.${params.row.original.state}`,
                        ) + (params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                            ? '...' : '')
                        }
                        headerClassName={
                            params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                                ? classes.connectingText
                                : undefined
                        }
                        descriptionText={secondRowText}
                    />
                );
            },
        },
        {
            accessor: 'update_time',
            Header: (<div className={classes.durationHeader}>{t<string>('screens:calls.duration')}</div>),
            width: 1,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <span className={classes.duration}>
                        {params.row.original.duration}
                    </span>
                );
            }
        },

    ];

    if (actions) {
        // @ts-ignore
        return [...main, actions];
    } else {
        // @ts-ignore
        return main
    }
};

export const generateAgentCurrentCallsColumns = (
    accounts: ExtensionType[],
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    actionsPermission: PermissionType,
    participantId: string,
    userDateTimeFormat?: string,
    handleOnClick?: () => void,
    isLoadingRingGroupsAccounts?: boolean,
    conferenceList?: ConferenceInfo[],
): Column<SipCall>[] | Column<SipCallGroup>[] | undefined => {

    const actions = actionsPermission != PermissionType.Hidden ?
        {
            accessor: 'id',
            Header: (<div className={classes.actionHeader}>{t<string>('screens:calls.actions')}</div>),
            width: 2,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                const call = params.row.original as SipCall;

                let isDisabled = false;
                const conferenceName = call?.conference_info?.name;
                const hasConferenceName = !!conferenceName;
                const participants = conferenceList?.find(e=>e.name === call.conference_info?.name)?.participants;

                const svParticipant = participants
                    ?.find(e => e.caller_id === participantId);
                const supervisorIsInCall = !!participantId && !!svParticipant && params.row.original.caller?.id == 'call_supervision';

                if(hasConferenceName)
                {
                    isDisabled = !supervisorIsInCall;
                }
                else
                {
                    isDisabled = params.row.original.state != SipCallState.Connected;
                }

                const isSvInSpyMode = !isDisabled && !!svParticipant && svParticipant?.mute === YesNo.Yes;
                const isSvInWhisperMode = !isDisabled && !!svParticipant && svParticipant?.mute === YesNo.No && svParticipant.coach === YesNo.Yes;
                const isSvInBargeInMode = !isDisabled && !!svParticipant && svParticipant?.mute === YesNo.No && svParticipant.coach !== YesNo.Yes;

                return (
                    <span className={classes.actions2}>

                        <Spy activeCallEntity={params.row.original}
                            callbackAfterOnClick={handleOnClick}
                            isReadOnly={actionsPermission == PermissionType.ReadOnly || isDisabled}
                            call={params.row.original.call}
                            participantId={participantId}
                            isActive={isSvInSpyMode}
                            supervisorIsInCall={supervisorIsInCall}
                            conferenceName={conferenceName}
                            inCallParticipantId={svParticipant?.participant_id}
                        />
                        <Whisper activeCallEntity={params.row.original}
                            callbackAfterOnClick={handleOnClick}
                            isReadOnly={actionsPermission == PermissionType.ReadOnly || isDisabled}
                            call={params.row.original.call}
                            participantId={participantId}
                            isActive={isSvInWhisperMode}
                            supervisorIsInCall={supervisorIsInCall}
                            conferenceName={conferenceName}
                            inCallParticipantId={svParticipant?.participant_id}
                        />
                        <Barge activeCallEntity={params.row.original}
                            callbackAfterOnClick={handleOnClick}
                            isReadOnly={actionsPermission == PermissionType.ReadOnly || isDisabled}
                            call={params.row.original.call}
                            participantId={participantId}
                            isActive={isSvInBargeInMode}
                            supervisorIsInCall={supervisorIsInCall}
                            conferenceName={conferenceName}
                            inCallParticipantId={svParticipant?.participant_id}
                        />

                    </span>
                );
            }
        } : undefined;

    const main = [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.startTime'),
            width: 1,
            Cell: function Cell(params: any) {


                if (params.row.original.groupName) {
                    return (
                        <div id={'group_row'}>
                            <RingGroupCell
                                title={params.row.original.groupName}
                                link={params.row.original.link}
                            />
                        </div>
                    )
                }

                const time = params.row.original.start_time || params.row.original.connect_time;
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        //@ts-ignore
                        time={time}
                        customDateFormat={userDateTimeFormat}
                        hideDateWhenIsToday
                    />
                );
            },
        },
        {
            accessor: 'type',
            Header: '',
            Cell: function Cell(params: any) {

                return (<CallerCell type={params.row.original.type} groupName={params.row.original?.groupName}/>)
            },
            minWidth: 24,
            maxWidth: 24
        },
        {
            accessor: 'caller',
            Header: t<string>('screens:calls.caller'),
            width: 1.5,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <TwoLineCell
                        headerText={displayCallerHeader(t, params.row.original.caller)}
                        descriptionText={displayCallerText(accounts, params.row.original.caller)}
                        placeHolder={isLoadingRingGroupsAccounts && <BarLoader/>}
                    />
                );
            },
        },
        {
            accessor: 'callee',
            Header: t<string>('screens:calls.destination'),
            width: 1.5,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <TwoLineCell
                        headerText={displayCallerHeader(t, params.row.original.callee)}
                        descriptionText={
                            displayCallerText(accounts, params.row.original.callee)
                        }
                        placeHolder={isLoadingRingGroupsAccounts && <BarLoader/>}

                    />
                );
            },
        },
        {
            accessor: 'state',
            Header: t<string>('screens:calls.callState'),
            width: 2,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                const call = params.row.original;

                let secondRowText = '';
                if (call.state === SipCallState.Queued) {
                    secondRowText = t<string>('screens:calls.queuedAndBelow', {
                        position: call.queue_info?.position || 0,
                    });
                } else if(call.state === SipCallState.Parked) {
                    secondRowText = t<string>('screens:calls.callParkingId', {
                        park_id: call.park_id ?? '',
                    });
                }

                return (
                    <TwoLineCell
                        headerText={t(
                            `enums:sipCallState.${params.row.original.state}`,
                        ) + (params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                            ? '...' : '')
                        }
                        headerClassName={
                            params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                                ? classes.connectingText
                                : undefined
                        }
                        descriptionText={secondRowText}
                    />
                );
            },
        },
        {
            accessor: 'update_time',
            Header: (<div className={classes.durationHeader}>{t<string>('screens:calls.duration')}</div>),
            width: 1,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <span className={classes.duration}>
                        {params.row.original.duration}
                    </span>
                );
            }
        },

    ];

    if (actions) {
        // @ts-ignore
        return [...main, actions];
    } else {
        // @ts-ignore
        return main
    }
};

export const prepareData = (data: SipCall[], huntGroupList: RingGroupType[] | undefined) => {

    let output: any[] = [{
        id: i18next.t<string>('screens:calls.otherCalls'),
        groupName: i18next.t<string>('screens:calls.otherCalls'),
        i_c_group: undefined,
        link: undefined,
        isGroup: true,
        items: []
    }];

    huntGroupList?.forEach(item => !output.find(v => v.groupName == item.name) ? output.push({
        id: item.name,
        groupName: item.name,
        i_c_group: item.i_c_group,
        link: getRoute(Routes.RingGroupsDetails, {id: item.i_c_group}),
        isGroup: true,
        items: []
    }) : null);

    data?.forEach(item => {

        const call = item;
        const huntId = call.callee.huntgroup_id || call.caller.huntgroup_id || '';

        let noc: RingGroupType | undefined = undefined;
        if (call.state === SipCallState.Queued || call.state === SipCallState.Dequeued) {
            noc = fetchRingGroupNameByQueue(call, huntGroupList);
        } else if (huntId) {
            noc = fetchRingGroupNameByHuntId(huntId, huntGroupList);
        }

        const index = output.findIndex(item => item.groupName == noc?.name);

        if (index != -1) {
            output[index].items.push(item)
        } else {
            output[0].items.push(item)
        }
    })


    output = output.filter(v => v.items.length);

    if (output.length) {
        output.reverse();
        output.forEach(v => v.items = v.items.sort((a: SipCall, b: SipCall) => {
            return (a?.start_time || a?.connect_time || 0) < (b?.start_time || b?.connect_time || 0)
        }))
    }

    const newOutput: Column<SipCall>[] | Column<SipCallGroup>[] | undefined = [];

    output.forEach(v => {
        const {items, ...group} = v;
        newOutput.push(group);
        newOutput.push(...items)
    })

    return newOutput;
}