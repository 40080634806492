import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../../components/SelectField/SelectField';
import TextField from '../../components/TextField/TextField';
import { DeviceProfileDetailsActionKeyRowProps, useStyle } from './DeviceProfileDetailsActionKeyRow.utils';
import DeviceProfileDetailsActionKeyIconButton from './DeviceProfileDetailsActionKeyIconButton';

const DeviceProfileDetailsActionKeyRow: React.FC<DeviceProfileDetailsActionKeyRowProps> = ({
    item,
    changeFunctionCallBack,
    changeValueCallBack,
    changeLabelCallBack,
    setActiveButton,
    onChange,
    errors,
    readonly
}) => {
    const classes = useStyle();
    const {t} = useTranslation();

    const showValue = useMemo(() => {
        if(!item?.selectedAction?.length) {
            return false;
        }
        const config = item.actionsConfiguration?.find(e => e.type === item?.selectedAction);
        if(!config) {
            return false;
        }
        const valueConfig = config?.action_attributes?.find(e => e.attribute === 'value');
        return !!valueConfig;
    }, [item]);
    
    const isValueMandatory = useMemo(() => {
        if(!item?.selectedAction?.length) {
            return false;
        }
        const config = item.actionsConfiguration?.find(e => e.type === item?.selectedAction);
        if(!config) {
            return false;
        }
        const valueConfig = config?.action_attributes?.find(e => e.attribute === 'value');
        return valueConfig?.mandatory === 1;
    }, [item]);
    
    const showLabel = useMemo(() => {
        if(!item?.selectedAction?.length) {
            return false;
        }
        const config = item.actionsConfiguration?.find(e => e.type === item?.selectedAction);
        if(!config) {
            return false;
        }
        const valueConfig = config?.action_attributes?.find(e => e.attribute === 'label');
        return !!valueConfig;
    }, [item]);
    
    const isLabelMandatory = useMemo(() => {
        if(!item?.selectedAction?.length) {
            return false;
        }
        const config = item.actionsConfiguration?.find(e => e.type === item?.selectedAction);
        if(!config) {
            return false;
        }
        const valueConfig = config?.action_attributes?.find(e => e.attribute === 'label');
        return valueConfig?.mandatory === 1;
    }, [item]);

    return (<div className={classes.root} id={'device-profile-row-' + item.itemIdentifierInternal}>
        <DeviceProfileDetailsActionKeyIconButton
            size='medium'
            item={item}
            className={classes.iconButton}
            onClick={() => {
                setActiveButton(item);
            }}
        />

        <SelectField
            label={t('screens:deviceProfiles.function')}
            items={item?.actions ?? ['']}
            value={item?.selectedAction}
            dataQa="function-input"
            onChange={(e, value: string) => {
                changeFunctionCallBack?.(item, value);
                onChange(e);
            }}
            getOptionLabel={(v) => { 
                if(!v?.length) return '';
                return t('enums:UAProfileAction.' + v + '');
            }}
            getOptionSelected={(v) =>
                v === item.selectedAction
            }
            disableClearable
            classes={{
                container: classes.formFunction
            }}
            onFocus={() => {
                setActiveButton?.(item);
            }}
            readOnly={readonly}
        />
        
        {showValue ? (
            <TextField
                label={t('screens:deviceProfiles.value')}
                value={item.value}
                required={!readonly && isValueMandatory}
                className={classes.fieldValue}
                noTooltip
                onChange={(e) => {
                    changeValueCallBack?.(item, e.target.value);
                    onChange(e);
                }}
                onFocus={() => {
                    setActiveButton?.(item);
                }}
                helperText={errors?.value}
                readOnly={readonly}
            />
        ) : (<div className={classes.fieldValue} style={{visibility: 'hidden'}}></div>)}
        
        {showLabel && (
            <TextField
                label={t('screens:deviceProfiles.label')}
                value={item.label}
                className={classes.fieldValue}
                noTooltip
                onChange={(e) => {
                    changeLabelCallBack?.(item, e.target.value);
                    onChange(e);
                }}
                onFocus={() => {
                    setActiveButton?.(item);
                }}
                readOnly={readonly}
                required={!readonly && isLabelMandatory}
            />
        )}
    </div>);
};
    
export default DeviceProfileDetailsActionKeyRow;