import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    contentDevice: {
        '& .description': {
            width: '100%',
        },
    },

    deviceInfo: {
        maxWidth: 1040,
        padding: 0,
        marginBottom: 32,
    },

    extraBottomPadding: {
        paddingBottom: 52
    },

    devicePorts: {
        maxWidth: 1040,
        display: 'flex',
        marginBottom: 40,
    },
}));