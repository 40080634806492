
import {makeStyles} from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { UAProfileKeyAction, UAProfileKeyActionConfiguration, UAProfileKeyPositionInfo } from '../../store/types/Devices';

export type DeviceProfileDetailsActionKeyRowData = {
    keyId: number;
    actions: string[];
    value: string;
    label: string;
    itemIdentifierInternal: string;
    selectedAction: string | undefined;
    position: UAProfileKeyPositionInfo;
    isActive?: boolean;
    type: 'line_key' | 'soft_key' | 'expansion_key';
    isDisabled: boolean;
    actionsConfiguration: UAProfileKeyAction[];
    selectedActionConfiguration?: UAProfileKeyActionConfiguration[];
};

export type DeviceProfileDetailsActionKeyRowProps = {
    item: DeviceProfileDetailsActionKeyRowData;
    changeFunctionCallBack?: (item: DeviceProfileDetailsActionKeyRowData, newActionValue: string) => void;
    changeValueCallBack?: (item: DeviceProfileDetailsActionKeyRowData, newValue: string) => void;
    changeLabelCallBack?: (item: DeviceProfileDetailsActionKeyRowData, newLabelValue: string) => void;
    setActiveButton: (item: DeviceProfileDetailsActionKeyRowData) => void;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {}>,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors?: any;
    readonly: boolean;
};

export const useStyle = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '676px',
        height: 72,
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
        marginBottom: 8,
        minHeight: 72,
    },
    iconButton: {
        marginTop: 16,
    },
    formFunction: {
        width: '208px !important',
        height: 58,
        marginLeft: 16,
        maxWidth: '208px !important',
    },
    fieldValue: {
        width: '180px !important',
        height: 58,
        marginLeft: 16,
        maxWidth: '180px !important',
    }
}));
