import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Colors } from '../../styles/Colors';
import CustomButton from '../../components/Button/Button';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '../../components/TextField/TextField';
import SelectField from '../../components/SelectField/SelectField';
import { DropDownDictionaryItem } from '../CallHistory/ActiveCalls/ActiveCalls.utils';
import { devicesWithButtonsConfiguration } from '../../store/actions/devices';

const useStyles = makeStyles(() => ({
    filters: {
        display: 'flex',
        alignItems: 'center',
    },

    filtersContainer: {
        flex: 1,
        paddingTop: 17,
        paddingBottom: 17,
        marginBottom: 12,
        marginTop: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
    },

    search: {
        width: 96,
        height: 40,
        marginTop: 8,
        marginLeft: 16
    },

    status: {
        maxWidth: 144 + 'px !important',
        minWidth: 144 + 'px !important',
    },

    unsetMinWidth: {
        minWidth: 'unset',
        width: 256,
        maxWidth: 256,
    },

    filterSeparation: {
        marginRight: 16,
        flex: 1,
    },

    initMac: {
        '& .MuiInputBase-input.MuiInput-input': {
            color: Colors.LightGraySecondary3,
        },
    },
}));

export type DeviceProfilesFiltersModel = {
    profileName?: string,
    modelId?: number,
}

type DeviceProfilesFiltersProps = {
    onSubmit: (model: DeviceProfilesFiltersModel) => void;
};

const DeviceProfilesFilters: React.FC<DeviceProfilesFiltersProps> = ({
    onSubmit,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [profileName, setProfileName] = useState('');
    const [modelId, setModelId] = useState<number>(0);

    const handleSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        onSubmit?.({
            modelId: modelId ?? 0,
            profileName: profileName
        });
    }, [modelId, profileName]);

    const profilesSelectList = useMemo(() => {
        const retList: DropDownDictionaryItem<number>[] = [];
        retList.push(
            {
                value: 0,
                name: t('common:any'),
            } as DropDownDictionaryItem<number>
        );
        for(const bc of devicesWithButtonsConfiguration) {
            retList.push(bc);
        }
        return retList;
    }, [devicesWithButtonsConfiguration]);

    return (
        <div className={classNames(classes.filters)}>
            <form className={classes.filtersContainer} onSubmit={handleSubmit} autoComplete="off">
                <div className={classes.filtersInputs}>
                    <SelectField
                        id={'modelId'}
                        label={t('screens:deviceProfiles.model')}
                        items={profilesSelectList}
                        value={
                            profilesSelectList.find(
                                (v) => v?.value === modelId,
                            ) || 0
                        }
                        onChange={(_, v: DropDownDictionaryItem<number>) => {
                            setModelId(v?.value ?? 0);
                        }}
                        dataQa={'model-select-item'}
                        getOptionLabel={(v: DropDownDictionaryItem<number>) => v?.name}
                        getOptionSelected={(v: DropDownDictionaryItem<number>) => v?.value === modelId}
                        classes={{ container: classNames(classes.filterSeparation, classes.unsetMinWidth) }}
                        disableClearable
                    />
                    <TextField
                        id="profileName"
                        className={classes.unsetMinWidth}
                        label={t('screens:deviceProfiles.profileName')}
                        value={profileName}
                        onChange={(e) => setProfileName(e.target.value)}
                        dataQa={'filter-by-device-id'}
                    />
                    <CustomButton
                        dataQa="search-button"
                        primary
                        accent
                        type="submit"
                        className={classes.search}
                    >
                        {t('common:search')}
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default DeviceProfilesFilters;
