import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { UATypeInfo } from '../../store/types/Devices';
import * as Yup from 'yup';
import i18n from '../../services/i18n';

export type DeviceProfileFormProps = {
    model?: UATypeInfo | null;
    name: string;
};

export type NewDeviceProfileDialogProps = {
    isOpen: boolean;
    clickOnClose?: () => void;
};

export const useStyles = makeStyles(() => ({
    error: {
        color: Colors.Error,
        fontSize: 12,
        fontWeight: 400,

        '& svg': {
            display: 'none',
        },
    },

    itemsContainer: {
        padding: '41px 48px 33px 48px',
        display: 'flex',
        flexDirection: 'column',
        width: 472,

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 472,
            marginBottom: 24,
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: 568,
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            width: 568,
            height: 234,
            padding: 0,
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 568,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 472,

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    initMac: {
        '& .MuiInputBase-input.MuiInput-input': {
            color: Colors.LightGraySecondary3,
        },
    },
}));

const requiredError = i18n.t<string>('errors:common.emptyInput');

export const addDeviceProfileValidationSchema = Yup.object().shape({
    model: Yup.object().when('i_ua', {
        is: (i_ua: number) => {
            return i_ua && i_ua > 0;
        },
        then: Yup.object()
            .nullable()
            .notRequired(),
        otherwise: Yup.object()
            .nullable()
            .required(i18n.t<string>('errors:devices.modelNotSelected')),
    }),
    name: Yup.string()
        .required(requiredError),
});

export const getRoute = (route: string, options: object) => {
    let output = route;

    for (const [key, value] of Object.entries(options)) {
        const search = `:${key}`;

        // @ts-ignore
        output = output.replace(search, value);
    }

    return output;
};