import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Header from '../../components/ListViewHeader/Header';
import DeviceProfilesFilters, { DeviceProfilesFiltersModel } from './DeviceProfilesFilters';
import {ReduxState} from '../../store/types';
import * as actions from '../../store/actions';
import {OptionsTemplateInfo} from '../../store/types/Devices';
import {PaginationMode} from '../../components/DataGrid/types';
import DataGrid from '../../components/DataGrid/DataGrid';
import {addId, generateColumns, useStyles} from './DeviceProfilesList.utils';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission} from "../../store/types/Permission";
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {showToast} from "../../utils/showToast";
import NewDeviceProfileDialog from './NewDeviceProfileDialog';

const DeviceProfilesList = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    usePageTitle();

    const i_customer: number = useSelector((state: ReduxState) => state.generic?.sessionData?.i_customer ?? 0);

    const [showAddNewDeviceProfileDialog, setShowAddNewDeviceProfileDialog] = useState(false);
    const handleAddNewDeviceProfileClick = () => {
        setShowAddNewDeviceProfileDialog((prev) => !prev);
    };
    
    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        i_ua_config_template?: number;
        name?: string;
    }>({
        isOpen: false
    });

    const handleDeleteAction = () => {
        dispatch(actions.deleteProfile.request({
            i_ua_config_template: deleteDialog.i_ua_config_template || 0,
            callback: () => {
                closeDeleteDialog();
                fetchData({pageSize: 100, page: 1});
                showToast(t<string>('screens:deviceProfiles.profileDeleted'));
            }
        }));
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            i_ua_config_template: undefined,
            name: undefined
        });
    };

    const handleFiltersChange = (value: DeviceProfilesFiltersModel) => {
        fetchData(undefined, value);
    };

    const {
        templates = [],
        isLoadingTemplates = false,
        totalProfiles,
        ua_type_list,
        createdProfileId,
    } = useSelector((state: ReduxState) => state.devices);

    useEffect(() => {
        if (!showAddNewDeviceProfileDialog && createdProfileId) {
            fetchData();
        }
    }, [showAddNewDeviceProfileDialog, createdProfileId]);

    const fetchData = (params?: { pageSize: number; page: number }, filter?: DeviceProfilesFiltersModel) => {
        const _pageSize = params?.pageSize ?? pageSize ?? 100;
        let _page = params?.page ?? page ?? 1;
        if(_page < 1) {
            _page = 1;
        }
        dispatch(
            actions.getOptionsTemplateList.request({
                get_total: 1,
                i_customer: i_customer,
                limit: _pageSize,
                offset: (_page - 1) * _pageSize,
                i_ua_type: !!filter?.modelId ? filter.modelId : undefined,
                name: (filter?.profileName?.length ?? 0) ? '%' + filter?.profileName + '%' : undefined
            })
        );
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    useEffect(() => {
        if(!i_customer) {return;}
        dispatch(actions.deviceTypeList.request({
            get_total: 1,
            i_customer: i_customer
        }));
    }, [i_customer]);

    const handleDelete = (obj: OptionsTemplateInfo) => {
        setDeleteDialog({isOpen: true, i_ua_config_template: obj.i_ua_config_template, name: obj.name});
    };

    const columns = useMemo(() => generateColumns(t, 
        handleDelete, 
        classes, 
        ua_type_list), 
    [ua_type_list]);

    return (
        <PermissionPlaceholder permission={Permission.Inventory.DeviceProfiles.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.content}>
                        <PermissionProvider
                            permission={Permission.Inventory.DeviceProfiles.value}
                        >
                            <Header
                                title={t('screens:deviceProfiles.listTitle')}
                                totalNumber={totalProfiles || 0}
                                dataQa={'device-profile-list-view-header'}
                                customClass={classes.header}
                                plusIcon={true}
                                buttonText={t('common:addNewButton')}
                                buttonOnClick={handleAddNewDeviceProfileClick}
                                showDetails={false}
                            >
                            </Header>
                        </PermissionProvider>

                        <DeviceProfilesFilters
                            onSubmit={handleFiltersChange}
                        />
                        <div style={{marginTop: 16}}></div>

                        <DataGrid<OptionsTemplateInfo>
                            columns={columns}
                            data={templates.map(addId)}
                            className={classes.table}
                            rowCount={totalProfiles ?? 200000}
                            loading={isLoadingTemplates}
                            onPageChange={(params) => {
                                fetchData({
                                    pageSize: params.pageSize,
                                    page: params.page,
                                });
                                setPage(params.page);
                            }}
                            onPageSizeChange={(params) => {
                                fetchData({pageSize: params.pageSize, page: 0});
                                setPageSize(params.pageSize);
                            }}
                            paginationMode={PaginationMode.Server}
                            centeredRows
                            narrowRows
                            initialPageSize={10}
                            forceFirstPage={false}
                        />
                    </div>
                </div>

                <AlertDialog
                    className={classes.deleteDialog}
                    contentClass={classes.contentDialog}
                    isOpen={deleteDialog.isOpen}
                    dataQa={'delete-profile-dialog'}
                    hideHeader
                    description={t('screens:deviceProfiles.deleteRule', {
                        name: deleteDialog.name || '',
                    })}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={closeDeleteDialog}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={handleDeleteAction}
                        />,
                    ]}
                />
                
                <NewDeviceProfileDialog
                    isOpen={showAddNewDeviceProfileDialog}
                    clickOnClose={handleAddNewDeviceProfileClick}
                />

            </div>
        </PermissionPlaceholder>
    );
};

export default DeviceProfilesList;
