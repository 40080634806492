import React, {useCallback, useMemo, useState} from 'react';
import { AssignProfileDialogProps, assignProfileValidationSchema, DropDownDictionaryItemExtended, ModelsUniqueItem, useStyles } from './AssignProfileDialog.utils';
import DialogContainer, { DialogButton } from '../../components/AlertDialog/DialogContainer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {Grid} from '@material-ui/core';
import classNames from 'classnames';
import TextField from '../../components/TextField/TextField';
import SelectField from '../../components/SelectField/SelectField';
import { useFormik } from 'formik';
import { actions } from '../../store';
import { UpdateOptionsTemplateRequest } from '../../store/types/Devices';
import Loader from '../../components/Loader/Loader';
import { ReduxState } from '../../store/types';
import {ReactComponent as UploadOk} from '../../assets/upload_ok.svg';
import {ReactComponent as UploadError} from '../../assets/upload_error.svg';

const AssignProfileDialog: React.FC<AssignProfileDialogProps> = ({
    devices,
    isModalOpen,
    setIsModalOpen,
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    
    const classes = useStyles();
    
    const {templates, updateOptionsTemplateState} = useSelector((state: ReduxState) => state.devices);

    const profilesSelectList = useMemo(
        () =>
            (templates ?? []).map((v) => ({
                name: v.name,
                value: v.i_ua_config_template,
                extendedValue: v.i_ua_type
            } as DropDownDictionaryItemExtended<number, number>)),
        [templates],
    );

    const initialValues = useMemo(() => {
        const distinctModelTypes = [...new Set(devices.map(e => e.i_ua_type))];
        const resultArray: ModelsUniqueItem[]  = [];
        for(const typeId of distinctModelTypes)
        {
            const devicesFilteredByTypeId = devices.filter(e => e.i_ua_type === typeId);
            const distinctDeviceId = [...new Set(devicesFilteredByTypeId.map(e => e.i_ua))];
            const templatesAssignedToDevices = templates?.filter(e => e.i_ua_list
                    ?.find(c => distinctDeviceId.find(k => k === c)))
                    ?? [];
            const allDevicesAssignedToFilteredTemplates = 
                [...new Set(templatesAssignedToDevices.flatMap(e => e.i_ua_list ?? []))]
                .filter(e => distinctDeviceId.find(c => c === e));
            resultArray.push({
                typeId: typeId,
                devices: devicesFilteredByTypeId,
                typeName: devicesFilteredByTypeId?.[0]?.type,
                profileId: templatesAssignedToDevices?.length === 1 && allDevicesAssignedToFilteredTemplates.length === distinctDeviceId.length
                    ? templatesAssignedToDevices[0].i_ua_config_template
                    : 0,
            } as ModelsUniqueItem);
        }
        return resultArray;
    }, [devices, templates]);

    const mapModelsUniqueItemToPayloads = useCallback((form: ModelsUniqueItem[]) => {
        const mapped: UpdateOptionsTemplateRequest[] = [];
        const unassignDevice: number[] = [];
        //unassign profile
        for(const itm of form)
        {
            for(const device of itm.devices)
            {
                const newTemplateId = itm.profileId ?? -1;
                const currentTemplateId = templates?.find(e => e.i_ua_list?.find(c => (c ?? 0) === device.i_ua))?.i_ua_config_template ?? 0;
                if(newTemplateId !== currentTemplateId) {
                    unassignDevice.push(device.i_ua);
                }
            }
        }
        for(const unassignDeviceId of unassignDevice) {
            for(const profile of templates ?? []) {
                if(profile && profile?.i_ua_list?.find(c => c === unassignDeviceId)) {
                    const alreadyInList = mapped.find(e => e.template_info.i_ua_config_template === profile.i_ua_config_template);
                    if(alreadyInList) {
                        const alreadyInListIndex = mapped.indexOf(alreadyInList);
                        mapped.splice(alreadyInListIndex, 1);
                        const toPush = {
                            template_info: {
                                ...alreadyInList.template_info,
                                i_ua_list: alreadyInList?.i_ua_list?.filter(c => c !== unassignDeviceId) ?? [],
                            }
                        }
                        mapped.push(toPush);
                    }
                    else {
                        mapped.push({
                            template_info: {
                                ...profile,
                                i_ua_list: profile?.i_ua_list?.filter(c => c !== unassignDeviceId)?? [],
                            }
                        });
                    }
                }
            }
        }
        for(const itm of form)
        {
            const profile = templates?.find(e => e.i_ua_config_template === itm.profileId);
            if(!profile) {
                continue;
            }
            const initialDeviceList = profile.i_ua_list ?? [];
            const currentDeviceList = itm.devices.map(e => e.i_ua);
            const addedDevices = currentDeviceList.filter(e => !initialDeviceList.includes(e));

            mapped.push({
                template_info: {
                    ...profile,
                    i_ua_list: [...initialDeviceList, ...addedDevices],
                },
                modelName: itm.typeName
            });
        }
        return mapped;
    }, [templates]);

    const {
        values,
        handleSubmit,
        setFieldValue,
        errors,
        resetForm,
        dirty,
    } = useFormik<ModelsUniqueItem[]>({
        initialValues,
        onSubmit: (form) => {
            const payload = mapModelsUniqueItemToPayloads(form);
            dispatch(actions.updateOptionsTemplate.request(payload));
            setStep(1);
        },
        validationSchema: assignProfileValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
    });

    const saveButton = useMemo(() => (
        <DialogButton
            key="save"
            dataQa="save-button"
            onClick={() => {
                handleSubmit();
            }}
            label={t('common:save')}
            primary
            disabled={dirty
                ? errors !== undefined && JSON.stringify(errors) !== JSON.stringify({})
                : true
            }
        />
    ), [dirty, errors]);

    const onClose = (success: boolean) => {
        setIsModalOpen(false, success);
        setStep(0);
        resetForm();
    };

    const isFinalStep = useMemo(() => {
        if(step === 0)
            return false;
        if((updateOptionsTemplateState?.total ?? 0) === -1)
            return true;
        if(updateOptionsTemplateState && updateOptionsTemplateState.total === updateOptionsTemplateState.done)
            return true;
        return false;
    }, [step, updateOptionsTemplateState]);

    const isSuccess = useMemo(() => {
        if(!isFinalStep)
            return false;
        if(updateOptionsTemplateState && updateOptionsTemplateState.total === updateOptionsTemplateState.done)
            return true;
        return false;
    }, [isFinalStep, updateOptionsTemplateState]);

    const cancelButton = useMemo(() => (
        <DialogButton
            key="cancel"
            label={isFinalStep ? t('common:close') : t('common:cancel')}
            onClick={() => onClose(isSuccess)}
        />
    ), [isFinalStep, isSuccess]);

    const actionButtons = useMemo(() => {
        const retArray = [
            cancelButton
        ];
        if(step !== 1) {
            retArray.push(saveButton);
        }
        return retArray;
    }, [cancelButton, saveButton]);

    return (<DialogContainer
                isOpen={isModalOpen}
                header={t('screens:devices.assignProfileDialogTitle')}
                headerClass={classes.header}
                dialogActionsButtons={actionButtons}
                className={classes.root}
                dataTestId='modal-container'
            >
                <Grid className={classNames(
                    classes.itemsContainer,
                    step == 1 && classes.itemsContainerStep1,
                )}>
                    {step == 0 && values.map((model, indx) => (
                        <div className={classes.uploadBox} key={'uploadBox_' + model.typeId}>
                            <TextField
                                label={t('screens:devices.model')}
                                value={model.typeName + ' (' + (model.devices?.length ?? 0) + ')'}
                                readOnly
                                noTooltip
                                className={classes.boxField}
                            />
                            <SelectField
                                id={model.typeId + ''}
                                label={t('screens:devices.profileName')}
                                items={profilesSelectList.filter(e => e.extendedValue === model.typeId)}
                                value={
                                    profilesSelectList.filter(e => e.extendedValue === model.typeId).find(
                                        (v) => v.value === model.profileId,
                                    ) || null
                                }
                                disableClearable
                                onChange={(_, v: DropDownDictionaryItemExtended<number, number>) => {
                                    setFieldValue('['+indx+'].profileId', v.value ?? 0);
                                }}
                                dataQa={'profile-select-item' + model.typeId}
                                getOptionLabel={(v: DropDownDictionaryItemExtended<number, number>) => v.name}
                                getOptionSelected={(v: DropDownDictionaryItemExtended<number, number>) => v.value === model.profileId}
                                classes={{
                                    container: classes.boxField,
                                }}
                                required={true}
                            />
                        </div>
                    ))}
                    {step == 1 && (updateOptionsTemplateState?.total ?? -1) !== -1
                        && (updateOptionsTemplateState?.total !== updateOptionsTemplateState?.done)
                        && (
                        <div className={classes.loaderContainer}>
                            <div>
                                <Loader dataQa='loader-upload' absolutePosition={false}/>
                            </div>
                            <div className={classes.statusLabel}>{t('screens:devices.assigningTemplateOf', {
                                        current: (updateOptionsTemplateState?.done ?? 0) + 1,
                                        total: updateOptionsTemplateState?.total ?? 1
                                    }
                                )}
                            </div>
                            <div className={classes.modelLabel}>
                                {updateOptionsTemplateState?.profileName}
                            </div>
                        </div>
                    )}
                    {step == 1 && (updateOptionsTemplateState?.total ?? -1) !== -1
                        && (updateOptionsTemplateState?.total === updateOptionsTemplateState?.done)
                        && (
                        <div className={classes.loaderContainer}>
                            <div>
                                <UploadOk className={classes.resultsIcon}/>
                            </div>
                            <div className={classes.uploadDoneText}>{t('screens:devices.assignCompleted')}</div>
                        </div>
                    )}
                    {step == 1 && (updateOptionsTemplateState?.total ?? -1) === -1 && (
                        <div className={classes.loaderContainer}>
                            <div>
                                <UploadError className={classes.resultsIcon}/>
                            </div>
                            <div className={classes.uploadDoneText}>{t('screens:devices.assignError')}</div>
                        </div>
                    )}
                </Grid>
            </DialogContainer>
    );
};

export default AssignProfileDialog;
