import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import {OptionsTemplateInfo, UATypeInfo} from '../../store/types/Devices';
import history from '../../history';
import {Delete, Edit} from '@material-ui/icons';
import React from 'react';
import {getRoute, Routes} from '../../routes/routes';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission} from '../../store/types/Permission';
import PermissionLinkCell from '../../components/DataTable/PermissionLinkCell';
import {ReactComponent as Clone} from '../../assets/clone.svg';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import BarLoader from '../../components/BarLoader/BarLoader';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: 'white',
        },
        flex: 1,
    },

    centerCell: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },

    actionCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
    },

    content: {
        maxWidth: 1040,
        padding: '0px 94px 100px 94px',
    },
    header: {
        paddingTop: 48,
        paddingBottom: 16,
        '& p': {
            marginBottom: 0,
        },
        '& div:not(:first-child)': {
            marginTop: 14
        }
    },
    table: {
        '& .MuiDataGrid-cell': {
            alignItems: 'center',
            height: 64,
            color: Colors.Gray5,
        },

        '& td.MuiTableCell-root.MuiTableCell-body': {
            padding: 8,
        },
        '& th.MuiTableCell-root.MuiTableCell-head': {
            padding: 8,
        },

        '& .MuiTableCell-body': {
            color: Colors.Gray5,
        },

        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(7)': {
            textAlign: 'center'
        },
        
        '& .MuiTableHead-root': {
            height: 64,
            display: 'flex',
        },

        '& thead tr th:nth-child(1)': {
            marginLeft: 12,
        },
        
        '& tbody tr td:nth-child(1)': {
            marginLeft: 12,
        },
        
        '& thead tr th:nth-child(3)': {
            textAlign: 'center'
        },
        
        '& thead tr th:nth-child(4)': {
            textAlign: 'center'
        },

        '& tbody tr td:nth-child(3)': {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center',
        },
    },
    moreOptionsButton: {
        '& svg.MuiSvgIcon-root > path': {
            fill: Colors.Gray5,
            fillOpacity: 1,
        },
        '&:hover': {
            backgroundColor: Colors.Gray11,
        },
        padding: 8,
        marginLeft: 14
    },
    dropdownMenuItem: {
        fontSize: 16,
        color: Colors.Text
    },
    hidden: {
        display: 'none'
    },
    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '365px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
    },
    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },
}));

export const addId = (o: OptionsTemplateInfo) => ({...o, id: o.i_ua_config_template});

export const generateColumns = (
    t: TFunction<string>,
    onDelete: (obj: OptionsTemplateInfo) => void,
    classes: ReturnType<typeof useStyles>,
    ua_type_list?: UATypeInfo[]
): Column<OptionsTemplateInfo>[] => {
    return [
        {
            accessor: 'name',
            width: 2,
            Header: t<string>('screens:deviceProfiles.profileName'),
            Cell: function Cell(params) {
                const permission = Permission.Inventory.DeviceProfiles.value;
                const text = params.row.original.name || '';
                const route = getRoute(Routes.DeviceProfilesDetails, {id: params.row.original.i_ua_config_template})
                return (
                    <PermissionLinkCell
                        text={text}
                        onClick={() => history.push(route)}
                        permissions={permission}
                    />
                );
            }
        },
        {
            accessor: 'ua_profile_id',
            width: 1,
            Header: t<string>('screens:deviceProfiles.model'),
            Cell: function Cell(params) {
                const modelName = ua_type_list
                    ?.find(e => e.i_ua_type === params.row.original.i_ua_type)
                    ?.name ?? '';
                if(!modelName.length) {
                    return (
                        <BarLoader
                            dataQa={
                                'fetch-device-model-loader-' + params.row.original.i_ua_type
                            }
                        />
                    );
                }
                return (<OverflowTooltip
                    tooltip={modelName}
                    text={modelName}
                    dataQa={'device-profile-text'}
                    copy={false}
                />);
            },
        },
        {
            accessor: 'i_ua_list',
            width: 1,
            Header: t<string>('screens:deviceProfiles.devicesUseProfile'),
            Cell: function Cell(params) {
                return params.row.original.i_ua_list?.length;
            },
        },
        {
            accessor: 'i_ua_config_template',
            width: 1,
            Header: t<string>('common:actions'),
            Cell: function Cell(params) {
                return (<div className={classes.actionCell}>
                        <PermissionProvider
                            permission={
                                Permission.Inventory.DeviceProfiles.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    history.push(
                                        getRoute(Routes.DeviceProfilesDetails, {
                                            id: params.row.original.i_ua_config_template,
                                        }),
                                    )
                                }
                                tooltipText={t<string>('common:edit')}
                                dataTestId="edit-list-item-button"
                                dataQa="edit-list-item-button"
                            >
                                <Edit htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        </PermissionProvider>
                        <PermissionProvider
                            permission={
                                Permission.Inventory.DeviceProfiles.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() => {
                                    history.push(
                                        getRoute(Routes.DeviceProfilesCopy, {
                                            id: params.row.original.i_ua_config_template,
                                        }),
                                    );
                                }}
                                tooltipText={t<string>('screens:deviceProfiles.clone')}
                                dataTestId="clone-list-item-button"
                                dataQa="clone-list-item-button"
                            >
                                <Clone style={{width: 24, height: 24}} />
                            </CustomizedIconButton>
                        </PermissionProvider>
                        <PermissionProvider
                            permission={
                                Permission.Inventory.DeviceProfiles.value
                            }
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    onDelete?.(params.row.original)
                                }
                                tooltipText={t<string>('common:delete')}
                                dataTestId="remove-list-item-button"
                                dataQa="remove-list-item-button"
                            >
                                <Delete htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        </PermissionProvider>
                    </div>
                );
            },
        },
    ];
};
