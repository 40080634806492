import React from 'react';
import { ReactComponent as ChevronLeft } from '../../assets/chevron left.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { UAInfo } from '../../store/types/Devices';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import CustomizedIconButton from '../IconButton/IconButton';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        lineHeight: 'initial',
        alignItems: 'flex-start',
        '& > span': {
            marginRight: 9,
        },

        '& h2': {
            margin: 0,
            marginRight: 16,
            fontWeight: 300,
            fontSize: 32,
            lineHeight: '20px',
            color: Colors.Text3,
        },
        
        '& h3': {
            margin: 0,
            fontWeight: 500,
            fontSize: 32,
            lineHeight: '20px',
            letterSpacing: 0,
            color: 'rgba(18, 18, 18, 1)'
        },

        '& h4': {
            margin: 0,
            fontSize: 14,
        },
    },

    chevron: {
        display: 'flex',
    },
    arrow: {
        cursor: 'pointer',
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: 10,
    },

    headerRow: {
        display: 'flex',
        alignItems: 'flex-end',
        margin: 0,
        marginBottom: 12,
    },
    linesRow: {
        display: 'flex',
        fontSize: 14,
        color: Colors.Gray5,
    },
}));

type DeviceTitleProps = {
    device?: UAInfo;
    onChevronClick?: () => void;
    dataQa?: string;
    className?: string;
};

const DeviceTitle: React.FC<DeviceTitleProps> = ({
    device,
    onChevronClick,
    dataQa,
    className,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div
            className={classNames(classes.root, className)}
            data-qa={dataQa}
            data-testid={'device-details-title'}
        >
            <div className={classes.chevron}>
                <CustomizedIconButton
                    className={classes.arrow}
                    onClick={onChevronClick}
                    skipPermission
                >
                    <ChevronLeft />
                </CustomizedIconButton>
            </div>
            <div className={classes.details}>
                <div className={classes.headerRow}>
                    <h2>{t('screens:devices.device')}</h2>
                    <h3> {device?.name}</h3>
                </div>
                <div className={classes.linesRow}>
                    <h4>
                        {device?.busy_ports?.length ?? 0}{' '}
                        {t('screens:devices.lineUsed')}
                        {' / '}
                        {device?.available_ports?.length ?? 0}{' '}
                        {t('common:free')}
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default DeviceTitle;
