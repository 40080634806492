import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import {PhoneDirectory} from '../../store/types/Devices';
import history from '../../history';
import {Delete, Edit} from '@material-ui/icons';
import React from 'react';
import {getRoute, Routes} from '../../routes/routes';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission} from '../../store/types/Permission';
import PermissionLinkCell from '../../components/DataTable/PermissionLinkCell';
import classNames from 'classnames';
import moment from 'moment';
import {ReactComponent as AutoSync} from '../../assets/AutoSync.svg';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: 'white',
        },
        flex: 1,
    },

    centerCell: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },

    actionCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
    },

    content: {
        maxWidth: 1040,
        padding: '0px 94px 100px 94px',
    },
    header: {
        paddingTop: 48,
        paddingBottom: 16,
        '& p': {
            marginBottom: 0,
        },
        '& div:not(:first-child)': {
            marginTop: 14
        }
    },
    table: {
        '& .MuiDataGrid-cell': {
            alignItems: 'center',
            height: 64,
            color: Colors.Gray5,
        },

        '& td.MuiTableCell-root.MuiTableCell-body': {
            padding: 8,
        },
        '& th.MuiTableCell-root.MuiTableCell-head': {
            padding: 8,
        },

        '& .MuiTableCell-body': {
            color: Colors.Gray5,
        },

        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(2)': {
            textAlign: 'center'
        },

        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(5)': {
            textAlign: 'center'
        },
        
        '& .MuiTableHead-root': {
            height: 64,
            display: 'flex',
        },

        '& thead tr th:nth-child(1)': {
            marginLeft: 12,
        },
        
        '& tbody tr td:nth-child(1)': {
            marginLeft: 12,
        },
        
        '& tbody tr td:nth-child(2)': {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center',
            color: Colors.Text,
            fontWeight: 400,
            fontSize: 16,
        },
        
        '& tbody tr td:nth-child(4)': {
            color: Colors.Text,
            fontWeight: 400,
            fontSize: 16,
        },
    },
    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '365px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
    },
    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },
    activeStatus: {
        color: 'rgba(117, 185, 67, 1)'
    },
    draftStatus: {
        color: 'rgba(196, 2, 2, 1)'
    },
    timeAgoColumn: {
        fontWeight: 400,
        fontStyle: 'italic',
        fontSize: 16,
    }
}));

export const addId = (o: PhoneDirectory) => ({...o, id: o.i_ua_config_directory});

export const generateColumns = (
    t: TFunction<string>,
    onDelete: (obj: PhoneDirectory) => void,
    classes: ReturnType<typeof useStyles>
): Column<PhoneDirectory>[] => {
    return [
        {
            accessor: 'name',
            width: 1,
            Header: t<string>('screens:devicePhoneBooks.name'),
            Cell: function Cell(params) {
                const permission = Permission.Inventory.DeviceProfiles.value;
                const text = params.row.original.name || '';
                const route = getRoute(Routes.DevicePhoneBookDetails, {id: params.row.original.i_ua_config_directory})
                return (
                    <PermissionLinkCell
                        text={text}
                        onClick={() => history.push(route)}
                        permissions={permission}
                        //icon={AutoSync}
                    />
                );
            }
        },
        {
            accessor: 'total_records',
            width: 0.5,
            Header: t<string>('screens:devicePhoneBooks.entries'),
            Cell: function Cell(params) {
                return params.row.original.total_records;
            },
        },
        {
            accessor: 'state',
            width: 1,
            Header: t<string>('screens:devicePhoneBooks.status'),
            Cell: function Cell(params) {
                return (<div className={classNames(
                    params.row.original.state == 'active' && classes.activeStatus,
                    params.row.original.state == 'draft' && classes.draftStatus
                )}>
                    {params.row.original.state == 'active'
                        ?  t<string>('screens:devicePhoneBooks.activeStatus')
                        : t<string>('screens:devicePhoneBooks.draftStatus')
                    }
                </div>);
            },
        },
        {
            accessor: 'last_active_update',
            width: 1,
            Header: t<string>('screens:devicePhoneBooks.lastUpdated'),
            Cell: function Cell(params) {
                return <div className={classes.timeAgoColumn}>
                    {params.row.original.last_active_update?.length
                        ? moment(params.row.original.last_active_update).fromNow()
                        : t<string>('screens:devicePhoneBooks.never')
                    }
                </div>
            },
        },
        {
            accessor: 'i_ua_config_directory',
            width: 1,
            Header: t<string>('common:actions'),
            Cell: function Cell(params) {
                return (<div className={classes.actionCell}>
                        <PermissionProvider
                            permission={
                                Permission.Inventory.DeviceProfiles.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    history.push(
                                        getRoute(Routes.DevicePhoneBookDetails, {
                                            id: params.row.original.i_ua_config_directory,
                                        }),
                                    )
                                }
                                tooltipText={t<string>('common:edit')}
                                dataTestId="edit-list-item-button"
                                dataQa="edit-list-item-button"
                            >
                                <Edit htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        </PermissionProvider>
                        <PermissionProvider
                            permission={
                                Permission.Inventory.DeviceProfiles.value
                            }
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    onDelete?.(params.row.original)
                                }
                                tooltipText={t<string>('common:delete')}
                                dataTestId="remove-list-item-button"
                                dataQa="remove-list-item-button"
                            >
                                <Delete htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        </PermissionProvider>
                    </div>
                );
            },
        }
    ];
};
