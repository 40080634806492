import React from 'react'

interface MenuIconFromPathProps {
    path:string
    alt:string
}


const MenuIconFromPath:React.VFC<MenuIconFromPathProps> = ({path,alt}) => {
  return <img src={path} alt={alt} style={{maxHeight:'20px',maxWidth:'20px', minWidth:'20px', minHeight:'20px'}}/>
}

export default MenuIconFromPath