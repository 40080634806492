import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import CustomizedCheckbox from '../../Checkbox/Checkbox';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import classNames from 'classnames';
import FileSelect from '../../FileSelect/FileSelect';
import {
    PromptFormProps,
    PromptsFormType,
    useStyles,
} from './PromptForm.utils';
import { VoiceFileContentType } from '../../../store/types/Voicemail';
import { prepareOggFile } from '../../UploadFile/UploadFile';

const PromptForm: React.FC<PromptFormProps> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { values, setFieldValue } = useFormikContext<PromptsFormType>();

    return (
        <div className={classes.inputs}>
            <Grid item className={classes.itemsContainer}>
                <Grid
                    className={classNames(
                        classes.centeredGrid,
                        classes.playBeforeGrid,
                    )}
                >
                    <CustomizedCheckbox
                        checked={values.introPromptStatus}
                        dataQa="intro-status"
                        onChange={(value) =>
                            setFieldValue(
                                'introPromptStatus',
                                value.target.checked,
                            )
                        }
                        label={t('screens:autoAttendants.intro')}
                    />

                    <IconWithTooltip
                        dataQa="intro-tooltip"
                        tooltipText={t('tooltips:autoAttendants.intro')}
                    />
                </Grid>

                {values.introPromptStatus && (
                    <Box
                        className={classes.file}
                        alignItems="center"
                        display="flex"
                    >
                        <FileSelect
                            nameValue={values.introPromptFileName}
                            onFileChange={(file) => {
                                let audioFile;
                                if (
                                    file.type === VoiceFileContentType.VideoOgg
                                ) {
                                    audioFile = prepareOggFile(file);
                                }

                                setFieldValue(
                                    'introPromptFileName',
                                    audioFile ? audioFile.name : file.name,
                                );
                                setFieldValue(
                                    'introPromptFile',
                                    audioFile ?? file,
                                );
                            }}
                            file={values.introPromptFile}
                            customStyles={{
                                textField: classes.buttonFileTextField,
                                buttonStyle: classes.buttonFile,
                            }}
                        />
                    </Box>
                )}

                <Grid
                    className={classNames(
                        classes.centeredGrid,
                        classes.playBeforeGrid,
                    )}
                >
                    <CustomizedCheckbox
                        checked={values.menuPromptStatus}
                        dataQa="menu-status"
                        onChange={(value) =>
                            setFieldValue(
                                'menuPromptStatus',
                                value.target.checked,
                            )
                        }
                        label={t('screens:autoAttendants.menu')}
                    />

                    <IconWithTooltip
                        dataQa="menu-tooltip"
                        tooltipText={t('tooltips:autoAttendants.menu')}
                    />
                </Grid>

                {values.menuPromptStatus && (
                    <Box
                        className={classes.file}
                        alignItems="center"
                        display="flex"
                    >
                        <FileSelect
                            nameValue={values.menuPromptFileName}
                            onFileChange={(file) => {
                                let audioFile;
                                if (
                                    file.type === VoiceFileContentType.VideoOgg
                                ) {
                                    audioFile = prepareOggFile(file);
                                }

                                setFieldValue(
                                    'menuPromptFileName',
                                    audioFile ? audioFile.name : file.name,
                                );
                                setFieldValue(
                                    'menuPromptFile',
                                    audioFile ?? file,
                                );
                            }}
                            file={values.menuPromptFile}
                            customStyles={{
                                textField: classes.buttonFileTextField,
                                buttonStyle: classes.buttonFile,
                            }}
                        />
                    </Box>
                )}

                <Grid
                    className={classNames(
                        classes.centeredGrid,
                        classes.playBeforeGrid,
                    )}
                >
                    <CustomizedCheckbox
                        checked={values.onTimeoutPromptStatus}
                        dataQa="on-timeout-status"
                        onChange={(value) =>
                            setFieldValue(
                                'onTimeoutPromptStatus',
                                value.target.checked,
                            )
                        }
                        label={t('screens:autoAttendants.onTimeout')}
                    />

                    <IconWithTooltip
                        dataQa="on-timeout-tooltip"
                        tooltipText={t('tooltips:autoAttendants.onTimeout')}
                    />
                </Grid>

                {values.onTimeoutPromptStatus && (
                    <Box
                        className={classes.file}
                        alignItems="center"
                        display="flex"
                    >
                        <FileSelect
                            nameValue={values.onTimeoutPromptFileName}
                            onFileChange={(file) => {
                                let audioFile;
                                if (
                                    file.type === VoiceFileContentType.VideoOgg
                                ) {
                                    audioFile = prepareOggFile(file);
                                }

                                setFieldValue(
                                    'onTimeoutPromptFileName',
                                    audioFile ? audioFile.name : file.name,
                                );
                                setFieldValue(
                                    'onTimeoutPromptFile',
                                    audioFile ?? file,
                                );
                            }}
                            file={values.onTimeoutPromptFile}
                            customStyles={{
                                textField: classes.buttonFileTextField,
                                buttonStyle: classes.buttonFile,
                            }}
                        />
                    </Box>
                )}

                <Grid
                    className={classNames(
                        classes.centeredGrid,
                        classes.playBeforeGrid,
                    )}
                >
                    <CustomizedCheckbox
                        checked={values.onUnavailableSelectionPromptStatus}
                        dataQa="on-unavailable-status"
                        onChange={(value) =>
                            setFieldValue(
                                'onUnavailableSelectionPromptStatus',
                                value.target.checked,
                            )
                        }
                        label={t(
                            'screens:autoAttendants.onUnavailableSelection',
                        )}
                    />

                    <IconWithTooltip
                        dataQa="on-unavailable-tooltip"
                        tooltipText={t('tooltips:autoAttendants.onUnavailable')}
                    />
                </Grid>

                {values.onUnavailableSelectionPromptStatus && (
                    <Box
                        className={classes.file}
                        alignItems="center"
                        display="flex"
                    >
                        <FileSelect
                            nameValue={
                                values.onUnavailableSelectionPromptFileName
                            }
                            onFileChange={(file) => {
                                let audioFile;
                                if (
                                    file.type === VoiceFileContentType.VideoOgg
                                ) {
                                    audioFile = prepareOggFile(file);
                                }

                                setFieldValue(
                                    'onUnavailableSelectionPromptFileName',
                                    audioFile ? audioFile.name : file.name,
                                );
                                setFieldValue(
                                    'onUnavailableSelectionPromptFile',
                                    audioFile ?? file,
                                );
                            }}
                            file={values.onUnavailableSelectionPromptFile}
                            customStyles={{
                                textField: classes.buttonFileTextField,
                                buttonStyle: classes.buttonFile,
                            }}
                        />
                    </Box>
                )}
            </Grid>
        </div>
    );
};

export default PromptForm;
