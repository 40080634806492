import React, {useMemo, useState} from "react";
import {ReactComponent as BlueIcon} from '../../assets/call_control_icons/blue.svg';
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {useTranslation} from "react-i18next";
import {ManageCallControlProps} from "./callControls.utils";
import {SipCallState, SipCallType} from "../../store/actions/ringgroups/payloads";
import classNames from "classnames";
import TransferCallDialog from "./TransferCallDialog";


const useStyles = makeStyles(() => ({
    root: {
        '& path, & rect': {transition: 'fill .4s ease'},
        cursor: 'pointer',
    },
    icon: {
        '& > rect': {
            fill: Colors.White
        },
        '&:hover > rect': {
            fill: Colors.Link
        },
        '&:hover  path': {
            fill: Colors.White
        },
    },
    disabled: {
        '& > rect': {
            fill: 'transparent'
        },

        '& path': {
            fill: Colors.Gray6
        },
    }
}))

const Transfer: React.VFC<ManageCallControlProps> = (
    {
        activeCallEntity,
        callbackAfterOnClick,
        i_customer,
        isReadOnly,
        accounts,
        sipCalls
    }) => {

    const {t} = useTranslation();
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const enableState = [SipCallState.Early, 
        SipCallState.Connected, 
        SipCallState.Held, 
        SipCallState.Holding, 
        SipCallState.Queued
    ];

    const enabledStateCondition = useMemo(() => {
        if(activeCallEntity?.state === SipCallState.Ringing && activeCallEntity?.type === SipCallType.Incoming) {
            return true;
        }
        return activeCallEntity && enableState.find((item) => item == activeCallEntity.state) !== undefined;
    }, [activeCallEntity]);

    const disabled = useMemo(() => {
        return isReadOnly || !enabledStateCondition;
    }, [isReadOnly, enabledStateCondition]);

    const onSaveClick = () => {
        callbackAfterOnClick?.()
    }

    const tooltipToShow = useMemo(()=>{
        return isReadOnly
            ? t('common:noPermissionToResource')
            : t('screens:callSettings.transferCall')
    }, [isReadOnly])

    const toggleVisibility = (value: boolean) => setIsOpen(value);

    return (
        <>
            <CustomizedTooltip
                title={tooltipToShow }
                copy={false}
                disableHoverListener={!isReadOnly && !enabledStateCondition}
            >
                <BlueIcon
                    className={classNames(classes.root, disabled ? classes.disabled : classes.icon)}
                    onClick={!disabled ? () => toggleVisibility(true) : undefined}
                />
            </CustomizedTooltip>

            <TransferCallDialog
                isOpen={isOpen}
                onSaveClick={onSaveClick}
                toggleVisibility={() => toggleVisibility(false)}
                caller={activeCallEntity.caller}
                callee={activeCallEntity.callee}
                type={activeCallEntity.type}
                i_customer={i_customer}
                call={activeCallEntity.call}
                accounts={accounts}
                sipCalls={sipCalls}
            />
        </>
    )
}

export default Transfer;