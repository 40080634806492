import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Header from '../../components/ListViewHeader/Header';
import {ReduxState} from '../../store/types';
import * as actions from '../../store/actions';
import {PhoneDirectory} from '../../store/types/Devices';
import {PaginationMode} from '../../components/DataGrid/types';
import DataGrid from '../../components/DataGrid/DataGrid';
import {addId, generateColumns, useStyles} from './DevicePhoneBookList.utils';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission} from "../../store/types/Permission";
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {showToast} from "../../utils/showToast";
import { YesNo } from '../../store/types/CallScreening';
import NewDevicePhoneBookDialog from './NewDevicePhoneBookDialog';

const DevicePhoneBookList = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    usePageTitle();

    const i_customer: number = useSelector((state: ReduxState) => state.generic?.sessionData?.i_customer ?? 0);

    const [showAddNewDevicePhoneBookDialog, setShowAddNewDevicePhoneBookDialog] = useState(false);
    const handleAddNewDevicePhoneBookClick = () => {
        setShowAddNewDevicePhoneBookDialog((prev) => !prev);
    };
    
    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        i_ua_config_directory?: number;
        name?: string;
    }>({
        isOpen: false
    });

    const handleDeleteAction = () => {
        dispatch(actions.deleteDevicePhoneBook.request({
            i_ua_config_directory: deleteDialog.i_ua_config_directory || 0,
            callback: () => {
                closeDeleteDialog();
                fetchData({pageSize: 100, page: 1});
                showToast(t<string>('screens:devicePhoneBooks.devicePhoneBookDeleted'));
            }
        }));
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            i_ua_config_directory: undefined,
            name: undefined
        });
    };

    const {
        phoneDirectories = [],
        isLoadingPhoneDirectories = false,
        totalPhoneDirectories,
        createdPhoneDirectoryId,
    } = useSelector((state: ReduxState) => state.devices);

    useEffect(() => {
        if (!showAddNewDevicePhoneBookDialog && createdPhoneDirectoryId) {
            fetchData();
        }
    }, [showAddNewDevicePhoneBookDialog, createdPhoneDirectoryId]);

    const fetchData = (params?: { pageSize: number; page: number }) => {
        const _pageSize = params?.pageSize ?? pageSize ?? 100;
        let _page = params?.page ?? page ?? 1;
        if(_page < 1) {
            _page = 1;
        }
        dispatch(
            actions.getDevicePhoneBookList.request({
                get_total: 1,
                get_total_records: YesNo.Yes,
                i_customer: i_customer,
                limit: _pageSize,
                offset: (_page - 1) * _pageSize,
            })
        );
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    const handleDelete = (obj: PhoneDirectory) => {
        setDeleteDialog({isOpen: true, i_ua_config_directory: obj.i_ua_config_directory, name: obj.name});
    };

    const columns = useMemo(() => generateColumns(t, 
        handleDelete, 
        classes), 
    []);

    return (
        <PermissionPlaceholder permission={Permission.Inventory.DeviceProfiles.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.content}>
                        <PermissionProvider
                            permission={Permission.Inventory.DeviceProfiles.value}
                        >
                            <Header
                                title={t('screens:devicePhoneBooks.listTitle')}
                                totalNumber={totalPhoneDirectories || 0}
                                dataQa={'device-phone-book-list-view-header'}
                                customClass={classes.header}
                                plusIcon={true}
                                buttonText={t('common:addNewButton')}
                                buttonOnClick={handleAddNewDevicePhoneBookClick}
                                showDetails={false}
                            >
                            </Header>
                        </PermissionProvider>

                        <div style={{marginTop: 16}}></div>

                        <DataGrid<PhoneDirectory>
                            columns={columns}
                            data={phoneDirectories.map(addId)}
                            className={classes.table}
                            rowCount={totalPhoneDirectories ?? 200000}
                            loading={isLoadingPhoneDirectories}
                            onPageChange={(params) => {
                                fetchData({
                                    pageSize: params.pageSize,
                                    page: params.page,
                                });
                                setPage(params.page);
                            }}
                            onPageSizeChange={(params) => {
                                fetchData({pageSize: params.pageSize, page: 0});
                                setPageSize(params.pageSize);
                            }}
                            paginationMode={PaginationMode.Server}
                            centeredRows
                            narrowRows
                            initialPageSize={10}
                            forceFirstPage={false}
                        />
                    </div>
                </div>

                <AlertDialog
                    className={classes.deleteDialog}
                    contentClass={classes.contentDialog}
                    isOpen={deleteDialog.isOpen}
                    dataQa={'delete-profile-dialog'}
                    hideHeader
                    description={t('screens:devicePhoneBooks.deleteRule', {
                        name: deleteDialog.name || '',
                    })}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={closeDeleteDialog}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={handleDeleteAction}
                        />,
                    ]}
                />
                
                <NewDevicePhoneBookDialog
                    isOpen={showAddNewDevicePhoneBookDialog}
                    clickOnClose={handleAddNewDevicePhoneBookClick}
                />

            </div>
        </PermissionPlaceholder>
    );
};

export default DevicePhoneBookList;
