import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../services/i18n';

export type DevicePhoneBookFormProps = {
    name: string;
    mode: 'manual' | 'csv' | 'auto';
    fileSizeReadable?: string;
};

export type NewDevicePhoneBookDialogProps = {
    isOpen: boolean;
    clickOnClose?: () => void;
};

export const useStyles = makeStyles(() => ({
    error: {
        color: Colors.Error,
        fontSize: 12,
        fontWeight: 400,

        '& svg': {
            display: 'none',
        },
    },

    itemsContainer: {
        padding: '41px 48px 33px 48px',
        display: 'flex',
        flexDirection: 'column',
        width: 472,

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 472,
            marginBottom: 24,
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: 568,
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            width: 568,
            height: 418,
            padding: 0,
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 568,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 472,

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    initMac: {
        '& .MuiInputBase-input.MuiInput-input': {
            color: Colors.LightGraySecondary3,
        },
    },
    
    fileDragAndDropBox: {
        border: '0.76px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '7.62px',
        width: '100%',
        height: '100%',
        display:"flex",
        marginBottom: 12,
    },

    uploadBox: {
        width: '100%',
        height: 'auto',
        cursor: 'pointer',
        display: 'flex',
        padding: 24,
        alignItems: 'center'
    },

    uploadIcon: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 16,
        fill: 'rgba(18, 18, 18, 1)',
        fillOpacity: 1,
    },

    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flex: '1 0 0',
        marginLeft: 12,
    },

    importTop: {
        marginLeft: 0,
        marginTop: 0,
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Black,
        maxWidth: 'auto',
    },

    importBottom: {
        marginLeft: 0,
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Gray6,
        maxWidth: 'auto',
    },

    browseButton: {
        marginTop: 0,
        marginLeft: 0,
        color: Colors.White,
        height: 40,
        minWidth: '120px',
        maxWidth: '280px',
        padding: '12px 16px'
    },

    lastColumn: {
        marginLeft: '14px'
    },
}));

const requiredError = i18n.t<string>('errors:common.emptyInput');

export const addDevicePhoneBookValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(requiredError),
});

export const getRoute = (route: string, options: object) => {
    let output = route;

    for (const [key, value] of Object.entries(options)) {
        const search = `:${key}`;

        // @ts-ignore
        output = output.replace(search, value);
    }

    return output;
};